import React from 'react';
import { useRoutes } from 'react-router-dom';

import AuthBg from '../../assets/auth-bg.png';
import AuthFooter from '../../components/Footer/AuthFooter';
import AuthNavbar from '../../components/Navbar/AuthNavbar';
import Login from '../../views/auth/Login';

const AuthPage = () => {
    const authPageRoutes = useRoutes([
        {
            path: 'login',
            element: <Login />,
        },
    ]);

    return (
        <>
            <main className="content">
                <AuthNavbar />
                <section className="relative w-full h-full">
                    <div
                        className="absolute top-0 w-full h-full bg-police-blue bg-no-repeat bg-full"
                        style={{
                            backgroundImage: `url(${AuthBg})`,
                        }}
                    ></div>
                    {authPageRoutes}
                </section>
            </main>
            <AuthFooter />
        </>
    );
};

export default AuthPage;
