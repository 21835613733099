import classNames from 'classnames';
import { compact, find, includes, map, pick, toLower } from 'lodash';
import React from 'react';
import { exportToExcel } from 'react-json-to-excel';
import { Link } from 'react-router-dom';

import authUserStorage from '../../authUser';
import {
    casteCategoryOptions,
    genderOptions,
    officerRankOptions,
    sortedOfficerRanks,
} from '../FormCard/inputFields/userFormFields';
import { bloodGroupOptions } from '../FormCard/inputFields/userHealthFormFields';
import Image from '../Image';
import TableDropdown from '../TableDropdown';
import { ModalForTransfer } from './forTransfer';

export const policeColumns = [
    {
        Header: 'Buckle No',
        accessor: 'buckle_no',
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: (props) => {
            const { userRoleId } = props;
            const { photo_url, name, user_id } = props.row.original;
            const renderNameCell = (
                <div className="flex items-center text-sm">
                    <div className="relative min-w-12 w-12 h-12 mr-3 rounded-full md:block">
                        {photo_url && (
                            <Image
                                src={photo_url}
                                className="object-cover w-full h-full rounded-full"
                                alt="..."
                                loading="lazy"
                            />
                        )}
                        <div
                            className="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                        ></div>
                    </div>
                    <div>
                        <p className="font-semibold text-black">{name}</p>
                    </div>
                </div>
            );
            if (userRoleId === '3' || userRoleId === '4') {
                return renderNameCell;
            }
            return <Link to={`/view/${user_id}`}>{renderNameCell}</Link>;
        },
    },
    {
        Header: 'Gender',
        accessor: 'gender',
    },
    {
        Header: 'Officer Post',
        accessor: 'officer_post',
        sortType: (rowA, rowB, id, desc) => {
            const rowAIndex = sortedOfficerRanks.indexOf(rowA.values[id]);
            const rowBIndex = sortedOfficerRanks.indexOf(rowB.values[id]);
            if (rowAIndex > rowBIndex) return 1;
            if (rowBIndex > rowAIndex) return -1;
            return 0;
        },
    },
    {
        Header: 'Work Type',
        accessor: 'work_type',
        className: 'max-w-200-px',
        headerClassName: 'whitespace-nowrap',
    },
    {
        Header: 'Mobile No',
        accessor: 'mobile_no',
        headerClassName: 'whitespace-nowrap',
        Cell: (props) => {
            const { value } = props;
            return (
                <a className="underline text-blue-400" href={`tel:${value}`}>
                    {value}
                </a>
            );
        },
    },
    {
        Header: 'Office Name',
        accessor: 'office_name',
    },
    {
        Header: 'Transfer',
        accessor: 'police_station_id',
        Cell: (props) => (
            <div className="inline-flex justify-center items-center w-full">
                <ModalForTransfer {...props} />
            </div>
        ),
        hide: (props) => {
            return props.userRoleId === '4';
        },
    },
    {
        Header: 'Actions',
        accessor: 'id',
        Cell: (props) => <TableDropdown user={props.row.original} />,
        hide: (props) => {
            return (
                props.pageTitle === 'Other District Officers' ||
                props.userRoleId === '4'
            );
        },
    },
];

export const validatorsForPolice = {
    search_text: (list, value) =>
        includes(toLower(list.name), toLower(value)) ||
        includes(toLower(list.buckle_no), toLower(value)),
    search_police_station: (list, value) => list.police_station_id === value,
    search_police_division: (list, value) => list.police_division_id === value,
    search_rank: (list, value) => list.officer_post === value,
    search_gender: (list, value) => list.gender === value,
    search_medical_history: (list, value) => {
        if (typeof value === 'object') {
            return !!compact(map(value, (x) => list[x])).length;
        }
        return list[value];
    },
    search_surgical_history: (list, value) => {
        if (typeof value === 'object') {
            return !!compact(map(value, (x) => list[x])).length;
        }
        return list[value];
    },
    search_top_level: (list, value) =>
        includes(toLower(list.police_station_id), toLower(value)),
    search_training_completed: (list, value) =>
        !!find(list.training, (x) =>
            includes(toLower(x.training_type), toLower(value)),
        ),
    search_training_incompleted: (list, value) =>
        !find(list.training, (x) =>
            includes(toLower(x.training_type), toLower(value)),
        ),
    search_caste_category: (list, value) => list.caste_category === value,
    search_caste_validity: (list, value) => list.caste_validity === value,
    search_mscit_computer_course: (list, value) =>
        list.mscit_computer_course === value,
    search_mode_of_selection: (list, value) => list.mode_of_selection === value,
    search_blood_group: (list, value) => list.blood_group === value,
    search_education: (list, value) => list.education === value,
};

export const searchFilterForPolice = (
    { hidePoliceStation, ...rest } = { hidePoliceStation: false },
    searchValues,
) => {
    return [
        {
            type: 'text',
            keyName: 'search_text',
            label: 'Name',
            defaultValue: '',
            className: classNames('w-full md:px-2', {
                'md:w-1/4': !hidePoliceStation,
                'md:w-1/3': hidePoliceStation,
            }),
        },
        (authUserStorage.getUser().user_role_id === 1 ||
            authUserStorage.getUser().user_role_id === 2) && {
            type: 'asyncSelect',
            keyName: 'search_police_division',
            label: 'Police Division',
            defaultValue: ({ police_division_id }) =>
                police_division_id || rest.police_division_id,
            disabled:
                !!rest.police_division_id ||
                Number(rest.police_station_id) === -1,
            className: 'w-full md:w-1/4 md:px-2',
            fetchOptionsKey: 'Users/fetchPoliceDivisions',
            optionMapper: (ps) => ({
                label: ps.name,
                value: ps.id,
            }),
        },
        authUserStorage.getUser().police_station_id !== '-1' && {
            type: 'asyncSelect',
            keyName: 'search_police_station',
            label: 'Police Station',
            defaultValue: ({ police_station_id }) =>
                police_station_id || rest.police_station_id,
            className: classNames('w-full md:w-1/4 md:px-2', {
                hidden: hidePoliceStation,
            }),
            disabled: !!rest.police_station_id,
            fetchOptionsKey: 'Users/fetchPoliceStations',
            optionFilter: (ps) => {
                if (
                    Number(ps.value) === -1 &&
                    Number(rest.police_station_id) !== -1
                ) {
                    return false;
                }
                if (
                    searchValues.search_police_division &&
                    Number(ps.divisionId) !==
                        Number(searchValues.search_police_division)
                ) {
                    return false;
                }
                return ps;
            },
            optionMapper: (ps) => ({
                label: ps.name,
                value: ps.id,
                divisionId: ps.police_division_id,
            }),
        },
        {
            type: 'select',
            keyName: 'search_rank',
            label: 'Officer Post / Rank',
            defaultValue: null,
            className: classNames('w-full md:px-2', {
                'md:w-1/4': !hidePoliceStation,
                'md:w-1/3': hidePoliceStation,
            }),
            options: map(officerRankOptions, (opr) => ({
                label: opr,
                value: opr,
            })),
        },
        {
            type: 'select',
            keyName: 'search_gender',
            label: 'Gender',
            defaultValue: null,
            className: classNames('w-full md:px-2', {
                'md:w-1/4': !hidePoliceStation,
                'md:w-1/3': hidePoliceStation,
            }),
            options: genderOptions,
        },
        {
            type: 'select',
            keyName: 'search_caste_category',
            label: 'Caste Category',
            defaultValue: null,
            className: 'w-full md:px-2 md:w-1/4',
            options: map(casteCategoryOptions, (ps) => ({
                label: ps,
                value: ps,
            })),
        },
        {
            type: 'select',
            keyName: 'search_caste_validity',
            label: 'Caste Validity',
            defaultValue: null,
            className: 'w-full md:px-2 md:w-1/4',
            options: map(['Yes', 'No', 'Not Applicable'], (ps) => ({
                label: ps,
                value: ps,
            })),
        },
        {
            type: 'select',
            keyName: 'search_mscit_computer_course',
            label: 'MSCIT - Computer Course',
            defaultValue: null,
            className: 'w-full md:px-2 md:w-1/4',
            options: map(['Yes', 'No', 'Not Applicable'], (ps) => ({
                label: ps,
                value: ps,
            })),
        },
        {
            type: 'select',
            keyName: 'search_mode_of_selection',
            label: 'Mode of Selection',
            defaultValue: null,
            className: 'w-full md:px-2 md:w-1/4',
            options: map(
                ['Direct', 'Departmental Direct', 'Officating'],
                (ps) => ({
                    label: ps,
                    value: ps,
                }),
            ),
        },
        {
            type: 'asyncSelect',
            keyName: 'search_training_completed',
            label: 'Training Completed',
            defaultValue: null,
            className: classNames('w-full md:w-1/4 md:px-2', {
                hidden: hidePoliceStation,
            }),
            fetchOptionsKey: 'UsersTraining/fetch_training_type',
            optionMapper: (ps) => ({
                label: ps.name,
                value: ps.name,
            }),
        },
        {
            type: 'asyncSelect',
            keyName: 'search_training_incompleted',
            label: 'Training InCompleted',
            defaultValue: null,
            className: classNames('w-full md:w-1/4 md:px-2', {
                hidden: hidePoliceStation,
            }),
            fetchOptionsKey: 'UsersTraining/fetch_training_type',
            optionMapper: (ps) => ({
                label: ps.name,
                value: ps.name,
            }),
        },
        {
            type: 'select',
            keyName: 'search_blood_group',
            label: 'Blood Group',
            defaultValue: null,
            className: 'w-full md:px-2 md:w-1/4',
            options: map(bloodGroupOptions, (bg) => ({
                label: bg,
                value: bg,
            })),
        },
        {
            type: 'select',
            keyName: 'search_medical_history',
            label: 'Medical & Surgical History',
            defaultValue: null,
            isMultiSelect: true,
            className: 'w-full md:w-1/2 md:px-2',
            options: [
                {
                    value: 'blood_pressure',
                    label: 'Blood Pressure',
                    type: 'medical_history',
                },
                {
                    value: 'diabetes',
                    label: 'Diabetes',
                    type: 'medical_history',
                },
                {
                    value: 'depression',
                    label: 'Depression',
                    type: 'medical_history',
                },
                {
                    value: 'tuberculosis',
                    label: 'Tuberculosis',
                    type: 'medical_history',
                },
                { value: 'asthama', label: 'Asthama', type: 'medical_history' },
                {
                    value: 'mental_disorder',
                    label: 'Mental Disorder',
                    type: 'medical_history',
                },
                {
                    value: 'std_hiv',
                    label: 'S.T.D / H.I.V.',
                    type: 'medical_history',
                },
                {
                    value: 'jaundice',
                    label: 'Jaundice',
                    type: 'medical_history',
                },
                {
                    value: 'epilepsy',
                    label: 'Epilepsy',
                    type: 'medical_history',
                },
                {
                    value: 'heart_disease',
                    label: 'Heart Disease',
                    type: 'medical_history',
                },
                {
                    value: 'sickle_cell',
                    label: 'Sickle Cell',
                    type: 'medical_history',
                },
                { value: 'cancer', label: 'Cancer', type: 'medical_history' },
                {
                    value: 'vision_disorders',
                    label: 'Vision Disorders',
                    type: 'medical_history',
                },
                {
                    value: 'head_injury',
                    label: 'Head Injury',
                    type: 'surgical_history',
                },
                {
                    value: 'leg_fracture',
                    label: 'Leg Fracture',
                    type: 'surgical_history',
                },
                {
                    value: 'hand_fracture',
                    label: 'Hand Fracture',
                    type: 'surgical_history',
                },
                {
                    value: 'other_fracture',
                    label: 'Other Fracture',
                    type: 'surgical_history',
                },
                {
                    value: 'covid19',
                    label: 'COVID 19',
                    type: 'surgical_history',
                },
                { value: 'hernia', label: 'Hernia', type: 'surgical_history' },
                {
                    value: 'hydrocele',
                    label: 'Hydrocele.',
                    type: 'surgical_history',
                },
                {
                    value: 'appendix',
                    label: 'Appendix',
                    type: 'surgical_history',
                },
            ],
            hide: (user) =>
                user?.user_role_id === '4' || user?.user_role_id === '3',
        },
        {
            type: 'asyncSelect',
            keyName: 'search_education',
            label: 'Education',
            defaultValue: null,
            className: 'w-full md:w-1/4 md:px-2',
            fetchOptionsKey: 'Fetch/education',
            optionMapper: (pe) => ({
                label: pe.education,
                value: pe.education,
            }),
        },
        {
            className: 'w-full md:w-1/4 md:px-2 mt-6',
            render: (props) => {
                const downloadingData = props?.map((x) =>
                    pick(x, [
                        'office_name',
                        'name_suffix',
                        'name',
                        'gender',
                        'buckle_no',
                        'officer_post',
                        'work_type',
                        'mobile_no',
                        'alt_mobile_no',
                        'wa_mobile_no',
                        'age',
                        'blood_group',
                        'bmi',
                        'family_no',
                        'email_address',
                        'education',
                        'caste_category',
                        'caste',
                        'caste_validity',
                        'date_of_birth',
                        'date_of_joining',
                        'date_of_retirement',
                        'aadhar_card_no',
                        'pan_no',
                        'sevarth_id',
                        'home_address',
                        'home_pincode',
                        'permanent_address',
                        'permanent_pincode',
                        'mode_of_selection',
                        'confirmation_date',
                        'mscit_computer_course',
                        'present_posting_start_date',
                        'present_district_posting_date',
                    ]),
                );
                return (
                    <button
                        className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        onClick={() =>
                            downloadingData?.length &&
                            exportToExcel(
                                downloadingData,
                                `police_list_${new Date()}`,
                                false,
                            )
                        }
                        style={{ marginTop: 2 }}
                    >
                        {'Download'}
                    </button>
                );
            },
            hide: (user) =>
                user?.user_role_id === '4' || user?.user_role_id === '3',
        },
    ];
};
