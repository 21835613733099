import * as yup from 'yup';

const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

export const userSportSchema = yup.object({
    user_id: yup.string().required(),
    sport_name: yup.string().required(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Sport Name',
            accessor: 'sport_name',
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user) => [
    {
        groupName: 'Add Sport Details',
        inputFields: [
            {
                keyName: 'sport_name',
                label: 'Sport Details',
                type: 'text',
                className: 'w-full lg:w-1/3',
                onChangeOverride: (val) => titleCase(val),
            },
        ],
    },
];
