import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchRetiringDetails = () => {
    const axios = useAxios();
    return useQuery('UsersRetirement/fetchRetirementDetails', async () => {
        const { data } = await axios
            .get('UsersRetirement/fetchRetirementDetails')
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    });
};

export default useFetchRetiringDetails;
