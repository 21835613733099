import { useEffect, useState } from 'react';

import avatarImg from '../assets/avatar.jpg';

export const DefaultImage = (props) => (
    <img {...props} alt={props.alt} src={avatarImg} />
);

const Image = (props) => {
    const [src, setSrc] = useState(props.src || avatarImg);

    useEffect(() => {
        setSrc(props.src || avatarImg);
    }, [props.src]);

    return (
        <img
            {...props}
            alt={props.alt}
            src={src}
            onError={() => {
                console.log('Err');
                setSrc(avatarImg);
            }}
        />
    );
};

export default Image;
