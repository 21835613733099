import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostCriminalUploadImage = (options = {}) => {
    const axios = useAxios();
    return useMutation(async (fileBase64) => {
        const { data } = await axios
            .post('Criminals/add_criminal_photo', JSON.stringify(fileBase64))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    }, options);
};

export default usePostCriminalUploadImage;
