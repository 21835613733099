import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
}

const formatDate = (d) => {
    const today = new Date(d);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    return new Date(mm + '/' + dd + '/' + yyyy);
};

const InputDate = ({
    keyName,
    value,
    onChange,
    disabled,
    label,
    error,
    helpText,
    hideErrors,
}) => (
    <div className="relative w-full mb-3">
        <label
            className="block text-gray-600 text-xs font-bold mb-2"
            htmlFor={`date_${keyName}`}
        >
            {label}
        </label>
        <p className="block text-blue-400 text-xs font-bold mb-2">{helpText}</p>
        <ReactDatePicker
            selected={
                value && isValidDate(new Date(value)) ? formatDate(value) : null
            }
            id={`date_${keyName}`}
            className="border px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            onChange={(e) => {
                console.log(e);
                onChange(moment(new Date(e)).format('YYYY-MM-DD'));
            }}
            dateFormat="dd/MM/yyyy"
            disabled={disabled}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
        />
        {!hideErrors && (
            <p className="text-red-500" style={{ minHeight: 24 }}>
                {error}
            </p>
        )}
    </div>
);

export default InputDate;
