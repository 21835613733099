import {
    criminalColumns,
    searchFilterForCriminal,
    searchFilterForCriminalDashboard,
    validatorsForCriminal,
} from './forCriminal';
import {
    policeColumns,
    searchFilterForPolice,
    validatorsForPolice,
} from './forPolice';
import {
    adminUserColumns,
    searchFilterForPoliceAdmin,
    validatorsForPoliceAdmin,
} from './forPoliceAdmin';
import {
    policeStationColumns,
    searchFilterForPoliceStation,
    validatorsForPoliceStation,
} from './forPoliceStation';
import {
    officeTypeColumns,
    searchFilterForOfficeType,
    validatorsForOfficeType,
} from './forPoliceStationType';
import {
    searchFilterForTransferPolice,
    transferPoliceColumns,
} from './forTransfer';
import {
    searchFilterForTransferList,
    transferListColumns,
    validatorsForTransferList,
} from './forTransferList';
import {
    searchFilterForUpcomingRetirement,
    upcomingRetirementColumns,
    validatorsForUpcomingRetirement,
} from './forUpcomingRetirement';

const listViewOptions = {
    admins: {
        pathName: 'admins',
        apiUrlKey: 'users/fetchAdminUsers',
        searchFilter: searchFilterForPoliceAdmin,
        columns: adminUserColumns,
        title: 'Admin Users',
        validators: validatorsForPoliceAdmin,
    },
    'police-stations': {
        pathName: 'police-stations',
        apiUrlKey: 'Users/fetchPoliceStations',
        searchFilter: searchFilterForPoliceStation,
        columns: policeStationColumns,
        title: 'Police Station Users',
        validators: validatorsForPoliceStation,
    },
    police: {
        pathName: 'police',
        apiUrlKey: 'Users/FetchPoliceUsers',
        searchFilter: searchFilterForPolice,
        columns: policeColumns,
        title: 'Police Users',
        validators: validatorsForPolice,
    },
    'other-district-officer': {
        pathName: 'other-district-officer',
        apiUrlKey: 'Users/fetchOtherDistrictUsers',
        searchFilter: searchFilterForPolice,
        columns: policeColumns,
        title: 'Other District Officers',
        validators: validatorsForPolice,
    },
    'transfer-officer': {
        pathName: 'transfer-officer',
        apiUrlKey: 'Users/FetchPoliceUsers',
        searchFilter: searchFilterForTransferPolice,
        columns: transferPoliceColumns,
        title: 'Transfer Police Users',
        validators: validatorsForPolice,
    },
    'police-list': {
        pathName: 'police-list',
        apiUrlKey: 'Users/FetchPoliceUsersBySearchParams',
        searchFilter: searchFilterForPolice,
        columns: policeColumns,
        title: 'Police Users',
        validators: validatorsForPolice,
    },
    'criminals-home': {
        pathName: 'criminals',
        apiUrlKey: 'Criminals/FetchCriminal',
        searchFilter: searchFilterForCriminalDashboard,
        columns: criminalColumns,
        title: 'Dashboard',
        validators: validatorsForCriminal,
    },
    criminals: {
        pathName: 'criminals',
        apiUrlKey: 'Criminals/FetchCriminal',
        searchFilter: searchFilterForCriminal,
        columns: criminalColumns,
        title: 'All Criminals Data',
        validators: validatorsForCriminal,
    },
    'all-police-users': {
        pathName: 'police',
        apiUrlKey: 'Users/FetchPoliceUsers',
        searchFilter: searchFilterForPolice,
        columns: policeColumns,
        title: 'All Police Officer',
        validators: validatorsForPolice,
    },
    'your-police-users': {
        pathName: (statUserId) => `police?police_station_id=${statUserId}`,
        apiUrlKey: 'Users/FetchPoliceUsersByStation',
        searchFilter: searchFilterForPolice({ hidePoliceStation: true }),
        columns: policeColumns,
        title: 'Your Police Station Officer',
        validators: validatorsForPolice,
    },
    'office-type': {
        pathName: 'office-type',
        apiUrlKey: 'Fetch/officeType',
        searchFilter: searchFilterForOfficeType,
        columns: officeTypeColumns,
        title: 'Office Type',
        validators: validatorsForOfficeType,
    },
    'upcoming-retirement': {
        pathName: 'upcoming-retirement',
        apiUrlKey: 'UsersRetirement/fetchUpcomingRetirement',
        searchFilter: searchFilterForUpcomingRetirement,
        columns: upcomingRetirementColumns,
        title: 'Upcoming Retiring Police',
        validators: validatorsForUpcomingRetirement,
    },
    'retired-police': {
        pathName: 'retired-police',
        apiUrlKey: 'UsersRetirement/fetchRetired',
        searchFilter: searchFilterForUpcomingRetirement,
        columns: upcomingRetirementColumns,
        title: 'Retired Police',
        validators: validatorsForUpcomingRetirement,
    },
    'transfer-list': {
        pathName: 'transfer-list',
        apiUrlKey: 'UsersTransfer/fetchTransfers',
        searchFilter: searchFilterForTransferList,
        columns: transferListColumns,
        title: 'Police Transfer List',
        validators: validatorsForTransferList,
    },
};

export default listViewOptions;
