import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userTrainingSchema,
} from './inputFields/userTrainingFormFields';

const UserTrainingForm = ({
    userId,
    viewOnly,
    updating,
    mutate,
    deleteTraining,
    list,
    trainingTypes,
}) => {
    const [updatedUserTraining, setUpdatedUserTraining] = useState({
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedUserTraining((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userTrainingSchema.validate(updatedUserTraining, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserTraining);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            formTitle="Training Details"
            addButtonTxt="Add Training"
            data={updatedUserTraining}
            onChange={handleUpdate}
            inputFields={inputFields({}, trainingTypes)}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedUserTraining({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={mutate ? 'Add' : updating ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() =>
                                    deleteTraining(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const UserTrainingFormCard = ({ viewOnly, componentWrapper }) => {
    let { userId } = useParams();

    const axios = useAxios();

    const policeUserTraining = useQuery(
        ['UsersTraining/fetch_user_training', userId],
        async () => {
            const { data } = await axios
                .get(`UsersTraining/fetch_user_training?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const policeUserTrainingType = useQuery(
        ['UsersTraining/fetch_training_type'],
        async () => {
            const { data } = await axios
                .get(`UsersTraining/fetch_training_type`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPoliceTraining = useMutation(async (userTrainingDetails) => {
        const response = await axios
            .post(
                'UsersTraining/add_user_training',
                JSON.stringify(userTrainingDetails),
            )
            .then((res) => {
                policeUserTraining.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePoliceTraining = useMutation(async (userTrainingDetails) => {
        const response = await axios
            .post(
                'UsersTraining/delete_user_training',
                JSON.stringify(userTrainingDetails),
            )
            .then((res) => {
                policeUserTraining.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    if (
        addPoliceTraining.isLoading ||
        deletePoliceTraining.isLoading ||
        policeUserTrainingType.isLoading
    ) {
        return <Spinner />;
    }

    if (addPoliceTraining.error || deletePoliceTraining.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserTrainingForm
                    userId={userId}
                    viewOnly={viewOnly}
                    mutate={addPoliceTraining.mutate}
                    deleteTraining={deletePoliceTraining.mutate}
                    list={policeUserTraining.data}
                    trainingTypes={policeUserTrainingType.data || []}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserTraining.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserTrainingFormCard;
