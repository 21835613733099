import React from 'react';

import {
    APPLICATION_COPYRIGHT,
    APPLICATION_PROVIDER,
    APPLICATION_PROVIDER_LINK,
} from '../../constants';

const AuthFooter = () => (
    <footer className="footer w-full bottom-0 bg-police-blue pb-6">
        <div className="container mx-auto px-4">
            <hr className="mb-6 border-b-1 border-yellow-400" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
                <div className="w-full px-4">
                    <div className="text-sm text-gray-500 font-semibold py-1 text-center">
                        {APPLICATION_COPYRIGHT}
                        <br />
                        <a
                            href={APPLICATION_PROVIDER_LINK}
                            className="text-white hover:text-gray-300 text-sm font-semibold py-1"
                        >
                            {APPLICATION_PROVIDER}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default AuthFooter;
