import React from 'react';

import { useAppData } from '../../AppProvider';
import UserDetails from '../UserDetails';

const Navbar = () => {
    const { appData } = useAppData();
    return (
        <>
            <nav className="no-print top-0 left-0 w-full z-10 md:flex-row md:flex-nowrap md:justify-start flex items-center p-4 bg-police-blue text-white">
                <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                    <div>
                        {appData.pageTitle && (
                            <h1 className="text-2xl text-white font-normal leading-normal mt-0 mb-2">
                                {appData.pageTitle}
                            </h1>
                        )}
                    </div>
                    <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
                        <UserDetails />
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default Navbar;
