import { isFunction, pick, reduce, toLower } from 'lodash';
import React, { useMemo } from 'react';
import { BsFillEyeFill } from 'react-icons/bs';
import { exportToExcel } from 'react-json-to-excel';
import { Link, useSearchParams } from 'react-router-dom';

import ViewList, { FetchListView } from '../../components/ViewList';

const srNoCell = {
    Header: 'Sr. No',
    id: 'index',
    className: 'text-center w-28 px-4',
    accessor: (_row, i) => (_row.TITLE === 'Total' ? '' : i + 1),
};

const searchFilterForZero = {
    type: 'text',
    keyName: 'ignore_zero',
    label: 'Ignore',
    defaultValue: '0',
    className: 'hidden w-full md:w-1/4 md:pr-2',
};

const defaultValidators = {
    ignore_zero: (list, value) => toLower(list.TOTAL_COUNT) !== toLower('0'),
};

const maleFemaleTotalCountCell = [
    {
        Header: 'Male',
        accessor: 'MALE_COUNT',
        className: 'text-center w-28 px-4',
    },
    {
        Header: 'Female',
        accessor: 'FEMALE_COUNT',
        className: 'text-center w-28 px-4',
    },
    {
        Header: 'Total',
        accessor: 'TOTAL_COUNT',
        className: 'text-center w-28 px-4',
    },
];

const viewList = (title) => ({
    Header: 'Link',
    accessor: 'SEARCH_KEY',
    className: 'px-4 w-8 text-center',
    Cell: ({ value, ...props }) => {
        const searchKey = {
            data: {
                title: `By ${title} (${props.row.original.TITLE})`,
                station_type: props.row.original.station_type,
            },
            condition: value,
        };
        console.log(searchKey);
        const encodedSearchKey = encodeURIComponent(
            btoa(JSON.stringify(searchKey)),
        );
        return value ? (
            <Link to={`/records/police-list?search=${encodedSearchKey}`}>
                <BsFillEyeFill className="inline" />
            </Link>
        ) : null;
    },
});

const viewListType = {
    1: 'admins',
    2: 'police-stations',
    3: 'your-police-users',
    4: 'criminals-home',
};

const listViewOptions = {
    fetchAgeDetailsByGroup: {
        hideIfNoData: true,
        pathName: 'fetchAgeDetailsByGroup',
        apiUrlKey: 'UsersByGroup/fetchAgeDetailsByGroup',
        searchFilter: [
            searchFilterForZero,
            {
                className: 'w-full md:w-10/12 md:pl-2 my-2 flex items-center',
                render: () => <h2 className="font-bold">{'By Age'}</h2>,
            },
            {
                className: 'w-full md:w-2/12 md:pl-2 my-2',
                render: (props) => {
                    const downloadingData = props?.map((x) =>
                        pick(x, [
                            'TITLE',
                            'TOTAL_COUNT',
                            'MALE_COUNT',
                            'FEMALE_COUNT',
                        ]),
                    );
                    return (
                        <button
                            className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            onClick={() =>
                                downloadingData?.length &&
                                exportToExcel(
                                    downloadingData,
                                    `police_list_${new Date()}`,
                                    false,
                                )
                            }
                            style={{ marginTop: 2 }}
                        >
                            {'Download'}
                        </button>
                    );
                },
                hide: (user) =>
                    user?.user_role_id === '4' || user?.user_role_id === '3',
            },
        ],
        columns: [
            srNoCell,
            {
                Header: 'Age Group',
                accessor: 'TITLE',
                className: 'px-4',
            },
            ...maleFemaleTotalCountCell,
            viewList('Age'),
        ],
        title: 'Age Group',
        validators: defaultValidators,
    },
    fetchPostDetailsByGroup: {
        hideIfNoData: true,
        pathName: 'fetchPostDetailsByGroup',
        apiUrlKey: 'UsersByGroup/fetchPostDetailsByGroup',
        searchFilter: [
            searchFilterForZero,
            {
                className: 'w-full md:w-10/12 md:pl-2 my-2 flex items-center',
                render: () => (
                    <h2 className="font-bold">{'By Officer Type'}</h2>
                ),
            },
            {
                className: 'w-full md:w-2/12 md:pl-2 my-2',
                render: (props) => {
                    const downloadingData = props?.map((x) =>
                        pick(x, [
                            'TITLE',
                            'TOTAL_COUNT',
                            'MALE_COUNT',
                            'FEMALE_COUNT',
                        ]),
                    );
                    return (
                        <button
                            className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            onClick={() =>
                                downloadingData?.length &&
                                exportToExcel(
                                    downloadingData,
                                    `police_list_${new Date()}`,
                                    false,
                                )
                            }
                            style={{ marginTop: 2 }}
                        >
                            {'Download'}
                        </button>
                    );
                },
                hide: (user) =>
                    user?.user_role_id === '4' || user?.user_role_id === '3',
            },
        ],
        columns: [
            srNoCell,
            {
                Header: 'Officer Type',
                accessor: 'TITLE',
                className: 'px-4',
            },
            ...maleFemaleTotalCountCell,
            viewList('Officer Type'),
        ],
        title: 'Officer Type',
        validators: defaultValidators,
    },
    fetchMedicalDetailsByGroup: {
        hideIfNoData: true,
        pathName: 'fetchMedicalDetailsByGroup',
        apiUrlKey: 'UsersByGroup/fetchMedicalDetailsByGroup',
        searchFilter: [
            searchFilterForZero,
            {
                className: 'w-full md:w-10/12 md:pl-2 my-2 flex items-center',
                render: () => (
                    <h2 className="font-bold">{'By Medical Details'}</h2>
                ),
            },
            {
                className: 'w-full md:w-2/12 md:pl-2 my-2',
                render: (props) => {
                    const downloadingData = props?.map((x) =>
                        pick(x, [
                            'TITLE',
                            'TOTAL_COUNT',
                            'MALE_COUNT',
                            'FEMALE_COUNT',
                        ]),
                    );
                    return (
                        <button
                            className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            onClick={() =>
                                downloadingData?.length &&
                                exportToExcel(
                                    downloadingData,
                                    `police_list_${new Date()}`,
                                    false,
                                )
                            }
                            style={{ marginTop: 2 }}
                        >
                            {'Download'}
                        </button>
                    );
                },
                hide: (user) =>
                    user?.user_role_id === '4' || user?.user_role_id === '3',
            },
        ],
        columns: [
            srNoCell,
            {
                Header: 'Medical History',
                accessor: 'TITLE',
                className: 'px-4',
            },
            ...maleFemaleTotalCountCell,
            viewList('Medical Details'),
        ],
        title: 'Medical History',
        validators: defaultValidators,
    },
    fetchHealthDetailsByGroup: {
        hideIfNoData: true,
        pathName: 'fetchHealthDetailsByGroup',
        apiUrlKey: 'UsersByGroup/fetchHealthDetailsByGroup',
        searchFilter: [
            searchFilterForZero,
            {
                className: 'w-full md:w-10/12 md:pl-2 my-2 flex items-center',
                render: () => (
                    <h2 className="font-bold">{'By Health Details'}</h2>
                ),
            },
            {
                className: 'w-full md:w-2/12 md:pl-2 my-2',
                render: (props) => {
                    const downloadingData = props?.map((x) =>
                        pick(x, [
                            'TITLE',
                            'TOTAL_COUNT',
                            'MALE_COUNT',
                            'FEMALE_COUNT',
                        ]),
                    );
                    return (
                        <button
                            className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            onClick={() =>
                                downloadingData?.length &&
                                exportToExcel(
                                    downloadingData,
                                    `police_list_${new Date()}`,
                                    false,
                                )
                            }
                            style={{ marginTop: 2 }}
                        >
                            {'Download'}
                        </button>
                    );
                },
                hide: (user) =>
                    user?.user_role_id === '4' || user?.user_role_id === '3',
            },
        ],
        columns: [
            srNoCell,
            {
                Header: 'Surgical History',
                accessor: 'TITLE',
                className: 'px-4',
            },
            ...maleFemaleTotalCountCell,
            viewList('Health Details'),
        ],
        title: 'Surgical History',
        validators: defaultValidators,
    },
    fetchTrainingByGroup: {
        hideIfNoData: true,
        pathName: 'fetchTrainingByGroup',
        apiUrlKey: 'UsersByGroup/fetchTrainingByGroup',
        searchFilter: [
            searchFilterForZero,
            {
                className: 'w-full md:w-10/12 md:pl-2 my-2 flex items-center',
                render: () => <h2 className="font-bold">{'By Training'}</h2>,
            },
            {
                className: 'w-full md:w-2/12 md:pl-2 my-2',
                render: (props) => {
                    const downloadingData = props?.map((x) =>
                        pick(x, [
                            'TITLE',
                            'TOTAL_COUNT',
                            'MALE_COUNT',
                            'FEMALE_COUNT',
                        ]),
                    );
                    return (
                        <button
                            className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                            onClick={() =>
                                downloadingData?.length &&
                                exportToExcel(
                                    downloadingData,
                                    `police_list_${new Date()}`,
                                    false,
                                )
                            }
                            style={{ marginTop: 2 }}
                        >
                            {'Download'}
                        </button>
                    );
                },
                hide: (user) =>
                    user?.user_role_id === '4' || user?.user_role_id === '3',
            },
        ],
        columns: [
            srNoCell,
            {
                Header: 'Training Type',
                accessor: 'TITLE',
                className: 'px-4',
            },
            ...maleFemaleTotalCountCell,
            viewList('Training'),
        ],
        title: 'Dashboard',
        validators: defaultValidators,
    },
};

const ViewListForSuperAdmin = ({ defaultType, user, noPagination }) => {
    let [searchParams] = useSearchParams();

    const listViewOption = listViewOptions[defaultType];

    const top_level_id = searchParams.get('top_level_id');

    const defaultSearchValue = useMemo(
        () =>
            reduce(
                listViewOption.searchFilter,
                (acc, val) => ({
                    ...acc,
                    [val.keyName]: isFunction(val.defaultValue)
                        ? val.defaultValue()
                        : val.defaultValue,
                }),
                {},
            ),
        [listViewOption.searchFilter],
    );

    if (!listViewOption) {
        return null;
    }

    return (
        <FetchListView
            {...listViewOption}
            defaultSearchValue={defaultSearchValue}
            topLevelId={top_level_id}
            user={user}
            noPagination={noPagination}
        />
    );
};

export default function Dashboard({ user }) {
    if (user?.user_role_id !== '4') {
        return (
            <div className="dashboard-list">
                <ViewListForSuperAdmin
                    defaultType={'fetchAgeDetailsByGroup'}
                    user={user}
                    noPagination
                />
                <ViewListForSuperAdmin
                    defaultType={'fetchPostDetailsByGroup'}
                    user={user}
                    noPagination
                />
                {user && user.user_role_id !== '4' && (
                    <>
                        <ViewListForSuperAdmin
                            defaultType={'fetchMedicalDetailsByGroup'}
                            user={user}
                            noPagination
                        />
                        <ViewListForSuperAdmin
                            defaultType={'fetchHealthDetailsByGroup'}
                            user={user}
                            noPagination
                        />
                        <ViewListForSuperAdmin
                            defaultType={'fetchTrainingByGroup'}
                            user={user}
                            noPagination
                        />
                    </>
                )}
            </div>
        );
    }
    return <ViewList defaultType={viewListType[user?.user_role_id]} />;
}
