import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostCreateUserHealth = (options = {}) => {
    const axios = useAxios();
    return useMutation(async (userDetails) => {
        const { data } = await axios
            .post('usersHealth/add_user_health', JSON.stringify(userDetails))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    }, options);
};

export default usePostCreateUserHealth;
