import classNames from 'classnames';
import { filter, map } from 'lodash';
import React, { useEffect, useMemo } from 'react';

import useFetchList from '../../hooks/useFetchList';
import InputSelect from '../Form/InputSelect';
import InputText from '../Form/InputText';

export const AsyncSelect = ({
    fetchOptionsKey,
    optionMapper,
    optionFilter,
    onChange,
    defaultOption,
    ...props
}) => {
    const fetchedOptions = useFetchList(fetchOptionsKey);

    const optionsList = useMemo(
        () => filter(map(fetchedOptions.data, optionMapper), optionFilter),
        [optionFilter, optionMapper, fetchedOptions.data],
    );

    useEffect(() => {
        if (optionsList.length === 1) {
            onChange(optionsList[0]);
        }
    }, [optionsList, onChange]);

    const defaultOptions = useMemo(
        () => (defaultOption ? [defaultOption] : []),
        [defaultOption],
    );

    const resetOption = useMemo(
        () => ({
            label: `Select ${props.label}`,
            value: null,
        }),
        [props.label],
    );

    const options = useMemo(() => {
        return [resetOption, ...optionsList, ...defaultOptions];
    }, [defaultOptions, optionsList, resetOption]);

    return <InputSelect {...props} onChange={onChange} options={options} />;
};

export const renderSearchInput = (
    searchField,
    searchValues,
    setSearchValues,
    searchResult,
    hide,
) => {
    if (hide) {
        return null;
    }
    if (searchField.render) {
        return (
            <div key={searchField} className={searchField.className}>
                {searchField.render(searchResult)}
            </div>
        );
    }
    if (searchField.type === 'text') {
        return (
            <div key={searchField} className={searchField.className}>
                <InputText
                    value={searchValues[searchField.keyName]}
                    onChange={(e) =>
                        setSearchValues((sv) => ({
                            ...sv,
                            [searchField.keyName]: e.target.value,
                        }))
                    }
                    keyName={searchField.keyName}
                    label={searchField.label}
                    placeholder={`Search ${searchField.label}`}
                    hideErrors
                />
            </div>
        );
    }
    if (searchField.type === 'select') {
        return (
            <div key={searchField} className={searchField.className}>
                <InputSelect
                    value={searchValues[searchField.keyName]}
                    onChange={(option) => {
                        if (!searchField.isMultiSelect) {
                            setSearchValues((sv) => ({
                                ...sv,
                                [searchField.keyName]: option.value,
                            }));
                        } else {
                            setSearchValues((sv) => ({
                                ...sv,
                                [searchField.keyName]: option.length
                                    ? option?.map((x) => x.value)
                                    : null,
                            }));
                        }
                    }}
                    options={
                        searchField.isMultiSelect
                            ? searchField.options
                            : [
                                  {
                                      label: `Select ${searchField.label}`,
                                      value: null,
                                  },
                                  ...searchField.options,
                              ]
                    }
                    keyName={searchField.keyName}
                    label={searchField.label}
                    disabled={searchField.disabled}
                    isMultiSelect={searchField.isMultiSelect}
                    placeholder={`Search ${searchField.label}`}
                    hideErrors
                    getOptionLabel={(option) => {
                        return option.type ? (
                            <span
                                className={classNames({
                                    'text-red-700':
                                        option.type === 'surgical_history',
                                })}
                            >
                                {option.label}
                            </span>
                        ) : (
                            option.label
                        );
                    }}
                />
            </div>
        );
    }
    if (searchField.type === 'asyncSelect') {
        return (
            <div key={searchField} className={searchField.className}>
                <AsyncSelect
                    value={searchValues[searchField.keyName]}
                    onChange={(option) =>
                        setSearchValues((sv) => ({
                            ...sv,
                            [searchField.keyName]: option.value,
                        }))
                    }
                    disabled={searchField.disabled}
                    keyName={searchField.keyName}
                    label={searchField.label}
                    fetchOptionsKey={searchField.fetchOptionsKey}
                    optionMapper={searchField.optionMapper}
                    optionFilter={searchField.optionFilter}
                    defaultOption={searchField.defaultOption}
                    placeholder={`Search ${searchField.label}`}
                    hideErrors
                />
            </div>
        );
    }
};
