import ReactSelect from 'react-select';

const InputSelect = ({
    keyName,
    value,
    options,
    onChange,
    disabled,
    label,
    helpText,
    isMultiSelect,
    placeholder,
    error,
    hideErrors,
    ...props
}) => (
    <div className="relative w-full mb-3">
        <label
            className="block text-gray-600 text-xs font-bold mb-2"
            htmlFor={`select_${keyName}`}
        >
            {label}
        </label>
        <p className="block text-blue-400 text-xs font-bold mb-2">{helpText}</p>
        <ReactSelect
            {...props}
            id={`select_${keyName}`}
            isDisabled={disabled}
            options={options}
            isMulti={isMultiSelect}
            placeholder={placeholder}
            value={options?.find((x) => x.value === value)}
            onChange={onChange}
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: 'transparent',
                    border: 0,
                    boxShadow: 'none',
                    '&:hover': {
                        border: 0,
                    },
                }),
            }}
            className="border px-2 py-1 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        {!hideErrors && (
            <p className="text-red-500" style={{ minHeight: 24 }}>
                {error}
            </p>
        )}
    </div>
);

export default InputSelect;
