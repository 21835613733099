import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userRewardSchema,
} from './inputFields/userRewardFormFields';

const UserRewardForm = ({
    userId,
    viewOnly,
    updating,
    mutate,
    deleteReward,
    list,
}) => {
    const [updatedUserReward, setUpdatedUserReward] = useState({
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedUserReward((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userRewardSchema.validate(updatedUserReward, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserReward);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            formTitle="Reward Details"
            addButtonTxt="Add Reward"
            data={updatedUserReward}
            onChange={handleUpdate}
            inputFields={inputFields({})}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedUserReward({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={mutate ? 'Add' : updating ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() => deleteReward(props.row.original)}
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const UserRewardFormCard = ({ viewOnly, componentWrapper }) => {
    let { userId } = useParams();

    const axios = useAxios();

    const policeUserReward = useQuery(
        ['UsersReward/fetch_user_reward', userId],
        async () => {
            const { data } = await axios
                .get(`UsersReward/fetch_user_reward?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPoliceReward = useMutation(async (userRewardDetails) => {
        const response = await axios
            .post(
                'UsersReward/add_user_reward',
                JSON.stringify(userRewardDetails),
            )
            .then((res) => {
                policeUserReward.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePoliceReward = useMutation(async (userRewardDetails) => {
        const response = await axios
            .post(
                'UsersReward/delete_user_reward',
                JSON.stringify(userRewardDetails),
            )
            .then((res) => {
                policeUserReward.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    if (addPoliceReward.isLoading || deletePoliceReward.isLoading) {
        return <Spinner />;
    }

    if (addPoliceReward.error || deletePoliceReward.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserRewardForm
                    userId={userId}
                    viewOnly={viewOnly}
                    mutate={addPoliceReward.mutate}
                    deleteReward={deletePoliceReward.mutate}
                    list={policeUserReward.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserReward.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserRewardFormCard;
