import React, { useState } from 'react';
import { useNavigate } from 'react-router';

import authStorage from '../../auth';
import authUserStorage from '../../authUser';
import Spinner from '../../components/Spinner';
import usePostLogin from '../../hooks/usePostLogin';

const Login = () => {
    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const userLogin = usePostLogin();
    const onLogin = () => {
        userLogin.mutate({
            email_address: emailAddress,
            password: password,
        });
    };

    if (!userLogin.isLoading && !userLogin.isError && userLogin.isSuccess) {
        if (userLogin.data.access_token) {
            authStorage.setToken(userLogin.data.access_token);
            authUserStorage.setUser(userLogin.data.data);
            userLogin.reset();
            navigate('/', { replace: true });
        }
    }

    return (
        <>
            {userLogin.isLoading && <Spinner />}
            <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                    <div className="w-full lg:w-4/12 px-4">
                        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-200 border-0">
                            <div className="rounded-t mb-0 px-6 py-6">
                                <div className="text-center mb-3">
                                    <h6 className="text-gray-500 text-sm font-bold">
                                        Sign in with
                                    </h6>
                                </div>
                                <hr className="mt-6 border-b-1 border-gray-300" />
                            </div>
                            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                                <div autoComplete="cd-police">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Email"
                                            value={emailAddress}
                                            autoComplete="cd-police"
                                            onChange={(e) =>
                                                setEmailAddress(e.target.value)
                                            }
                                        />
                                    </div>

                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-600 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="border-0 px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                                            placeholder="Password"
                                            value={password}
                                            autoComplete="cd-police"
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="text-center mt-6">
                                        <button
                                            className="bg-police-blue text-white hover:text-black active:bg-yellow-400 hover:bg-yellow-400 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={onLogin}
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                    {userLogin.isError && (
                                        <p className="text-center text-red-500">
                                            {
                                                userLogin.error?.response?.data
                                                    ?.message
                                            }
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
