import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchUserDetails = () => {
    const axios = useAxios();
    return useQuery('users/fetchLoggedInUser', async () => {
        const response = await axios
            .get('users/fetchLoggedInUser')
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response?.data?.data;
    });
};

export default useFetchUserDetails;
