import React from 'react';

import {
    APPLICATION_COPYRIGHT,
    APPLICATION_PROVIDER,
    APPLICATION_PROVIDER_LINK,
} from '../../constants';

const Footer = () => (
    <footer className="footer block py-4">
        <div className="container mx-auto px-4">
            <hr className="mb-4 border-b-1 border-gray-200" />
            <div className="flex flex-wrap items-center md:justify-between justify-center">
                <div className="w-full md:w-4/12 px-4">
                    <div className="text-sm text-gray-500 font-semibold py-1 text-center md:text-left">
                        {APPLICATION_COPYRIGHT}
                    </div>
                </div>
                <div className="w-full md:w-8/12 px-4">
                    <ul className="flex flex-wrap list-none md:justify-end  justify-center">
                        <li>
                            <a
                                href={APPLICATION_PROVIDER_LINK}
                                className="text-gray-600 hover:text-gray-800 text-sm font-semibold block py-1 px-3"
                            >
                                {APPLICATION_PROVIDER}
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
