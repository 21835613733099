import { useEffect, useMemo, useState } from 'react';

export const useGeoCode = (skip, skipGeoLocation) => {
    const [geoCode, setGeoCode] = useState({
        latitude: 18.7746708,
        longitude: 72.2699882,
    });

    useEffect(() => {
        if (!skipGeoLocation) {
            navigator.permissions
                .query({ name: 'geolocation' })
                .then((stat) => {
                    if (stat.state !== 'granted' && !skip) {
                        alert(
                            'Please grant access to your geo location and reload the page.',
                        );
                    }
                });
            navigator.geolocation.getCurrentPosition(
                (pos) => {
                    const crd = pos.coords;
                    setGeoCode({
                        latitude: crd.latitude,
                        longitude: crd.longitude,
                    });
                },
                console.log,
                {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 0,
                },
            );
        }
    }, [skip, skipGeoLocation]);

    return useMemo(() => ({ geoCode }), [geoCode]);
};
