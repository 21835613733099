import ReactSelect from 'react-select';

export const userSuffix = [
    {
        value: 'Mr.',
        label: 'Mr.',
    },
    {
        value: 'Miss.',
        label: 'Miss.',
    },
];

const InputCustomName = ({
    disabled,
    onChange,
    data,
    error,
    onChangeOverride,
    hideErrors,
}) => (
    <div className="relative w-full mb-3">
        <label
            className="block text-gray-600 text-sm font-bold mb-2"
            htmlFor="user_name"
        >
            {'Full Name ( in English)'}
        </label>
        <p className="block text-blue-400 text-xs font-bold mb-2">
            {'पूर्ण नाव - इंग्लिश मध्ये लिहणे असून स्पेल्लिंग न चुकता लिहावे.'}
        </p>
        <div className="flex">
            {data?.user_role_id === '4' && (
                <div style={{ width: 175 }}>
                    <ReactSelect
                        isDisabled={disabled}
                        options={userSuffix}
                        value={userSuffix?.find(
                            (x) => x.value === data.name_suffix,
                        )}
                        defaultValue={userSuffix[0]}
                        onChange={(e) =>
                            onChange({
                                name_suffix: e.value,
                            })
                        }
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: 'transparent',
                                border: 0,
                            }),
                        }}
                        className="border px-2 py-1 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                </div>
            )}
            <div className="w-full">
                <input
                    id="user_name"
                    type="text"
                    disabled={disabled}
                    value={data?.name || ''}
                    className="border px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={(e) =>
                        onChange({
                            name: onChangeOverride
                                ? onChangeOverride(e.target.value)
                                : e.target.value,
                        })
                    }
                />
                {!hideErrors && (
                    <p className="text-red-500" style={{ minHeight: 24 }}>
                        {error}
                    </p>
                )}
            </div>
        </div>
    </div>
);

export default InputCustomName;
