import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    criminalFamilyDetailsSchema,
} from './inputFields/criminalFamiliesFormFields.jsx';

const CriminalFamiliesForm = ({
    userId,
    viewOnly,
    updating,
    mutate,
    deleteCriminalFamily,
    list,
}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [updatedCriminalFamilyDetails, setUpdatedCriminalFamilyDetails] =
        useState({
            criminal_id: userId,
        });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedCriminalFamilyDetails((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await criminalFamilyDetailsSchema.validate(
                updatedCriminalFamilyDetails,
                {
                    abortEarly: false,
                },
            );
            setErrors({});
            mutate(updatedCriminalFamilyDetails);
            setIsOpen(false);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            isOpen={modalIsOpen}
            toggle={setIsOpen}
            formTitle="Family Details"
            addButtonTxt="Add Family Details"
            data={updatedCriminalFamilyDetails}
            onChange={handleUpdate}
            inputFields={inputFields({}, modalIsOpen)}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedCriminalFamilyDetails({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={modalIsOpen ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() => {
                                    setIsOpen(true);
                                    setUpdatedCriminalFamilyDetails(
                                        props.row.original,
                                    );
                                }}
                                className={
                                    'text-sm border border-black mx-2 px-2 py-1 w-auto font-normal block whitespace-nowrap bg-blue-200 text-gray-700'
                                }
                            >
                                {'Edit'}
                            </button>
                            <button
                                onClick={() =>
                                    deleteCriminalFamily(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const CriminalFamiliesFormCard = ({ viewOnly, componentWrapper }) => {
    let { criminalId } = useParams();

    const axios = useAxios();

    const criminalFamilyDetails = useQuery(
        ['CriminalFamilies/fetch_criminal_family', criminalId],
        async () => {
            const { data } = await axios
                .get(
                    `CriminalFamilies/fetch_criminal_family?criminal_id=${criminalId}`,
                )
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addCriminalFamilyDetails = useMutation(
        async (CriminalFamilyDetailsDetails) => {
            const response = await axios
                .post(
                    'CriminalFamilies/add_criminal_family',
                    JSON.stringify(CriminalFamilyDetailsDetails),
                )
                .then((res) => {
                    criminalFamilyDetails.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    const deleteCriminalFamilyDetails = useMutation(
        async (criminalFamilyDetailsDetails) => {
            const response = await axios
                .post(
                    'CriminalFamilies/delete_criminal_family',
                    JSON.stringify(criminalFamilyDetailsDetails),
                )
                .then((res) => {
                    criminalFamilyDetails.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    if (
        addCriminalFamilyDetails.isLoading ||
        deleteCriminalFamilyDetails.isLoading
    ) {
        return <Spinner />;
    }

    if (addCriminalFamilyDetails.error || deleteCriminalFamilyDetails.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <CriminalFamiliesForm
                    userId={criminalId}
                    viewOnly={viewOnly}
                    mutate={addCriminalFamilyDetails.mutate}
                    deleteCriminalFamily={deleteCriminalFamilyDetails.mutate}
                    list={criminalFamilyDetails.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(criminalFamilyDetails.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default CriminalFamiliesFormCard;
