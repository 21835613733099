import { Link } from 'react-router-dom';

export const APP_NAME = 'Smart Cops';

const AppName = (props) => (
    <Link
        {...props}
        className={`"leading-relaxed md:block text-left mr-0 whitespace-nowrap text-sm font-bold ${props.className}`}
        to="/"
    >
        {APP_NAME}
    </Link>
);

export default AppName;
