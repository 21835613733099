import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchList = (apiUri, options = {}) => {
    const axios = useAxios();
    return useQuery(apiUri, async () => {
        const { data } = await axios
            .get(apiUri, options)
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    });
};

export default useFetchList;
