import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useAxios } from '../../../AxiosProvider';
import UserFormCard from '../../../components/FormCard/UserFormCard';
import Spinner from '../../../components/Spinner';

const Form = ({ skipGeoLocation }) => {
    const axios = useAxios();
    const navigate = useNavigate();
    const userRoles = useQuery('Fetch/userRoles', async () => {
        const { data } = await axios
            .get('Fetch/userRoles')
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.roles;
    });

    const policeAdminOptions = useQuery('Users/fetchAdminUsers', async () => {
        const { data } = await axios
            .get('Users/fetchAdminUsers')
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    });
    const policeStationOptions = useQuery(
        'Users/fetchPoliceStationAdminUsers',
        async () => {
            const { data } = await axios
                .get('Users/fetchPoliceStationAdminUsers')
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPolice = useMutation(async (userDetails) => {
        const { data } = await axios
            .post('users/createUser', JSON.stringify(userDetails))
            .then((res) => {
                if (res.data.success && res.data.data.user_id) {
                    navigate(`/form/health/${res.data.data.user_id}`, {
                        replace: true,
                    });
                }
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    });

    if (
        addPolice.isLoading ||
        userRoles.isLoading ||
        userRoles.isFetching ||
        !userRoles.data ||
        policeStationOptions.isLoading ||
        policeStationOptions.isFetching ||
        !policeStationOptions.data ||
        policeAdminOptions.isLoading ||
        policeAdminOptions.isFetching ||
        !policeAdminOptions.data
    ) {
        return <Spinner />;
    }

    return (
        <div className="mx-auto w-full">
            <h5 className="md:px-10  text-xl font-normal leading-normal my-2 px-6 text-center">
                {
                    'हा फॉर्म पोलीस अधिकारी, कर्मचारी/ अंमलदार, कार्यालयीन कर्मचारी/अंमलदार यांच्या साठी आहे.'
                }
            </h5>
            <hr className="border border-gray-300" />
            <p className="md:px-10 font-bold leading-normal mt-2 px-6 text-center">
                {
                    'फॉर्म भरायला सुरवात करण्या अगोदर खालील दिलेली माहिती वाचावी. माहिती भरत असताना संपूर्ण माहिती अचूक भरण्याची काळजी घ्यावी.'
                }
            </p>
            <p className="md:px-10 text-sm leading-normal mb-2 px-6 text-justify">
                {
                    'पोलीस दलातील सर्व अधिकारी तसेच कर्मचाऱ्यांकरिता हा फॉर्म असून पोलीस स्टेशन, अथवा कार्यालय इथे तैनाती असलेल्या सर्व कर्मचाऱ्यांनी हा फॉर्म भरायचा आहे. आपणास जर फॉर्म भरायला जमत नसेल तर आपल्या कार्यालयातील इतर कर्मचाऱ्यांची मदत घेऊन फॉर्म भरून घेणे आहे. पोलीस दलातील सर्व अधिकारी कर्मचारी यांची वैयक्तिक व वैद्यकीय माहिती आपण या फॉर्म च्या माध्यमातून गोळा करत आहोत. या माहिती चा उपयोग भविष्यात पोलीस दला मार्फत राबवण्यात येणाऱ्या योजनेसाठी व अधिकृत कामांसाठी करण्यात येणार असल्यामुळे सगळ्यांनी अचूक माहिती भरायची आहे याची दक्षता घ्यावी.'
                }
            </p>
            <hr className="border border-gray-300" />
            <UserFormCard
                user={{
                    user_role_id: 3,
                }}
                policeAdminOptions={policeAdminOptions.data}
                policeStationOptions={policeStationOptions.data}
                userRoles={userRoles.data}
                mutate={addPolice.mutate}
                skipGeoLocation={skipGeoLocation}
            />
        </div>
    );
};

export default Form;
