import { filter } from 'lodash';
import React from 'react';
import * as yup from 'yup';

import InputCustomName from '../../Form/InputCustomName';

export const AlphanumericRegx = /^[A-Z0-9]*$/;
export const PincodeRegx = /^[1-9][0-9]{5}$/;
export const MobileRegx = /^\d{10}$/;
export const AadharNoRegx = /^\d{4}\d{4}\d{4}$/;
export const PanNoRegx = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
export const EmailRegx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

export const userSchema = (isOpenForm, { user_role_id }) =>
    yup.object({
        ...(isOpenForm
            ? {
                  police_admin_id: yup.string().required('Required field'),
                  police_station_id: yup.string().required('Required field'),
                  password: yup.string().required('Required field'),
              }
            : {}),

        name: yup.string().required('Required field'),
        email_address: yup
            .string()
            .matches(EmailRegx, 'Email Address must be a valid email')
            .required('Required field'),
        ...(user_role_id === '4'
            ? {
                  gender: yup.string().required('Required field'),
                  education: yup.string().required('Required field'),

                  buckle_no: yup
                      .string()
                      .trim()
                      .matches(AlphanumericRegx, 'Alphanumeric')
                      .required('Required field'),
                  officer_post: yup.string().required('Required field'),
                  work_type: yup.string().required('Required field'),
                  caste_category: yup.string().required('Required field'),
                  caste: yup.string().required('Required field'),
                  caste_validity: yup.string().required('Required field'),
                  mobile_no: yup
                      .string()
                      .trim()
                      .matches(MobileRegx, '10 digit mandatory')
                      .required('Required field'),
                  alt_mobile_no: yup
                      .string()
                      .trim()
                      .matches(MobileRegx, '10 digit mandatory'),
                  wa_mobile_no: yup
                      .string()
                      .trim()
                      .matches(MobileRegx, '10 digit mandatory')
                      .required('Required field'),
                  family_no: yup
                      .string()
                      .trim()
                      .matches(MobileRegx, '10 digit mandatory')
                      .required('Required field'),

                  date_of_birth: yup.string().required('Required field'),
                  date_of_joining: yup.string().required('Required field'),
                  date_of_retirement: yup.string().required('Required field'),

                  aadhar_card_no: yup
                      .string()
                      .trim()
                      .matches(AadharNoRegx, '12 digit mandatory'),
                  aadhar_card_no_url: yup.string().nullable(true),
                  pan_no: yup
                      .string()
                      .trim()
                      .matches(PanNoRegx, 'PAN Card Validation AAAAA1234A'),
                  pan_no_url: yup.string().nullable(true),
                  sevarth_id: yup
                      .string()
                      .trim()
                      .matches(AlphanumericRegx, 'Alphanumeric')
                      .required('Required field'),

                  mode_of_selection: yup.string().required('Required field'),
                  confirmation_date: yup.string().required('Required field'),
                  mscit_computer_course: yup
                      .string()
                      .required('Required field'),
                  present_posting_start_date: yup
                      .string()
                      .required('Required field'),
                  present_district_posting_date: yup
                      .string()
                      .required('Required field'),

                  home_address: yup.string().required('Required field'),
                  home_pincode: yup
                      .string()
                      .trim()
                      .matches(PincodeRegx, 'Invalid Pin code')
                      .required('Required field'),
                  permanent_address: yup.string().required('Required field'),
                  permanent_pincode: yup
                      .string()
                      .trim()
                      .matches(PincodeRegx, 'Invalid Pin code')
                      .required('Required field')
                      .required('Required field'),
              }
            : {}),
    });

export const casteCategoryOptions = [
    'OPEN',
    'OBC',
    'SC',
    'ST',
    'NT - B',
    'NT - C',
    'NT - D',
    'VJ',
    'VJ - A',
    'VJNT',
    'SBC',
    'SEBC',
];

export const sortedOfficerRanks = [
    'Superintendent of Police',
    'Additional SP',
    'Asst. SP - IPS',
    'Dy SP',
    'Prob. Dy SP',
    'PI',
    'API',
    'G. PSI',
    'PSI',
    'ASI',
    'HC',
    'NPC',
    'PC',

    'Office SUperintendent',
    'Account Officer',
    'Head Clerk',
    'Senior Clerk',
    'Junior Clerk',
    'Clerk',
    'Peon',

    'Safai Karmachari',
    'Other',
];

export const officerRankOptions = [
    'PC',
    'NPC',
    'HC',
    'ASI',
    'G. PSI',
    'PSI',
    'API',
    'PI',
    'Prob. Dy SP',
    'Dy SP',
    'Asst. SP - IPS',
    'Additional SP',
    'Superintendent of Police',

    'Peon',
    'Clerk',
    'Junior Clerk',
    'Senior Clerk',
    'Head Clerk',
    'Account Officer',
    'Office SUperintendent',

    'Safai Karmachari',
    'Other',
];

export const genderOptions = [
    {
        value: 'Male',
        label: 'Male',
    },
    {
        value: 'Female',
        label: 'Female',
    },
];
export const renderCustomName = ({
    onChange,
    data,
    disabled,
    error,
    onChangeOverride,
}) => (
    <InputCustomName
        disabled={disabled}
        onChange={onChange}
        data={data}
        error={error}
        onChangeOverride={onChangeOverride}
    />
);

export const inputFields = (
    userById,
    viewOnly,
    viewProfile,
    userRoles,
    policeAdminOptions,
    policeStationOptions,
) => [
    {
        groupName: '',
        inputFields: [
            policeAdminOptions && {
                keyName: 'police_admin_id',
                label: 'Select Head Office',
                type: 'select',
                options: policeAdminOptions,
                optionMapper: (x) => ({ label: x.name, value: x.user_id }),
                className: 'w-full md:w-6/12',
            },
            policeStationOptions && {
                keyName: 'police_station_id',
                label: 'Select Police Station / Office',
                type: 'select',
                options: (data) =>
                    data?.police_admin_id
                        ? filter(
                              policeStationOptions,
                              (x) =>
                                  x.police_division_id === data.police_admin_id,
                          )
                        : policeStationOptions,
                optionMapper: (x) => ({ label: x.name, value: x.user_id }),
                className: 'w-full md:w-6/12',
            },
        ],
    },
    {
        groupName: 'User Information',
        inputFields: [
            {
                keyName: 'user_name',
                label: 'Full Name ( in English)',
                helpText:
                    'पूर्ण नाव - इंग्लिश मध्ये लिहणे असून स्पेल्लिंग न चुकता लिहावे.',
                render: renderCustomName,
                errorKeyName: 'name',
                className: 'w-full md:w-6/12',
                onChangeOverride: (val) => titleCase(val),
            },
            userById.user_role_id === '4' && {
                keyName: 'gender',
                label: 'Gender',
                type: 'select',
                options: genderOptions,
                optionMapper: (x) => x,
                className: 'w-full md:w-6/12',
            },
            {
                keyName: 'email_address',
                label: 'Email Address',
                helpText:
                    'जर आपणास ई-मेल ID काय आहे हे माहिती नसेल तर, कार्यालयातील इतर कर्मचाऱ्यांची मदत घ्यावी.',
                type: 'email',
                className: 'w-full md:w-6/12',
            },
            !(viewOnly || viewProfile) && {
                keyName: 'password',
                label: 'Password',
                helpText:
                    'तुम्हाला जे पासवर्ड ठेवायचे आहे ते इथे लिहायचे आहे. कुणीही आपले Gmail चे पासवर्ड टाकू नये.',
                type: 'password',
            },
            userById.user_role_id === '4' && {
                keyName: 'education',
                label: 'Education - शिक्षण',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            userById.user_role_id === '4' && {
                keyName: 'buckle_no',
                label: 'Bukkle No. - बक्कल नंबर',
                helpText: 'आपणास बक्कल नंबर नसेल तर जागा रिकामी सोडावी.',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            userById.user_role_id === '4' && {
                keyName: 'officer_post',
                label: 'Post / Rank / हुद्दा',
                helpText: 'आपला हुद्दा ड्रॉपडाउन मधून सिलेक्ट करा.',
                type: 'select',
                options: officerRankOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            userById.user_role_id === '4' && {
                keyName: 'work_type',
                label: 'Work Type',
                helpText:
                    'पोलीस स्टेशन, कार्यालय मध्ये आपल्या कडे कोणते काम आहे त्याच्या बद्दल इथे लिहायचे आहे. जसे मोहरा, ड्युटी अंमलदार, रीडर इत्यादी...',
                type: 'text',
                onChangeOverride: (val) => titleCase(val),
            },
            userById.user_role_id === '4' && {
                keyName: 'caste_category',
                label: 'Caste Category',
                helpText: 'जातीचे वगकरण',
                type: 'select',
                options: casteCategoryOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            userById.user_role_id === '4' && {
                keyName: 'caste',
                label: 'Caste',
                helpText: 'तुमा जातीचे नाव िलहायचे आहे. ',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            userById.user_role_id === '4' && {
                keyName: 'caste_validity',
                label: 'Caste Validity',
                helpText: 'तुमा कडे जात वैधता माणप आहे काय ?',
                type: 'select',
                options: ['Yes', 'No', 'Not Applicable'],
                optionMapper: (x) => ({ label: x, value: x }),
            },
        ],
    },
    userById.user_role_id === '4' && {
        inputFields: [
            {
                keyName: 'mobile_no',
                label: 'Mobile Number',
                helpText: 'आपला प्राथमिक मोबाइल नंबर.',
                type: 'text',
            },
            {
                keyName: 'alt_mobile_no',
                label: 'Alternate Mobile Number',
                helpText:
                    'आपला  वैकल्पिक  मोबाइल नंबर. दुसरा नंबर नसल्यास प्राथमिक नंबर परत लिहावे.',
                type: 'text',
            },
            {
                keyName: 'wa_mobile_no',
                label: 'Whatsapp Mobile Number',
                helpText:
                    'आपला व्हाट्सअँप मोबाइल नंबर. व्हाट्सअँप नसल्यास प्राथमिक नंबर परत लिहावे.',
                type: 'text',
            },
            {
                keyName: 'family_no',
                label: 'Family Mobile Number',
                helpText:
                    'कुटुंबातील व्यक्तीचे मोबाइलला नंबर. आपातकालीन काळात पोलीस दलाकडे कुटुंबातील व्यक्तीचे नंबर असावे याकरिता हि माहिती मागण्यात येत आहे.',
                type: 'text',
            },
        ],
    },
    userById.user_role_id === '4' && {
        inputFields: [
            {
                keyName: 'date_of_birth',
                label: 'Date of Birth - जन्म तारीख',
                type: 'date',
            },
            {
                keyName: 'date_of_joining',
                label: 'Date of Joining - पोलीस दलात सामील होण्याची तारीख',
                type: 'date',
            },
            {
                keyName: 'date_of_retirement',
                label: 'Date of Retirement - रिटायरमेंट तारीख',
                type: 'date',
            },
            {
                keyName: 'aadhar_card_no',
                label: 'Adhar Card Number - आधार कार्ड नंबर',
                type: 'text',
            },
            {
                keyName: 'aadhar_card_no_url',
                label: 'Aadhar Card No. (Upload Document)',
                type: 'file',
                className: 'w-full md:w-8/12',
            },
            {
                keyName: 'pan_no',
                label: 'PAN Card Number ',
                helpText:
                    'कृपया PAN कार्ड नंबर कॅपिटल अक्षरांमध्ये लिहावे. उदाहरण ABCDE1234F',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            {
                keyName: 'pan_no_url',
                label: 'PAN No. (Upload Document)',
                type: 'file',
                className: 'w-full md:w-8/12',
            },
            {
                keyName: 'sevarth_id',
                label: 'Sevarth ID',
                helpText:
                    'कृपया सेवार्थ नंबर कॅपिटल अक्षरांमध्ये लिहावे. उदाहरण DGPCMPM1234',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            {
                keyName: 'mode_of_selection',
                label: 'Mode of Selection',
                type: 'select',
                options: ['Direct', 'Departmental Direct', 'Officating'],
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'confirmation_date',
                label: 'Confirmation Date',
                type: 'date',
            },
            {
                keyName: 'mscit_computer_course',
                label: 'MSCIT Computer Course',
                type: 'select',
                options: ['Yes', 'No', 'Not Applicable'],
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'present_posting_start_date',
                label: 'Present Posting Start Date',
                type: 'date',
            },
            {
                keyName: 'present_district_posting_date',
                label: 'Present District Posting Date',
                type: 'date',
            },
        ],
    },
    userById.user_role_id === '4' && {
        inputFields: [
            {
                keyName: 'home_address',
                label: 'Home Address - घरचा पत्ता',
                type: 'text',
                className: 'w-full md:w-6/12',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'home_pincode',
                label: 'Home Address PIN Code  - घरचा पत्ता पिनकोड',
                type: 'text',
                className: 'w-full md:w-6/12',
            },
            {
                keyName: 'permanent_address',
                label: 'Permanent Address - कायमचा पत्ता',
                type: 'text',
                className: 'w-full md:w-6/12',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'permanent_pincode',
                label: 'Permanent Address PIN Code  - कायमचा पत्ता पिनकोड',
                type: 'text',
                className: 'w-full md:w-6/12',
            },
        ],
    },
    {
        className: 'hidden',
        inputFields: [
            {
                keyName: 'user_role_id',
                label: 'Role',
                disabled: true,
                type: 'select',
                options: userRoles,
                optionMapper: (x) => ({ label: x.name, value: x.id }),
                className: 'hidden',
            },
            {
                keyName: 'is_active',
                label: 'Is Active',
                type: 'switch',
                className: 'hidden',
            },
        ],
    },
    userById.user_role_id === '3' && {
        groupName: 'Additional Information',
        inputFields: [
            {
                keyName: 'station_name',
                label: 'Police Station Name',
                type: 'text',
            },
            {
                keyName: 'station_type',
                label: 'Police Station Type',
                type: 'text',
            },
            {
                keyName: 'station_address',
                label: 'Police Station Address',
                type: 'text',
            },
            {
                keyName: 'station_pincode',
                label: 'Police Station Pin Code',
                type: 'text',
            },
            {
                keyName: ['station_latitude', 'station_longitude'],
                label: ['Station Latitude', 'Station Longitude'],
                type: 'location',
                className: 'w-full',
            },
        ],
    },
    userById.user_role_id === '2' && {
        groupName: 'Additional Information',
        inputFields: [
            { keyName: 'office_name', label: 'Office Name', type: 'text' },
            { keyName: 'address', label: 'Address', type: 'text' },
            { keyName: 'district', label: 'District', type: 'text' },
            { keyName: 'state', label: 'State', type: 'text' },
            { keyName: 'pincode', label: 'Pin Code', type: 'text' },
            {
                keyName: ['latitude', 'longitude'],
                label: ['Latitude', 'Longitude'],
                type: 'location',
                className: 'w-full',
            },
        ],
    },
];
