import { filter, flow, isFunction, map, reduce, size } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { useAppData } from '../../AppProvider';
import useFetchList from '../../hooks/useFetchList';
import CardTable from '../CardTable';
import Spinner from '../Spinner';
import listViewOptions from './listViewOptions';
import { renderSearchInput } from './renderSearchInput';

const getSearchKey = (location) => {
    const search = location.get('search');
    if (!search) {
        return {};
    }
    try {
        console.log(search);
        return JSON.parse(atob(search));
    } catch (error) {
        return {};
    }
};

export const FetchListView = ({
    hideIfNoData,
    columns,
    title,
    apiUrlKey,
    searchFilter,
    validators,
    defaultSearchValue,
    topLevelId,
    user,
    noPagination,
}) => {
    const [location] = useSearchParams();
    const search = getSearchKey(location);
    const titleOverride = search.data?.title;
    const list = useFetchList(apiUrlKey, {
        params: {
            search: search?.condition && btoa(search.condition),
        },
    });
    const { setAppData } = useAppData();
    const [searchValues, setSearchValues] = useState(defaultSearchValue);

    useEffect(() => {
        setSearchValues(defaultSearchValue);
    }, [defaultSearchValue]);

    const filterResult = useCallback(
        (list) =>
            flow(
                map(
                    searchValues,
                    (value, keyName) => (listArr) =>
                        value
                            ? filter(listArr, (l) =>
                                  validators[keyName](l, value),
                              )
                            : listArr,
                ),
            )(list),
        [searchValues, validators],
    );

    useEffect(() => {
        setAppData({
            pageTitle: titleOverride ? titleOverride : title,
        });
        return () => {
            setAppData({
                pageTitle: '',
            });
        };
    }, [setAppData, titleOverride, title]);

    if (list.isLoading || list.isFetching || !list.data) {
        return <Spinner />;
    }

    if (list.error) {
        return <Navigate to="/auth/Login" />;
    }

    const searchFilterArray = isFunction(searchFilter)
        ? searchFilter(search.data, searchValues)
        : searchFilter;

    const visibleData = filterResult(list.data);
    if (!size(visibleData) && hideIfNoData) {
        return null;
    }

    return (
        <div className="flex flex-wrap mt-4">
            <div className="w-full">
                <div className="flex flex-wrap">
                    {map(searchFilterArray, (searchField, index) => (
                        <React.Fragment key={index}>
                            {renderSearchInput(
                                searchField,
                                searchValues,
                                setSearchValues,
                                filterResult(list.data),
                                searchField?.hide?.(user),
                            )}
                        </React.Fragment>
                    ))}
                </div>
                <CardTable
                    columns={columns}
                    data={visibleData}
                    pageTitle={title}
                    topLevelId={topLevelId}
                    userRoleId={user?.user_role_id}
                    noPagination={noPagination}
                />
            </div>
        </div>
    );
};

const ViewList = ({ defaultType, user, userRoles }) => {
    let { type } = useParams();
    let [searchParams] = useSearchParams();
    const search = getSearchKey(searchParams);
    const listViewOption = listViewOptions[type || defaultType];

    const police_division_id = searchParams.get('police_division_id');
    const police_station_id = searchParams.get('police_station_id');

    const searchFilterArray = isFunction(listViewOption.searchFilter)
        ? listViewOption.searchFilter(search.data)
        : listViewOption.searchFilter;

    const defaultSearchValue = useMemo(
        () =>
            reduce(
                filter(searchFilterArray, (x) => !!x.keyName),
                (acc, val) => ({
                    ...acc,
                    [val.keyName]: isFunction(val.defaultValue)
                        ? val.defaultValue({
                              police_division_id,
                              police_station_id,
                          })
                        : val.defaultValue,
                }),
                {},
            ),
        [police_division_id, police_station_id, searchFilterArray],
    );

    console.log(defaultSearchValue);
    if (!listViewOption) {
        return null;
    }

    return (
        <FetchListView
            {...listViewOption}
            defaultSearchValue={defaultSearchValue}
            user={user}
        />
    );
};

export default ViewList;
