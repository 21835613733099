import * as yup from 'yup';

import { DateCell } from './userPostingFormFields';

const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

export const userTrainingSchema = yup.object({
    user_id: yup.string().required(),
    training_type: yup.string().required(),
    training_name: yup.string().required(),
    district_name: yup.string().required(),
    from_Date: yup.string().required(),
    to_Date: yup.string().required(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Training Type',
            accessor: 'training_type',
        },
        {
            Header: 'Training Name',
            accessor: 'training_name',
        },
        {
            Header: 'District Name',
            accessor: 'district_name',
        },
        {
            Header: 'From Date',
            accessor: 'from_Date',
            Cell: DateCell,
        },
        {
            Header: 'To Date',
            accessor: 'to_Date',
            Cell: DateCell,
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user, trainingTypes) => [
    {
        groupName: 'Add Training Details',
        inputFields: [
            {
                keyName: 'training_type',
                label: 'Training Type - ट्रेनिंगचे प्रकार',
                type: 'select',
                options: trainingTypes,
                optionMapper: (x) => ({ label: x.name, value: x.name }),
                className: 'w-full md:w-1/3',
            },
            {
                keyName: 'training_name',
                label: 'Training Name - ट्रेनिंगचे नाव',
                type: 'text',
                className: 'w-full lg:w-1/3',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'district_name',
                label: 'District Name - ठिकाण व जिल्ह्याचे नाव',
                type: 'text',
                className: 'w-full lg:w-1/3',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'from_Date',
                label: 'From Date - या तारखेपासून',
                type: 'date',
                className: 'w-full lg:w-1/2',
            },
            {
                keyName: 'to_Date',
                label: 'Till Date - ते या तारखेपर्यंत',
                type: 'date',
                className: 'w-full lg:w-1/2',
            },
        ],
    },
];
