import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userPunishmentSchema,
} from './inputFields/userPunishmentFormFields';

const UserPunishmentForm = ({
    userId,
    viewOnly,
    updating,
    mutate,
    deletePunishment,
    list,
}) => {
    const [updatedUserPunishment, setUpdatedUserPunishment] = useState({
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedUserPunishment((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userPunishmentSchema.validate(updatedUserPunishment, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserPunishment);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            formTitle="Punishment Details"
            addButtonTxt="Add Punishment"
            data={updatedUserPunishment}
            onChange={handleUpdate}
            inputFields={inputFields({})}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedUserPunishment({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={mutate ? 'Add' : updating ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() =>
                                    deletePunishment(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const UserPunishmentFormCard = ({ viewOnly, componentWrapper }) => {
    let { userId } = useParams();

    const axios = useAxios();

    const policeUserPunishment = useQuery(
        ['UsersPunishment/fetch_user_punishments', userId],
        async () => {
            const { data } = await axios
                .get(`UsersPunishment/fetch_user_punishments?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPolicePunishment = useMutation(async (userPunishmentDetails) => {
        const response = await axios
            .post(
                'UsersPunishment/add_user_punishments',
                JSON.stringify(userPunishmentDetails),
            )
            .then((res) => {
                policeUserPunishment.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePolicePunishment = useMutation(
        async (userPunishmentDetails) => {
            const response = await axios
                .post(
                    'UsersPunishment/delete_user_punishments',
                    JSON.stringify(userPunishmentDetails),
                )
                .then((res) => {
                    policeUserPunishment.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    if (addPolicePunishment.isLoading || deletePolicePunishment.isLoading) {
        return <Spinner />;
    }

    if (addPolicePunishment.error || deletePolicePunishment.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserPunishmentForm
                    userId={userId}
                    viewOnly={viewOnly}
                    mutate={addPolicePunishment.mutate}
                    deletePunishment={deletePolicePunishment.mutate}
                    list={policeUserPunishment.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserPunishment.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserPunishmentFormCard;
