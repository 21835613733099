import Switch from 'react-custom-checkbox/switch';

const checkedTrackStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
};

const checkedIndicatorStyle = {
    background: '#44aa44',
    transform: 'translateX(30px)',
};

const checkedIconStyle = {
    opacity: 1,
    transition: 'all 0.25s ease-in-out',
};

const indicatorStyle = {
    alignItems: 'center',
    background: '#f34334',
    borderRadius: 24,
    bottom: 2,
    display: 'flex',
    height: 24,
    justifyContent: 'center',
    left: 2,
    outline: 'solid 2px transparent',
    position: 'absolute',
    transition: '0.25s',
    width: 24,
};

const trackStyle = {
    background: '#e5efe9',
    border: '1px solid #e6e6e6',
    borderRadius: 15,
    cursor: 'pointer',
    display: 'flex',
    height: 28,
    marginRight: 12,
    position: 'relative',
    width: 60,
};

const InputSwitch = ({
    keyName,
    checked,
    onChange,
    disabled,
    label,
    error,
    hideErrors,
}) => (
    <div className="relative w-full mb-3">
        <label
            className="block text-gray-600 text-xs font-bold mb-2"
            htmlFor={`switch_${keyName}`}
        >
            {label}
        </label>
        <Switch
            id={`switch_${keyName}`}
            disabled={disabled}
            onChange={onChange}
            checked={checked}
            indicatorStyle={indicatorStyle}
            trackStyle={trackStyle}
            checkedIconStyle={checkedIconStyle}
            checkedIndicatorStyle={checkedIndicatorStyle}
            checkedTrackStyle={checkedTrackStyle}
            className="border px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
        />
        {!hideErrors && (
            <p className="text-red-500" style={{ minHeight: 24 }}>
                {error}
            </p>
        )}
    </div>
);

export default InputSwitch;
