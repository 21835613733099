import { includes, toLower } from 'lodash';
import { BsFillEyeFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import authUserStorage from '../../authUser';
import { createCondition } from './forPoliceStation';

const viewList = {
    Header: 'Link',
    accessor: 'id',
    className: 'px-4 w-8 text-center',
    Cell: ({ value, ...props }) => {
        const searchKey = {
            data: {
                title: `By Office Type (${props.row.original.station_type})`,
                station_type: props.row.original.station_type,
                police_division_id: authUserStorage.getUser().id,
            },
            condition: `${createCondition(
                'station_type',
                `'${props.row.original.station_type}'`,
            )} and ${createCondition(
                'police_division_id',
                `'${authUserStorage.getUser().id}'`,
            )}`,
        };
        const encodedSearchKey = encodeURIComponent(
            btoa(JSON.stringify(searchKey)),
        );

        return props.row.original.station_type ? (
            <Link to={`/records/police-stations?search=${encodedSearchKey}`}>
                <BsFillEyeFill className="inline" />
            </Link>
        ) : null;
    },
};

export const officeTypeColumns = [
    {
        Header: '#',
        id: 'index',
        className: 'text-center w-16',
        accessor: (_row, i) => i + 1,
    },
    {
        Header: 'Office Type',
        accessor: 'station_type',
    },
    {
        Header: 'Count',
        accessor: 'COUNT',
        className: 'text-center w-28 px-4',
    },
    viewList,
];

export const validatorsForOfficeType = {
    search_office_type: (list, value) =>
        includes(toLower(list.station_type), toLower(value)),
};

export const searchFilterForOfficeType = [
    {
        type: 'text',
        keyName: 'search_office_type',
        label: 'Office Type',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
];
