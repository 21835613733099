import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { useAxios } from '../../../AxiosProvider';
import UserPostingFormCard from '../../../components/FormCard/UserPostingFormCard';

const PostingForm = () => {
    const { userId } = useParams();
    const navigate = useNavigate();
    const [additionalPostingDetails, onChangeAdditionalPostingDetails] =
        useState({});
    const axios = useAxios();

    useQuery(
        ['Users/fetchLoggedInUser', userId],
        async () => {
            const { data } = await axios
                .get(`Users/fetchLoggedInUser?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            onChangeAdditionalPostingDetails({
                present_posting_start_date:
                    data.data?.present_posting_start_date,
                present_district_posting_date:
                    data.data?.present_district_posting_date,
            });
            return data.data;
        },
        {
            enabled: !!userId,
        },
    );

    const updatePolicePostingDates = useMutation(async (postingDetails) => {
        const response = await axios
            .post(
                'UsersPosting/add_user_posting_dates',
                JSON.stringify(postingDetails),
            )
            .then(() => {
                navigate(`/form/training/${userId}`, { replace: true });
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const onComplete = () => {
        // validate n call api to save data
        updatePolicePostingDates.mutate({
            user_id: userId,
            ...additionalPostingDetails,
        });
    };

    return (
        <div className="mx-auto w-full">
            <h5 className="md:px-10  text-xl font-normal leading-normal my-2 px-6 text-center">
                {
                    'तुम्ही पोलीस दलात सामील झाल्यापासून तुमची पोस्टिंग जिथे जिथे झालेली आहे त्याची माहिती इथे भरणे आहे. जर तुम्हाला लक्षात नसेल तर तुम्ही तुमचे शेवटचे ३ पोस्टिंग बद्दल इथे माहिती भरणे आवश्यक आहे.'
                }
            </h5>
            <hr className="border border-gray-300" />
            <p className="md:px-10 text-sm leading-normal my-2 px-6 text-justify">
                {
                    'नोट : माहिती भरण्यासाठी तुम्हाला ऍड पोस्टिंग च्या बटण वर क्लिक करून तिथे ऑफिस चे नाव, जिल्हा , त्यावेळेस ची तुमची रँक, पोस्टिंग झाल्याची तारीख व ट्रान्सफर ची तारीख अशी माहिती भरून पोस्टिंग ऍड करायची आहे. पोस्टिंग ऍड करीत असताना सगळ्यात जुन्या पोस्टिंग पासून सुरवात करावी आणि तुमची शेवटची पोस्टिंग बद्दल माहिती अखेरीस भरायची आहे. तसेच सध्या ज्या ऑफिस ला पोस्टिंग आहे त्याची तारीख भरण्यासाठी वेगळा फील्ड खाली दिलेला आहे.'
                }
            </p>
            <hr className="border border-gray-300" />
            <UserPostingFormCard
                user={{
                    user_role_id: 3,
                }}
                additionalPostingDetails={additionalPostingDetails}
                onChangeAdditionalPostingDetails={(data) =>
                    onChangeAdditionalPostingDetails({
                        ...additionalPostingDetails,
                        ...data,
                    })
                }
            />
            <div className="w-full px-4 lg:order-3 lg:text-right lg:self-center">
                <div className="py-6 px-3 text-center">
                    <button
                        onClick={onComplete}
                        className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                    >
                        {'Save & Continue'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PostingForm;
