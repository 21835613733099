import { map, range } from 'lodash';
import * as yup from 'yup';

const acrDetails = ['A+', 'A', 'B+', 'B', 'B-'];

const acrYears = map(range(20), (x) => `${2010 + x} - ${2011 + x}`);

export const userAcrDetailsSchema = yup.object({
    user_id: yup.string().required(),
    acr_grade_details: yup.string().required(),
    acr_year: yup.string().required(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'ACR Details Details',
            accessor: 'acr_grade_details',
        },
        {
            Header: 'ACR Details Date',
            accessor: 'acr_year',
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user) => [
    {
        groupName: 'Add ACR Details Details',
        inputFields: [
            {
                keyName: 'acr_grade_details',
                label: 'ACR Details',
                type: 'select',
                className: 'w-full lg:w-1/2',
                options: acrDetails,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'acr_year',
                label: 'ACR Details Date',
                type: 'select',
                className: 'w-full lg:w-1/2',
                options: acrYears,
                optionMapper: (x) => ({ label: x, value: x }),
            },
        ],
    },
];
