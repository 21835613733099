import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostUploadImage = () => {
    const axios = useAxios();
    return useMutation(async (fileBase64) => {
        const { data } = await axios
            .post('ImageUpload/upload_image', JSON.stringify(fileBase64))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data;
    });
};

export default usePostUploadImage;
