import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userDeputationSchema,
} from './inputFields/userDeputationFormFields';
import classNames from 'classnames';

const UserDeputationForm = ({
    userId,
    userById,
    viewOnly,
    mutate,
    deleteDeputation,
    list,
}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [updatedUserDeputation, setUpdatedUserDeputation] = useState({
        deputation_current_office: userById?.police_station_id,
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedUserDeputation((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userDeputationSchema.validate(updatedUserDeputation, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserDeputation);
            setIsOpen(false);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            isOpen={modalIsOpen}
            toggle={setIsOpen}
            formTitle="Deputation Details"
            addButtonTxt="Add Deputation"
            data={updatedUserDeputation}
            onChange={handleUpdate}
            inputFields={inputFields({}, modalIsOpen)}
            onSubmit={submitData}
            onReset={() => {
                setUpdatedUserDeputation({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={'Transfer Officer'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() => {
                                    console.log(props.row.original);
                                    setIsOpen(true);
                                    setUpdatedUserDeputation(
                                        props.row.original,
                                    );
                                }}
                                disabled={
                                    props.row.original.deputation_till_date
                                }
                                className={classNames(
                                    'text-sm border border-black mx-2 px-2 py-1 w-auto font-normal block whitespace-nowrap bg-blue-200 text-gray-700',
                                    {
                                        'cursor-not-allowed':
                                            props.row.original
                                                .deputation_till_date,
                                    },
                                )}
                            >
                                {'Depute Back'}
                            </button>
                            <button
                                onClick={() =>
                                    deleteDeputation(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const UserDeputationFormCard = ({ viewOnly, componentWrapper, userById }) => {
    let { userId } = useParams();

    const axios = useAxios();

    const policeUserDeputation = useQuery(
        ['UsersDeputation/fetch_user_Deputations', userId],
        async () => {
            const { data } = await axios
                .get(`UsersDeputation/fetch_user_Deputations?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPoliceDeputation = useMutation(async (userDeputationDetails) => {
        const response = await axios
            .post(
                'UsersDeputation/add_user_Deputations',
                JSON.stringify(userDeputationDetails),
            )
            .then((res) => {
                policeUserDeputation.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePoliceDeputation = useMutation(
        async (userDeputationDetails) => {
            const response = await axios
                .post(
                    'UsersDeputation/delete_user_Deputations',
                    JSON.stringify(userDeputationDetails),
                )
                .then((res) => {
                    policeUserDeputation.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    if (addPoliceDeputation.isLoading || deletePoliceDeputation.isLoading) {
        return <Spinner />;
    }

    if (addPoliceDeputation.error || deletePoliceDeputation.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserDeputationForm
                    userId={userId}
                    userById={userById}
                    viewOnly={viewOnly}
                    mutate={addPoliceDeputation.mutate}
                    deleteDeputation={deletePoliceDeputation.mutate}
                    list={policeUserDeputation.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserDeputation.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserDeputationFormCard;
