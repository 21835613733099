import React from 'react';
import { Link } from 'react-router-dom';

import { DefaultImage } from './Image';

const UserDetails = () => (
    <>
        <div className="text-gray-500 block">
            <div className="items-center flex">
                <span className="w-12 h-12 text-sm text-white bg-gray-200 inline-flex items-center justify-center rounded-full">
                    <Link to="/profile">
                        <DefaultImage
                            alt="..."
                            className="w-full rounded-full align-middle border-none shadow-lg"
                        />
                    </Link>
                </span>
            </div>
        </div>
    </>
);

export default UserDetails;
