import { useLocation, useRoutes } from 'react-router-dom';

import Footer from '../../components/Footer';
import FormNavbar from '../../components/Navbar/FormNavbar';
import FormView from './FormView';
import HealthForm from './HealthForm';
import PostingForm from './PostingForm';
import TrainingForm from './TrainingForm';
import UserDetailsForm from './UserDetailsForm';

const PublicPage = () => {
    const location = useLocation();
    const publicPageRoutes = useRoutes([
        { path: '/', element: <UserDetailsForm skipGeoLocation /> },
        { path: 'health/:userId', element: <HealthForm /> },
        { path: 'posting/:userId', element: <PostingForm /> },
        { path: 'training/:userId', element: <TrainingForm /> },
        { path: 'view/:userId', element: <FormView /> },
    ]);

    const hideNavbar = location.pathname === '/form/view/12';

    return (
        <>
            <main className="content">
                {!hideNavbar && <FormNavbar />}
                <section className="relative w-full">
                    {publicPageRoutes}
                </section>
            </main>
            <Footer />
        </>
    );
};

export default PublicPage;
