import { BsFilePdfFill } from 'react-icons/bs';

import usePostUploadImage from '../../hooks/usePostUploadImage';

const InputFile = ({
    keyName,
    value,
    onChange,
    disabled,
    label,
    helpText,
    error,
    type = 'text',
    hideErrors,
}) => {
    const uploadFile = usePostUploadImage();

    const handleFileUpload = (event) => {
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = async () => {
            const data = await uploadFile.mutateAsync({
                keyName,
                photo_url: reader.result,
            });
            onChange(data);
        };
        reader.onerror = (error) => {
            console.log('Error: ', error);
        };
    };

    return (
        <div className="relative w-full mb-3">
            <label
                className="block text-gray-600 text-xs font-bold mb-2"
                htmlFor={`text_${keyName}`}
            >
                {label}
            </label>
            <p className="block text-blue-400 text-xs font-bold mb-2">
                {helpText}
            </p>
            <div className="flex items-center">
                <input
                    id={`text_${keyName}`}
                    type={type}
                    className="border px-3 py-3 placeholder-gray-300 text-gray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    onChange={handleFileUpload}
                    disabled={disabled}
                    accept="image/*,application/pdf"
                />
                {value && (
                    <a href={value} target="_blank" rel="noreferrer">
                        {value.indexOf('.pdf') > 0 ? (
                            <BsFilePdfFill className="text-5xl" />
                        ) : (
                            <img className="h-12" alt={keyName} src={value} />
                        )}
                    </a>
                )}
            </div>
            {!hideErrors && (
                <p className="text-red-500" style={{ minHeight: 24 }}>
                    {uploadFile.error || error}
                    {uploadFile.isLoading && !uploadFile.error && (
                        <span className="px-2 text-orange-500">
                            Uploading...
                        </span>
                    )}
                </p>
            )}
        </div>
    );
};

export default InputFile;
