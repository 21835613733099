import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostCreateUser = (options = {}) => {
    const axios = useAxios();
    return useMutation(async (userDetails) => {
        const { data } = await axios
            .post('users/createUser', JSON.stringify(userDetails))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    }, options);
};

export default usePostCreateUser;
