import { map } from 'lodash';
import React from 'react';

import { AsyncSelect } from '../ViewList/renderSearchInput';
import InputCheckbox from './InputCheckbox';
import InputDate from './InputDate';
import InputFile from './InputFile';
import InputMap from './InputMap';
import InputSelect from './InputSelect';
import InputSwitch from './InputSwitch';
import InputText from './InputText';

const renderInput = ({
    inputF,
    viewOnly,
    viewProfile,
    onChange,
    data,
    errors,
}) => {
    if (!inputF) {
        return null;
    }

    const className = inputF.className || 'w-full md:w-4/12';

    if (inputF.render) {
        return (
            <div className={`${className} p-0 md:px-4`}>
                {inputF.render({
                    onChangeOverride: inputF.onChangeOverride,
                    onChange,
                    data,
                    disabled: viewOnly || viewProfile || inputF.disabled,
                    error: errors[inputF.errorKeyName || inputF.keyName],
                })}
            </div>
        );
    }
    if (inputF.type === 'select') {
        return (
            <div className={`${className} p-0 md:px-4`}>
                <InputSelect
                    label={inputF.label}
                    helpText={inputF.helpText}
                    isMultiSelect={inputF.isMultiSelect}
                    disabled={viewOnly || viewProfile || inputF.disabled}
                    options={map(
                        typeof inputF.options === 'function'
                            ? inputF.options(data)
                            : inputF.options,
                        inputF.optionMapper,
                    )}
                    value={data?.[inputF.keyName]}
                    onChange={(e) => onChange({ [inputF.keyName]: e.value })}
                    error={errors[inputF.keyName]}
                />
            </div>
        );
    }
    if (inputF.type === 'asyncSelect') {
        return (
            <div className={`${className} p-0 md:px-4`}>
                <AsyncSelect
                    value={data?.[inputF.keyName]}
                    onChange={(option) =>
                        onChange({
                            [inputF.keyName]: option.value,
                        })
                    }
                    keyName={inputF.keyName}
                    label={inputF.label}
                    fetchOptionsKey={inputF.fetchOptionsKey}
                    optionMapper={inputF.optionMapper}
                    defaultOption={inputF.defaultOption}
                />
            </div>
        );
    }
    if (inputF.type === 'checkbox') {
        return (
            <div className={`${className} p-0 md:px-4`}>
                <InputCheckbox
                    disabled={viewOnly || viewProfile || inputF.disabled}
                    label={inputF.label}
                    onChange={(e) => onChange({ [inputF.keyName]: e })}
                    checked={data?.[inputF.keyName] === '1'}
                    error={errors[inputF.keyName]}
                />
            </div>
        );
    }
    if (inputF.type === 'switch') {
        return (
            <div className={`${className} p-0 md:px-4`}>
                <InputSwitch
                    disabled={viewOnly || viewProfile || inputF.disabled}
                    label={inputF.label}
                    onChange={(e) => onChange({ [inputF.keyName]: e })}
                    checked={data?.[inputF.keyName]}
                    error={errors[inputF.keyName]}
                />
            </div>
        );
    }
    if (inputF.type === 'date') {
        return (
            <div className={`${className} p-0 md:px-4`}>
                <InputDate
                    label={inputF.label}
                    helpText={inputF.helpText}
                    disabled={viewOnly || viewProfile || inputF.disabled}
                    value={data?.[inputF.keyName]}
                    onChange={(e) => onChange({ [inputF.keyName]: e }, true)}
                    error={errors[inputF.keyName]}
                />
            </div>
        );
    }
    if (inputF.type === 'location') {
        return (
            data?.[inputF.keyName[0]] &&
            data?.[inputF.keyName[1]] && (
                <div className={`${className} my-4`}>
                    <InputMap
                        disabled={viewOnly || viewProfile || inputF.disabled}
                        label={inputF.label}
                        onChange={(e) => {
                            onChange(
                                {
                                    [inputF.keyName[0]]: e.latitude,
                                    [inputF.keyName[1]]: e.longitude,
                                },
                                true,
                            );
                        }}
                        latitude={data?.[inputF.keyName[0]]}
                        longitude={data?.[inputF.keyName[1]]}
                    />
                </div>
            )
        );
    }
    if (inputF.type === 'file') {
        return (
            <div className={`${className} p-0 md:px-4`}>
                <InputFile
                    label={inputF.label}
                    helpText={inputF.helpText}
                    keyName={inputF.keyName}
                    disabled={viewOnly || viewProfile || inputF.disabled}
                    type={inputF.type}
                    value={data?.[inputF.keyName] || ''}
                    error={errors[inputF.keyName]}
                    onChange={(value) =>
                        onChange({
                            [inputF.keyName]: inputF.onChangeOverride
                                ? inputF.onChangeOverride(value)
                                : value,
                        })
                    }
                />
            </div>
        );
    }
    return (
        <div className={`${className} p-0 md:px-4`}>
            <InputText
                label={inputF.label}
                helpText={inputF.helpText}
                keyName={inputF.keyName}
                disabled={viewOnly || viewProfile || inputF.disabled}
                type={inputF.type}
                value={data?.[inputF.keyName]}
                error={errors[inputF.keyName]}
                onChange={(e) =>
                    onChange({
                        [inputF.keyName]: inputF.onChangeOverride
                            ? inputF.onChangeOverride(e.target.value)
                            : e.target.value,
                    })
                }
            />
        </div>
    );
};

export default renderInput;
