import * as yup from 'yup';

import { officerRankOptions } from './userFormFields';
import { DateCell } from './userPostingFormFields';

export const userRankJoiningSchema = yup.object({
    user_id: yup.string().required(),
    rank_details: yup.string().required(),
    rank_date: yup.string().required(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Rank Joining Details',
            accessor: 'rank_details',
        },
        {
            Header: 'Rank Joining Date',
            accessor: 'rank_date',
            Cell: DateCell,
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user) => [
    {
        groupName: 'Add Rank Joining Details',
        inputFields: [
            {
                keyName: 'rank_details',
                label: 'Rank Joining Details',
                type: 'select',
                className: 'w-full lg:w-1/2',
                options: officerRankOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'rank_date',
                label: 'Rank Joining Date',
                type: 'date',
                className: 'w-full lg:w-1/2',
            },
        ],
    },
];
