import * as yup from 'yup';
import {
    MobileRegx,
    PincodeRegx,
    casteCategoryOptions,
    genderOptions,
    renderCustomName,
    titleCase,
} from './userFormFields';
import { bloodGroupOptions } from './userHealthFormFields';

export const criminalSchema = (isOpenForm, { user_role_id }) =>
    yup.object({
        name: yup.string().required('Required field'),
        alias_name: yup.string().required('Required field'),
        gender: yup.string().required('Required field'),
        education: yup.string().required('Required field'),
        work_type: yup.string().required('Required field'),
        caste_category: yup.string().required('Required field'),
        caste: yup.string().required('Required field'),
        blood_group: yup.string().required('Required field'),
        date_of_birth: yup.string().required('Required field'),
        mobile_no: yup
            .string()
            .trim()
            .matches(MobileRegx, '10 digit mandatory')
            .required('Required field'),
        alt_mobile_no: yup
            .string()
            .nullable(true)
            .trim()
            .matches(MobileRegx, '10 digit mandatory'),
        wa_mobile_no: yup
            .string()
            .trim()
            .matches(MobileRegx, '10 digit mandatory')
            .required('Required field'),

        current_address: yup.string().required('Required field'),
        current_tahsil: yup.string().required('Required field'),
        current_district: yup.string().required('Required field'),
        current_pincode: yup
            .string()
            .trim()
            .matches(PincodeRegx, 'Invalid Pin code')
            .required('Required field'),
        permanent_address: yup.string().required('Required field'),
        permanent_tahsil: yup.string().required('Required field'),
        permanent_district: yup.string().required('Required field'),
        permanent_pincode: yup
            .string()
            .trim()
            .matches(PincodeRegx, 'Invalid Pin code')
            .required('Required field'),
    });

export const inputFields = () => [
    {
        groupName: 'Personal Details',
        inputFields: [
            {
                keyName: 'name',
                label: 'Name of Person ( in English)',
                render: renderCustomName,
                errorKeyName: 'name',
                onChangeOverride: (val) => titleCase(val),
            },
            { keyName: 'alias_name', label: 'Alias Name', type: 'text' },
            {
                keyName: 'gender',
                label: 'Gender',
                type: 'select',
                options: genderOptions,
                optionMapper: (x) => x,
            },
            {
                keyName: 'education',
                label: 'Education - शिक्षण',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            {
                keyName: 'work_type',
                label: 'Work Type',
                type: 'text',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'caste_category',
                label: 'Caste Category',
                type: 'select',
                options: casteCategoryOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'caste',
                label: 'Caste',
                type: 'text',
                onChangeOverride: (val) => val.toUpperCase(),
            },
            {
                keyName: 'blood_group',
                label: 'Blood Group',
                type: 'select',
                options: bloodGroupOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            { keyName: 'date_of_birth', label: 'Date of Birth', type: 'date' },
            { keyName: 'mobile_no', label: 'Mobile Number', type: 'text' },
            {
                keyName: 'alt_mobile_no',
                label: 'Alternate Mobile Number',
                type: 'text',
            },
            {
                keyName: 'wa_mobile_no',
                label: 'Whatsapp Mobile Number',
                type: 'text',
            },
        ],
    },
    {
        groupName: 'Residential Details',
        inputFields: [
            {
                keyName: 'current_address',
                label: 'Current Address',
                type: 'text',
            },
            { keyName: 'current_tahsil', label: 'Tahsil', type: 'text' },
            { keyName: 'current_district', label: 'District', type: 'text' },
            { keyName: 'current_pincode', label: 'Pin Code', type: 'text' },
            { keyName: 'current_longitude', label: 'Longitude', type: 'text' },
            { keyName: 'current_latitude', label: 'Latitude', type: 'text' },
            {
                keyName: 'permanent_address',
                label: 'Permanent Address',
                type: 'text',
            },
            { keyName: 'permanent_tahsil', label: 'Tahsil', type: 'text' },
            { keyName: 'permanent_district', label: 'District', type: 'text' },
            { keyName: 'permanent_pincode', label: 'Pin Code', type: 'text' },
            {
                keyName: 'permanent_longitude',
                label: 'Longitude',
                type: 'text',
            },
            { keyName: 'permanent_latitude', label: 'Latitude', type: 'text' },
        ],
    },
];
