import classNames from 'classnames';
import React from 'react';
import { useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';

export default function CardTable({
    columns = [],
    data = [],
    tableTitle,
    pageTitle,
    userRoleId,
    noPagination,
}) {
    const visibleColumnsDefault = useMemo(
        () =>
            columns.filter((x) =>
                x.hide
                    ? !x.hide({
                          pageTitle,
                          userRoleId,
                      })
                    : true,
            ),
        [columns, pageTitle, userRoleId],
    );

    const sortByDefault = useMemo(
        () =>
            visibleColumnsDefault
                .filter((x) => x.sortType)
                .map((x) => ({
                    id: x.accessor,
                    desc: false,
                })),
        [visibleColumnsDefault],
    );

    const tableInstance = useTable(
        {
            columns: visibleColumnsDefault,
            data,
            initialState: {
                pageIndex: 0,
                pageSize: noPagination ? 1000 : 10,
                sortBy: sortByDefault,
            },
        },
        useSortBy,
        usePagination,
    );

    const {
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        // below new props related to 'usePagination' hook
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        state: { pageIndex },
    } = tableInstance;

    return (
        <div
            className={
                'relative flex flex-col min-w-0 break-words w-full mb-4 shadow-lg rounded  bg-white'
            }
        >
            {!!tableTitle && (
                <div className="rounded-t mb-0 px-4 py-3 border-0">
                    <div className="flex flex-wrap items-center">
                        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                            <h3
                                className={
                                    'font-semibold text-lg  text-gray-700'
                                }
                            >
                                {tableTitle}
                            </h3>
                        </div>
                    </div>
                </div>
            )}
            <div className="block w-full overflow-x-auto border">
                <section className="container mx-auto font-mono">
                    {!noPagination && (
                        <div className="flex flex-col items-end m-4">
                            <span className="text-sm text-gray-700 dark:text-gray-400">
                                {!!rows.length && (
                                    <>
                                        {'Showing '}
                                        <span className="font-semibold text-gray-900">
                                            {rows.length}
                                        </span>
                                        {' Entries'}
                                    </>
                                )}
                            </span>
                        </div>
                    )}
                    <div className="w-full overflow-hidden">
                        <div className="w-full overflow-x-auto">
                            <table className="w-full">
                                <thead>
                                    {
                                        // Loop over the header rows
                                        headerGroups.map((headerGroup) => (
                                            // Apply the header row props
                                            <tr
                                                {...headerGroup.getHeaderGroupProps()}
                                                className="text-sm font-semibold tracking-wide text-left text-gray-900 bg-gray-100 uppercase border-b border-gray-600"
                                            >
                                                {
                                                    // Loop over the headers in each row
                                                    headerGroup.headers.map(
                                                        (column) => (
                                                            // Apply the header cell props
                                                            <th
                                                                {...column.getHeaderProps()}
                                                                className={classNames(
                                                                    'px-4 py-3',
                                                                    column.className,
                                                                    column.headerClassName,
                                                                )}
                                                            >
                                                                {
                                                                    // Render the header
                                                                    column.render(
                                                                        'Header',
                                                                    )
                                                                }
                                                            </th>
                                                        ),
                                                    )
                                                }
                                            </tr>
                                        ))
                                    }
                                </thead>
                                <tbody
                                    {...getTableBodyProps()}
                                    className="bg-white text-xs"
                                >
                                    {
                                        // Loop over the table rows
                                        page.length > 0 ? (
                                            page.map((row) => {
                                                // Prepare the row for display
                                                prepareRow(row);
                                                return (
                                                    // Apply the row props
                                                    <tr
                                                        {...row.getRowProps()}
                                                        className="text-gray-700"
                                                    >
                                                        {
                                                            // Loop over the rows cells
                                                            row.cells.map(
                                                                (cell) => {
                                                                    // Apply the cell props
                                                                    return (
                                                                        <td
                                                                            {...cell.getCellProps()}
                                                                            className={classNames(
                                                                                'p-2 border',
                                                                                cell
                                                                                    .column
                                                                                    .className,
                                                                            )}
                                                                        >
                                                                            {
                                                                                // Render the cell contents
                                                                                cell.render(
                                                                                    'Cell',
                                                                                    {
                                                                                        userRoleId,
                                                                                    },
                                                                                )
                                                                            }
                                                                        </td>
                                                                    );
                                                                },
                                                            )
                                                        }
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr className="text-gray-700">
                                                <td
                                                    colSpan={columns.length}
                                                    className="p-2 border"
                                                >
                                                    <p className="flex justify-center items-center p-4 text-center">
                                                        {'No Record Found'}
                                                    </p>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {pageOptions.length > 1 && (
                        <div className="flex flex-col items-center m-4">
                            <span className="text-sm text-gray-700 dark:text-gray-400">
                                Page{' '}
                                <span className="font-semibold text-gray-900">
                                    {pageIndex + 1}
                                </span>{' '}
                                of{' '}
                                <span className="font-semibold text-gray-900">
                                    {pageOptions.length}
                                </span>{' '}
                            </span>
                            <div className="inline-flex mt-2 xs:mt-0">
                                <button
                                    onClick={previousPage}
                                    disabled={!canPreviousPage}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 rounded-l hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5 mr-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                    Prev
                                </button>
                                <button
                                    onClick={nextPage}
                                    disabled={!canNextPage}
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-gray-800 border-0 border-l border-gray-700 rounded-r hover:bg-gray-900 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                >
                                    Next
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5 ml-2"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
}

CardTable.defaultProps = {
    color: 'light',
};
