import { AspectRatio } from 'react-aspect-ratio';
import Resizer from 'react-image-file-resizer';
import ReactImageUploading from 'react-images-uploading';

import avatarImg from '../../assets/avatar.jpg';
import Image from '../Image';

const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            'JPEG',
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            'base64',
            200,
            200,
        );
    });

const InputProfile = ({ data, onChange, viewOnly }) => (
    <div className="flex flex-wrap justify-center">
        <div className="w-full px-4 flex justify-center">
            <div className="my-4">
                {!viewOnly ? (
                    <ReactImageUploading
                        value={[data?.photo_url || avatarImg]}
                        onChange={async (e) => {
                            console.log(e);
                            onChange(
                                { photo_url: await resizeFile(e[0].file) },
                                true,
                            );
                        }}
                        acceptType={['jpeg', 'jpg', 'png']}
                    >
                        {({
                            imageList,
                            onImageUpload,
                            isDragging,
                            dragProps,
                            errors,
                        }) => {
                            return (
                                <button onClick={onImageUpload} {...dragProps}>
                                    <div className="relative">
                                        <AspectRatio
                                            ratio="1"
                                            style={{
                                                width: '150px',
                                                position: 'relative',
                                            }}
                                        >
                                            <Image
                                                alt="..."
                                                src={imageList[0]}
                                                className="shadow-xl rounded-full h-auto align-middle border-none max-w-150-px max-h-150-px"
                                                style={{
                                                    ...(data?.photo_url
                                                        ? {}
                                                        : {
                                                              opacity: 0.5,
                                                          }),
                                                    ...(isDragging
                                                        ? {
                                                              opacity: 0.5,
                                                              transition:
                                                                  'opacity 2s linear',
                                                          }
                                                        : {}),
                                                }}
                                            />
                                        </AspectRatio>
                                        {!data?.photo_url && (
                                            <p className="absolute font-bold text-xl flex justify-center items-center inset-0">
                                                {'Upload image here'}
                                            </p>
                                        )}
                                    </div>
                                </button>
                            );
                        }}
                    </ReactImageUploading>
                ) : (
                    <>
                        <Image
                            src={data?.photo_url}
                            alt="..."
                            className="shadow-xl rounded-full h-auto align-middle border-none max-w-150-px"
                        />
                    </>
                )}
            </div>
        </div>
    </div>
);

export default InputProfile;
