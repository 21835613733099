import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userAcrDetailsSchema,
} from './inputFields/userAcrDetailsFormFields';

const UserAcrDetailsForm = ({
    userId,
    viewOnly,
    updating,
    mutate,
    deleteAcrDetails,
    list,
}) => {
    const [updatedUserAcrDetails, setUpdatedUserAcrDetails] = useState({
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedUserAcrDetails((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userAcrDetailsSchema.validate(updatedUserAcrDetails, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserAcrDetails);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            formTitle="ACR Details"
            addButtonTxt="Add ACR Details"
            data={updatedUserAcrDetails}
            onChange={handleUpdate}
            inputFields={inputFields({})}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedUserAcrDetails({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={mutate ? 'Add' : updating ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() =>
                                    deleteAcrDetails(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const UserAcrDetailsFormCard = ({ viewOnly, componentWrapper }) => {
    let { userId } = useParams();

    const axios = useAxios();

    const policeUserAcrDetails = useQuery(
        ['UsersAcrDetails/fetch_user_acr_details', userId],
        async () => {
            const { data } = await axios
                .get(`UsersAcrDetails/fetch_user_acr_details?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPoliceAcrDetails = useMutation(async (userAcrDetailsDetails) => {
        const response = await axios
            .post(
                'UsersAcrDetails/add_user_acr_details',
                JSON.stringify(userAcrDetailsDetails),
            )
            .then((res) => {
                policeUserAcrDetails.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePoliceAcrDetails = useMutation(
        async (userAcrDetailsDetails) => {
            const response = await axios
                .post(
                    'UsersAcrDetails/delete_user_acr_details',
                    JSON.stringify(userAcrDetailsDetails),
                )
                .then((res) => {
                    policeUserAcrDetails.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    if (addPoliceAcrDetails.isLoading || deletePoliceAcrDetails.isLoading) {
        return <Spinner />;
    }

    if (addPoliceAcrDetails.error || deletePoliceAcrDetails.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserAcrDetailsForm
                    userId={userId}
                    viewOnly={viewOnly}
                    mutate={addPoliceAcrDetails.mutate}
                    deleteAcrDetails={deletePoliceAcrDetails.mutate}
                    list={policeUserAcrDetails.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserAcrDetails.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserAcrDetailsFormCard;
