import html2pdf from 'html2pdf.js';
import { isEmpty, map, omitBy, pick } from 'lodash';
import moment from 'moment';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { BsPinMapFill } from 'react-icons/bs';
import { BsFillEnvelopeFill } from 'react-icons/bs';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { useAxios } from '../../../AxiosProvider';
import avatarImg from '../../../assets/avatar.jpg';
import UserAcrDetailsFormCard from '../../../components/FormCard/UserAcrDetailsFormCard';
import UserDeputationFormCard from '../../../components/FormCard/UserDeputationFormCard';
import UserPostingFormCard from '../../../components/FormCard/UserPostingFormCard';
import UserPunishmentFormCard from '../../../components/FormCard/UserPunishmentFormCard';
import UserRankJoiningFormCard from '../../../components/FormCard/UserRankJoiningFormCard';
import UserRewardFormCard from '../../../components/FormCard/UserRewardFormCard';
import UserSportFormCard from '../../../components/FormCard/UserSportFormCard';
import UserSuspensionFormCard from '../../../components/FormCard/UserSuspensionFormCard';
import UserTrainingFormCard from '../../../components/FormCard/UserTrainingFormCard';
import { inputFields } from '../../../components/FormCard/inputFields/userHealthFormFields';
import { dateFormatter } from '../../../components/FormCard/inputFields/userPostingFormFields';
import Image from '../../../components/Image';
import FormNavbar from '../../../components/Navbar/FormNavbar';

const additionalSection = [
    {
        title: 'Posting / Transfer Details',
        Component: UserPostingFormCard,
    },
    {
        title: 'Deputation Details',
        Component: UserDeputationFormCard,
    },
    {
        title: 'Training Details',
        Component: UserTrainingFormCard,
    },
    {
        title: 'Sport Details',
        Component: UserSportFormCard,
    },
    {
        title: 'Punishment Details',
        Component: UserPunishmentFormCard,
    },
    {
        title: 'Reward Details',
        Component: UserRewardFormCard,
    },
    {
        title: 'Suspension Details',
        Component: UserSuspensionFormCard,
    },
    {
        title: 'ACR Details',
        Component: UserAcrDetailsFormCard,
    },
    {
        title: 'Rank Joining Details',
        Component: UserRankJoiningFormCard,
    },
];

const diffBetweenDate = (date1, date2) => {
    const momentDate1 = moment(new Date(date1));
    const momentDate2 = moment(new Date(date2));
    if (momentDate1.isValid() && momentDate2.isValid()) {
        const years = momentDate2.diff(momentDate1, 'years', false);
        momentDate1.add(years, 'years');
        const months = momentDate2.diff(momentDate1, 'months', false);
        return `${years} Years ${months} Months`;
    }

    return null;
};

const FormView = () => {
    const { userId } = useParams();
    const axios = useAxios();

    const policeUser = useQuery(
        ['Users/fetchLoggedInUser', userId],
        async () => {
            const { data } = await axios
                .get(`Users/fetchLoggedInUser?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const policeStation = useQuery(
        ['Users/fetchLoggedInUser', policeUser.data?.police_station_id ?? ''],
        async () => {
            const { data } = await axios
                .get(
                    `Users/fetchLoggedInUser?user_id=${
                        policeUser.data?.police_station_id ?? ''
                    }`,
                )
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
        {
            skip: !!policeUser.data?.police_station_id ?? '',
        },
    );

    const healthData = omitBy(
        pick(policeUser.data, [
            'blood_pressure',
            'diabetes',
            'depression',
            'tuberculosis',
            'asthama',
            'mental_disorder',
            'std_hiv',
            'jaundice',
            'epilepsy',
            'heart_disease',
            'sickle_cell',
            'cancer',
            'vision_disorders',
        ]),
        (x) => x !== '1',
    );

    const medicalData = omitBy(
        pick(policeUser.data, [
            'head_injury',
            'leg_fracture',
            'hand_fracture',
            'other_fracture',
            'covid19',
            'hernia',
            'hydrocele',
            'appendix',
        ]),
        (x) => x !== '1',
    );

    // Convert HTML content to PDF
    function convert_HTML_To_PDF() {
        var element = document.getElementById('element-to-print');
        html2pdf(element, {
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
            jsPDF: {
                unit: 'px',
                format: 'a4',
                hotfixes: ['px_scaling'],
                orientation: 'portrait',
                floatPrecision: 'smart',
            },
        });
    }

    return (
        <>
            <div
                id="element-to-print"
                className=" border border-2 border-police-blue"
            >
                <div className="flex">
                    <div
                        className="text-center bg-yellow-400 text-police-blue font-bold p-2 text-2xl lg:text-4xl flex justify-center items-center"
                        style={{ flex: 3 }}
                    >
                        {'चंद्रपूर जिल्हा पोलीस'}
                    </div>
                    <FormNavbar />
                </div>
                <div className="flex">
                    <div
                        className="bg-police-blue p-2 flex flex-col items-center"
                        style={{ flex: 3 }}
                    >
                        <div className="bg-white w-40 h-40 rounded-full">
                            <Image
                                className="w-40 h-40 rounded-full"
                                src={policeUser.data?.photo_url ?? avatarImg}
                                alt="user"
                            />
                        </div>
                        <div className="bg-white rounded-2xl mt-8 w-full flex-1">
                            <p className="font-bold bg-yellow-400 rounded-2xl px-2 py-1 text-center text-lg">
                                {`${policeUser.data?.name_suffix ?? ''} ${
                                    policeUser.data?.name ?? ''
                                }`}
                            </p>
                            <table className="w-full mt-2">
                                <tr>
                                    <td className="px-2">
                                        <BsFillTelephoneFill className="inline" />
                                    </td>
                                    <td>
                                        <p>
                                            {policeUser.data?.mobile_no ?? ''}
                                        </p>
                                        <p>
                                            {policeUser.data?.alt_mobile_no ??
                                                ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2"></td>
                                </tr>
                                <tr>
                                    <td className="px-2">
                                        <BsFillEnvelopeFill className="inline" />
                                    </td>
                                    <td>
                                        <p>
                                            {policeUser.data?.email_address ??
                                                ''}
                                        </p>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="px-2 py-2"></td>
                                </tr>
                                <tr>
                                    <td className="px-2">
                                        <BsPinMapFill className="inline" />
                                    </td>
                                    <td>
                                        <p>
                                            {policeUser.data?.home_address ??
                                                ''}
                                        </p>
                                        <p>
                                            {policeUser.data?.home_pincode ??
                                                ''}
                                        </p>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div
                        className="bg-yellow-400 text-police-blue font-bold flex flex-col justify-center items-center"
                        style={{ flex: 7 }}
                    >
                        <div className="text-4xl text-center font-bold flex justify-center items-center w-full">
                            {`${policeUser.data?.name_suffix ?? ''} ${
                                policeUser.data?.name ?? ''
                            }`}
                        </div>
                        <div className="p-3 flex w-full">
                            <table className="flex-1 px-3 border-r-2 border-black mx-2 after-colon">
                                {map(
                                    [
                                        {
                                            title: 'Rank',
                                            value:
                                                policeUser.data?.officer_post ??
                                                '',
                                        },
                                        {
                                            title: 'Bukkle No.',
                                            value:
                                                policeUser.data?.buckle_no ??
                                                '',
                                        },
                                        {
                                            title: 'Work Type',
                                            value:
                                                policeUser.data?.work_type ??
                                                '',
                                        },
                                    ],
                                    ({ title, value }) =>
                                        value ? (
                                            <tr>
                                                <td className="align-baseline text-black after-colon">
                                                    {title}
                                                </td>
                                                <td className="align-baseline text-black font-normal">
                                                    {value}
                                                </td>
                                            </tr>
                                        ) : null,
                                )}
                            </table>
                            <table className="flex-1 px-3 mx-2">
                                {map(
                                    [
                                        {
                                            title: 'Posted at',
                                            value:
                                                policeStation.data
                                                    ?.station_name ?? '',
                                        },
                                        {
                                            title: 'Address',
                                            value: `${
                                                policeStation.data
                                                    ?.station_address ?? ''
                                            } ${
                                                policeStation.data
                                                    ?.station_pincode ?? ''
                                            }`,
                                        },
                                    ],
                                    ({ title, value }) =>
                                        value ? (
                                            <tr>
                                                <td className="align-baseline text-black font-bold w-28 after-colon">
                                                    {title}
                                                </td>
                                                <td className="align-baseline text-black font-normal ">
                                                    {value}
                                                </td>
                                            </tr>
                                        ) : null,
                                )}
                            </table>
                        </div>
                        <div className="pb-2 w-full bg-police-blue text-yellow-400 font-bold flex">
                            <div className="flex-1 p-2 border-l-2 border-yellow-400">
                                <table className="w-full">
                                    {map(
                                        [
                                            {
                                                title: 'Family No ',
                                                value:
                                                    policeUser.data
                                                        ?.family_no ?? '',
                                            },
                                            {
                                                title: 'Education',
                                                value:
                                                    policeUser.data
                                                        ?.education ?? '',
                                            },
                                            {
                                                title: 'Category',
                                                value:
                                                    policeUser.data
                                                        ?.caste_category ?? '',
                                            },
                                            {
                                                title: 'Caste',
                                                value:
                                                    policeUser.data?.caste ??
                                                    '',
                                            },
                                            {
                                                title: 'Caste Validity',
                                                value:
                                                    policeUser.data
                                                        ?.caste_validity ?? '',
                                            },
                                            {
                                                title: 'Blood Group',
                                                value:
                                                    policeUser.data
                                                        ?.blood_group ?? '',
                                            },
                                            {
                                                title: 'Age',
                                                value:
                                                    policeUser.data?.age ?? '',
                                            },
                                            {
                                                title: 'Height (cm) ',
                                                value:
                                                    policeUser.data?.height ??
                                                    '',
                                            },
                                            {
                                                title: 'Weight (Kg)',
                                                value:
                                                    policeUser.data?.weight ??
                                                    '',
                                            },
                                            {
                                                title: 'BMI',
                                                value:
                                                    policeUser.data?.bmi ?? '',
                                            },
                                        ],
                                        ({ title, value }) =>
                                            value ? (
                                                <tr>
                                                    <td className="align-baseline text-yellow-400 after-colon">
                                                        {title}
                                                    </td>
                                                    <td className="align-baseline text-white font-normal ">
                                                        {value}
                                                    </td>
                                                </tr>
                                            ) : null,
                                    )}
                                </table>
                            </div>

                            <div className="flex-1 -ml-4 p-2 border-l-2 border-yellow-400">
                                <table className="w-full">
                                    <tr>
                                        <td className="align-baseline text-yellow-400 font-bold after-colon">
                                            {'Date of Birth  '}
                                        </td>
                                        <td className="align-baseline text-white font-normal">
                                            {dateFormatter(
                                                policeUser.data?.date_of_birth,
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            className="text-white text-2xl font-bold"
                                        >
                                            {diffBetweenDate(
                                                new Date(
                                                    policeUser.data
                                                        ?.date_of_birth ?? '',
                                                ),
                                                new Date(),
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="border-b-2 border-white">
                                        <td
                                            colSpan={2}
                                            className="text-yellow-400 text-right font-normal pb-2"
                                        >
                                            {'of Age'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-baseline text-yellow-400 font-bold after-colon">
                                            {'Date of Joining '}
                                        </td>
                                        <td className="align-baseline text-white font-normal">
                                            {dateFormatter(
                                                policeUser.data
                                                    ?.date_of_joining,
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            className="text-white text-2xl font-bold"
                                        >
                                            {diffBetweenDate(
                                                new Date(
                                                    policeUser.data
                                                        ?.date_of_joining ?? '',
                                                ),
                                                new Date(),
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="border-b-2 border-white">
                                        <td
                                            colSpan={2}
                                            className="text-yellow-400 text-right font-normal pb-2"
                                        >
                                            {'of Service'}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="align-baseline text-yellow-400 font-bold after-colon">
                                            {'Date of Retirement'}
                                        </td>
                                        <td className="align-baseline text-white font-normal">
                                            {dateFormatter(
                                                policeUser.data
                                                    ?.date_of_retirement,
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            className="text-white text-2xl font-bold"
                                        >
                                            {diffBetweenDate(
                                                new Date(),
                                                new Date(
                                                    policeUser.data
                                                        ?.date_of_retirement ??
                                                        '',
                                                ),
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td
                                            colSpan={2}
                                            className="text-yellow-400 text-right font-normal pb-2"
                                        >
                                            {'Service Remaining'}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-white text-black flex">
                    <div className="flex-1 text-center font-bold p-2 border-r-2 border-police-blue">
                        Aadhar Card
                        <p className="text-2xl text-police-blue font-normal">
                            {policeUser.data?.aadhar_card_no ?? ''}
                        </p>
                    </div>
                    <div className="flex-1 text-center font-bold p-2 border-r-2 border-police-blue">
                        PAN Card
                        <p className="text-2xl text-police-blue font-normal">
                            {policeUser.data?.pan_no ?? ''}
                        </p>
                    </div>
                    <div className="flex-1 text-center font-bold p-2">
                        Sevarth ID
                        <p className="text-2xl text-police-blue font-normal">
                            {policeUser.data?.sevarth_id ?? ''}
                        </p>
                    </div>
                </div>
                <div className="w-full bg-police-blue text-yellow-400 font-bold flex">
                    <div className="flex-1 text-2xl text-white text-center p-2 border-r-2 border-yellow-400">
                        Medical History
                    </div>
                    <div className="flex-1 text-2xl text-white text-center p-2">
                        Surgical History
                    </div>
                </div>
                <div className="w-full border-t-2 border-yellow-400 bg-white text-black flex">
                    <div className="flex-1 p-2 border-r-2 border-police-blue">
                        {!isEmpty(healthData) ||
                        policeUser.data?.medical_history_other ? (
                            <ul className="list-disc px-8">
                                {map(
                                    healthData,
                                    (val, key) =>
                                        val && (
                                            <li key={key}>
                                                {inputFields(
                                                    policeUser.data,
                                                )[0].inputFields.find(
                                                    (x) => x.keyName === key,
                                                )?.label ?? ''}
                                            </li>
                                        ),
                                )}
                                {policeUser.data?.medical_history_other && (
                                    <li>
                                        {inputFields(
                                            policeUser.data,
                                        )[0].inputFields.find(
                                            (x) =>
                                                x.keyName ===
                                                'medical_history_other',
                                        )?.label ?? ''}{' '}
                                        :
                                        {policeUser.data
                                            ?.medical_history_other ?? ''}
                                    </li>
                                )}
                            </ul>
                        ) : (
                            <p className="font-mono text-xs flex justify-center items-center p-4 text-center">
                                {'No Record Found'}
                            </p>
                        )}
                    </div>
                    <div className="flex-1 p-2">
                        {!isEmpty(medicalData) ||
                        policeUser.data?.surgical_history_any_other ? (
                            <ul className="list-disc px-8">
                                {map(
                                    medicalData,
                                    (val, key) =>
                                        val && (
                                            <li>
                                                {inputFields(
                                                    policeUser.data,
                                                )[1].inputFields.find(
                                                    (x) => x.keyName === key,
                                                )?.label ?? ''}
                                            </li>
                                        ),
                                )}
                                {policeUser.data
                                    ?.surgical_history_any_other && (
                                    <li>
                                        {inputFields(
                                            policeUser.data,
                                        )[1].inputFields.find(
                                            (x) =>
                                                x.keyName ===
                                                'surgical_history_any_other',
                                        )?.label ?? ''}{' '}
                                        :
                                        {policeUser.data
                                            ?.surgical_history_any_other ?? ''}
                                    </li>
                                )}
                            </ul>
                        ) : (
                            <p className="font-mono text-xs flex justify-center items-center p-4 text-center">
                                {'No Record Found'}
                            </p>
                        )}
                    </div>
                </div>
                {map(additionalSection, ({ title, Component }) => {
                    return (
                        <Component
                            viewOnly
                            componentWrapper={(renderComp) => (
                                <div className="no-print-break-inside ">
                                    <div className="w-full bg-police-blue text-yellow-400 flex">
                                        <div className="flex-1 text-2xl text-white text-center p-2">
                                            {title}
                                        </div>
                                    </div>
                                    <div className="w-full bg-white text-black flex">
                                        {renderComp}
                                    </div>
                                </div>
                            )}
                        />
                    );
                })}
            </div>
            <div className="flex justify-center items-center p-4 no-print">
                <button
                    onClick={() => window.print()}
                    className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                >
                    {'Print'}
                </button>
                <button
                    onClick={convert_HTML_To_PDF}
                    className="hidden bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                >
                    {'Download'}
                </button>
            </div>
        </>
    );
};

export default FormView;
