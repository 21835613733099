import { isEmpty, map, reduce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import renderInput from '../Form/renderInput';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userPostingSchema,
} from './inputFields/userPostingFormFields';

const UserPostingForm = ({
    userId,
    viewOnly,
    mutate,
    deletePosting,
    list,
    additionalPostingDetails,
    onChangeAdditionalPostingDetails,
}) => {
    const [updatedUserPosting, setUpdatedUserPosting] = useState({
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        let bmi = 0;
        if (updatedUserPosting.weight && updatedUserPosting.height) {
            bmi = (
                updatedUserPosting.weight /
                Math.pow(updatedUserPosting.height / 100, 2)
            ).toFixed(1);
        }
        setUpdatedUserPosting((x) => ({ ...x, bmi }));
    }, [updatedUserPosting.weight, updatedUserPosting.height]);

    const handleUpdate = (value) => {
        setUpdatedUserPosting((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userPostingSchema.validate(updatedUserPosting, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserPosting);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            addButtonTxt="Add Posting"
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() =>
                                    deletePosting(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
            data={updatedUserPosting}
            errors={errors}
            formTitle="Posting Details"
            renderHeader={
                onChangeAdditionalPostingDetails &&
                (() => (
                    <form className="w-full px-4 pb-4 mt-16 md:m-auto">
                        {map(
                            [
                                {
                                    inputFields: [
                                        {
                                            keyName:
                                                'present_posting_start_date',
                                            label: 'Present Posting Start Date - सध्याच्या कार्यालयातील पोस्टिंगची तारीख',
                                            type: 'date',
                                            className: 'w-full lg:w-1/2',
                                        },
                                        {
                                            keyName:
                                                'present_district_posting_date',
                                            label: 'Present District Posting Date - सध्याच्या जिल्ह्यातील पोस्टिंगची तारीख',
                                            helpText:
                                                'जर तुम्ही याच जिल्ह्यात पोलिस दलात सामील झाले आहे तर जॉइनिंग तारीख भरावी',
                                            type: 'date',
                                            className: 'w-full lg:w-1/2',
                                        },
                                    ],
                                },
                            ],
                            (inputField, inputFieldIndex) =>
                                inputField && (
                                    <div
                                        key={inputFieldIndex}
                                        className={inputField.className}
                                    >
                                        <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold">
                                            {inputField.groupName}
                                            <p className="block text-blue-400 text-xs font-bold mb-2">
                                                {inputField.helpText}
                                            </p>
                                        </h6>
                                        <div className="flex flex-wrap items-end">
                                            {map(
                                                inputField.inputFields,
                                                (inputF, index) => (
                                                    <React.Fragment key={index}>
                                                        {renderInput({
                                                            inputF,
                                                            viewOnly,
                                                            onChange:
                                                                onChangeAdditionalPostingDetails,
                                                            data: additionalPostingDetails,
                                                            errors,
                                                        })}
                                                    </React.Fragment>
                                                ),
                                            )}
                                        </div>
                                        <hr className="w-full mt-6 mb-6 border-b-1 border-gray-300" />
                                    </div>
                                ),
                        )}
                    </form>
                ))
            }
            inputFields={inputFields({}, updatedUserPosting)}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            onChange={handleUpdate}
            onReset={() => {
                setUpdatedUserPosting({});
                setErrors({});
            }}
            onSubmit={submitData}
            resetLabel={'Reset'}
            showReset={!!mutate}
            submitLabel={'Add'}
            viewOnly={viewOnly}
        />
    );
};

const UserPostingFormCard = ({
    viewOnly,
    additionalPostingDetails,
    onChangeAdditionalPostingDetails,
    componentWrapper,
}) => {
    let { userId } = useParams();
    const axios = useAxios();

    const policeUserPosting = useQuery(
        ['UsersPosting/fetch_user_posting', userId],
        async () => {
            const { data } = await axios
                .get(`UsersPosting/fetch_user_posting?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPolicePosting = useMutation(async (postingDetails) => {
        const response = await axios
            .post(
                'UsersPosting/add_user_posting',
                JSON.stringify(postingDetails),
            )
            .then((res) => {
                policeUserPosting.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePolicePosting = useMutation(async (postingDetails) => {
        const response = await axios
            .post(
                'UsersPosting/delete_user_posting',
                JSON.stringify(postingDetails),
            )
            .then((res) => {
                policeUserPosting.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    if (addPolicePosting.isLoading || policeUserPosting.isLoading) {
        return <Spinner />;
    }

    if (
        addPolicePosting.error ||
        policeUserPosting.error ||
        deletePolicePosting.error
    ) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserPostingForm
                    userId={userId}
                    viewOnly={viewOnly}
                    mutate={addPolicePosting.mutate}
                    deletePosting={deletePolicePosting.mutate}
                    list={policeUserPosting.data}
                    additionalPostingDetails={additionalPostingDetails}
                    onChangeAdditionalPostingDetails={
                        onChangeAdditionalPostingDetails
                    }
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserPosting.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserPostingFormCard;
