import avatarImg from '../../assets/fav2.png';
import { MAHARASHTRA_POLICE_MR } from '../../constants';

const FormNavbar = () => (
    <center
        className="bg-police-blue text-yellow-400 font-bold flex justify-between items-center text-2xl lg:text-4xl"
        style={{ flex: 7 }}
    >
        <img className="w-32 lg:w-40" src={avatarImg} alt="police-logo"></img>
        <span>{MAHARASHTRA_POLICE_MR}</span>
        <img className="w-32 lg:w-40" src={avatarImg} alt="police-logo"></img>
    </center>
);

export default FormNavbar;
