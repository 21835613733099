import { find, isFunction } from 'lodash';
import React from 'react';
import { BsList } from 'react-icons/bs';
import { Link } from 'react-router-dom';

import listViewOptions from './ViewList/listViewOptions';

const CardStats = ({ statSubtitle, statTitle, statIconColor, statUserId }) => (
    <>
        <div
            className={`${statIconColor} relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg`}
        >
            <div className="flex-auto p-4">
                <div className="flex flex-wrap">
                    <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                        <h5 className="text-white font-bold text-xs">
                            {statSubtitle}
                        </h5>
                        <span className="font-semibold text-xl text-gray-700">
                            {statTitle}
                        </span>
                    </div>
                    <hr className="w-full mt-2 mb-2 border-b-1 border-gray-300" />
                    <Link
                        to={`records/${(() => {
                            const pathName = find(
                                listViewOptions,
                                (l) => l.title === statSubtitle,
                            )?.pathName;

                            return isFunction(pathName)
                                ? pathName(statUserId)
                                : pathName;
                        })()}`}
                        className="w-full"
                    >
                        <p className="w-full text-sm text-center text-white">
                            <span className="whitespace-nowrap">
                                {'View List'}
                            </span>
                            <span className={'ml-2'}>
                                <BsList className="inline" />
                            </span>
                        </p>
                    </Link>
                </div>
            </div>
        </div>
    </>
);

export default CardStats;
