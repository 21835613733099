import React from 'react';
import 'react-aspect-ratio/aspect-ratio.css';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import AppProvider from './AppProvider';
import AxiosProvider from './AxiosProvider';
import './index.css';
import { Router } from './router';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

Modal.setAppElement('#root');
ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <BrowserRouter>
            <AxiosProvider>
                <AppProvider>
                    <QueryClientProvider client={queryClient}>
                        <Router />
                    </QueryClientProvider>
                </AppProvider>
            </AxiosProvider>
        </BrowserRouter>
    </React.StrictMode>,
);
