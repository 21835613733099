import Checkbox from 'react-custom-checkbox';
import { FiCheck } from 'react-icons/fi';

const InputCheckbox = ({
    keyName,
    checked,
    onChange,
    disabled,
    label,
    error,
    hideErrors,
}) => (
    <div className="relative w-full mb-3">
        <Checkbox
            id={`checkbox_${keyName}`}
            disabled={disabled}
            checked={checked}
            icon={<FiCheck color="#174A41" size={24} />}
            name={`checkbox_${keyName}`}
            onChange={(value) => {
                return onChange(value);
            }}
            borderColor="rgb(156 163 175)"
            style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
            labelStyle={{
                fontSize: '1rem',
                lineHeight: '1rem',
                marginLeft: '0.5rem',
                userSelect: 'none',
            }}
            label={label}
        />
        {!hideErrors && (
            <p className="text-red-500" style={{ minHeight: 24 }}>
                {error}
            </p>
        )}
    </div>
);

export default InputCheckbox;
