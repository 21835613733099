import React from 'react';

import AppName from '../AppName';

const AuthNavbar = () => (
    <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
            <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                <AppName className="text-white mr-4 py-2" />
            </div>
        </div>
    </nav>
);

export default AuthNavbar;
