import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostCreateCriminal = (options = {}) => {
    const axios = useAxios();
    return useMutation(async (criminalDetails) => {
        const { data } = await axios
            .post('criminals/add_criminal', JSON.stringify(criminalDetails))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data;
    }, options);
};

export default usePostCreateCriminal;
