import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchDashboardDetails = () => {
    const axios = useAxios();
    return useQuery('Dashboard/fetchDashboardDetails', async () => {
        const { data } = await axios
            .get('Dashboard/fetchDashboardDetails')
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.data;
    });
};

export default useFetchDashboardDetails;
