import React, { useEffect, useRef, useState } from 'react';
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    ZoomControl,
    useMapEvents,
} from 'react-leaflet';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import 'react-leaflet-fullscreen/styles.css';

const CustomMarker = React.memo(({ data, isActive }) => {
    const leafletRef = useRef();

    useEffect(() => {
        if (isActive) {
            leafletRef.current.openPopup();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Marker ref={leafletRef} position={[+data.lat, +data.lng]}>
            {!!data.popup_text && (
                <Popup
                    closeOnClick={false}
                    closeOnEscapeKey={false}
                    autoClose={false}
                >
                    {data.popup_text}
                </Popup>
            )}
        </Marker>
    );
});

function LocationMarker() {
    const [position, setPosition] = useState(null);
    const map = useMapEvents({
        // click() {
        //   map.locate();
        // },
        locationfound(e) {
            setPosition(e.latlng);
            map.flyTo(e.latlng, map.getZoom());
        },
    });

    useEffect(() => {
        map.locate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return position === null ? null : (
        <Marker position={position}>
            <Popup>You are here</Popup>
        </Marker>
    );
}

const IconOnMap = ({ data }) =>
    data.map((data, index) => <CustomMarker data={data} key={index} />);

const MapWithMarker = ({ data, defaultMapCenter }) => (
    <div style={{ width: '100%', height: '50vh', zIndex: 99999 }}>
        <MapContainer
            center={defaultMapCenter}
            zoom={8}
            maxZoom={30}
            className="map-container shadow w-full h-full"
            zoomControl={null}
        >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <IconOnMap data={data} />
            <FullscreenControl position="bottomright" />
            <LocationMarker />
            <ZoomControl position="bottomright" />
        </MapContainer>
    </div>
);

export default MapWithMarker;
