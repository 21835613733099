import { includes, pick, toLower } from 'lodash';
import { exportToExcel } from 'react-json-to-excel';
import { Link } from 'react-router-dom';

import Image from '../Image';
import TableDropdown from '../TableDropdown';

export const createCondition = (keyName, value) => {
    if (!value) {
        return `${keyName} is null`;
    }

    return `${keyName} = ${value}`;
};

export const policeStationColumns = [
    {
        Header: '#',
        id: 'index',
        className: 'text-center w-16',
        accessor: (_row, i) => i + 1,
    },
    {
        Header: 'Name',
        accessor: 'station_name',
        Cell: (props) => {
            const { photo_url, station_name, user_id, police_division_id } =
                props.row.original;
            const searchKey = {
                data: {
                    police_division_id,
                    police_station_id: user_id,
                },
                condition: user_id
                    ? `${createCondition(
                          'police_division_id',
                          police_division_id,
                      )} and ${createCondition('police_station_id', user_id)}`
                    : `${createCondition(
                          'police_division_id',
                          police_division_id,
                      )}`,
            };
            const encodedSearchKey = encodeURIComponent(
                btoa(JSON.stringify(searchKey)),
            );

            return (
                <Link to={`/records/police?search=${encodedSearchKey}`}>
                    <div className="text-left flex items-center w-full">
                        <Image
                            src={photo_url}
                            className="h-8 w-8 bg-white rounded-full border"
                            alt="..."
                        />
                        <span className={'ml-3 font-bold text-gray-600'}>
                            {station_name}
                        </span>
                    </div>
                </Link>
            );
        },
    },
    {
        Header: 'Office Type',
        accessor: 'station_type',
    },
    {
        Header: 'Office Address',
        accessor: 'station_address',
    },
    {
        Header: 'Total Officers',
        accessor: 'total_officers',
    },
    {
        Header: 'Actions',
        accessor: 'id',
        className: 'text-center',
        Cell: (props) => <TableDropdown user={props.row.original} />,
    },
];

export const validatorsForPoliceStation = {
    search_text: (list, value) =>
        includes(toLower(list.name), toLower(value)) ||
        includes(toLower(list.buckle_no), toLower(value)),
    search_top_level: (list, value) =>
        includes(toLower(list.police_division_id), toLower(value)),
    search_ignore_other_district: (list, value) => list.id !== value,
};

export const searchFilterForPoliceStation = [
    {
        type: 'text',
        keyName: 'search_text',
        label: 'Name',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
    {
        type: 'text',
        keyName: 'search_ignore_other_district',
        label: 'Hack to filter/exclude the other district from the table',
        defaultValue: '-1',
        className: 'w-full md:w-1/4 md:pr-2 hidden',
    },
    {
        type: 'text',
        keyName: 'search_top_level',
        label: 'Top Level Id',
        defaultValue: ({ police_division_id }) => {
            return police_division_id || '';
        },
        className: 'w-full md:w-1/4 md:pr-2 hidden',
    },
    {
        className: 'w-full md:w-2/12 md:pl-2 mt-6',
        render: (props) => {
            const downloadingData = props?.map((x) =>
                pick(x, [
                    'name',
                    'email_address',
                    'station_type',
                    'station_address',
                    'total_officers',
                ]),
            );

            return (
                <button
                    className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    onClick={() =>
                        downloadingData?.length &&
                        exportToExcel(
                            downloadingData,
                            `office_list_${new Date()}`,
                            false,
                        )
                    }
                    style={{ marginTop: 2 }}
                >
                    {'Download'}
                </button>
            );
        },
    },
];
