import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchCriminalById = (criminalId) => {
    const axios = useAxios();
    return useQuery(
        ['Criminals/fetch_criminal_by_id', criminalId],
        async () => {
            const { data } = await axios
                .get(`Criminals/fetch_criminal_by_id?criminal_id=${criminalId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.criminal;
        },
        {
            enabled: !!criminalId,
        },
    );
};

export default useFetchCriminalById;
