import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostTransferOfficer = () => {
    const axios = useAxios();
    return useMutation(async (userDetails) => {
        const response = await axios
            .post('UsersTransfer/createTransfer', JSON.stringify(userDetails))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });
};

export default usePostTransferOfficer;
