import { includes, toLower } from 'lodash';
import { Link } from 'react-router-dom';

import Image from '../Image';
import TableDropdown from '../TableDropdown';

export const adminUserColumns = [
    {
        Header: '#',
        id: 'index',
        className: 'text-center w-16',
        accessor: (_row, i) => i + 1,
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: (props) => {
            const { photo_url, name, user_id } = props.row.original;
            return (
                <Link
                    to={`/records/police-stations?police_division_id=${user_id}`}
                >
                    <div className="text-left flex items-center w-full">
                        <Image
                            src={photo_url}
                            className="h-8 w-8 bg-white rounded-full border"
                            alt="..."
                        />
                        <span className={'ml-3 font-bold text-gray-600'}>
                            {name}
                        </span>
                    </div>
                </Link>
            );
        },
    },
    {
        Header: 'State',
        accessor: 'state',
    },
    {
        Header: 'District',
        accessor: 'district',
    },
    {
        Header: 'Actions',
        accessor: 'id',
        className: 'text-center',
        Cell: (props) => <TableDropdown user={props.row.original} />,
    },
];

export const validatorsForPoliceAdmin = {
    search_text: (list, value) =>
        includes(toLower(list.name), toLower(value)) ||
        includes(toLower(list.buckle_no), toLower(value)),
};

export const searchFilterForPoliceAdmin = [
    {
        type: 'text',
        keyName: 'search_text',
        label: 'Name',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
];
