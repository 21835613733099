import * as yup from 'yup';

export const bloodGroupOptions = [
    'A+',
    'B+',
    'AB+',
    'O+',
    'A-',
    'B-',
    'AB-',
    'O-',
];

const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

const numberValidation = (isRequired) =>
    yup.lazy((value) => {
        if (!value) {
            return isRequired
                ? yup.string().nullable().required('Required field')
                : yup.string().nullable();
        }

        return isRequired
            ? yup.number().required('Required field')
            : yup.number();
    });

export const userHealthSchema = yup.object({
    user_id: yup.number('Numeric Only').required('Required field'),
    blood_pressure: yup.boolean().nullable(),
    diabetes: yup.boolean().nullable(),
    depression: yup.boolean().nullable(),
    tuberculosis: yup.boolean().nullable(),
    asthama: yup.boolean().nullable(),
    mental_disorder: yup.boolean().nullable(),
    std_hiv: yup.boolean().nullable(),
    jaundice: yup.boolean().nullable(),
    epilepsy: yup.boolean().nullable(),
    heart_disease: yup.boolean().nullable(),
    sickle_cell: yup.boolean().nullable(),
    cancer: yup.boolean().nullable(),
    vision_disorders: yup.boolean().nullable(),
    medical_history_other: yup.string().nullable(),

    head_injury: yup.boolean().nullable(),
    leg_fracture: yup.boolean().nullable(),
    hand_fracture: yup.boolean().nullable(),
    other_fracture: yup.boolean().nullable(),
    covid19: yup.boolean().nullable(),
    hernia: yup.boolean().nullable(),
    hydrocele: yup.boolean().nullable(),
    appendix: yup.boolean().nullable(),
    surgical_history_any_other: yup.string().nullable(),
    allergy_history: yup.string().nullable(),

    age: numberValidation(true),
    blood_group: yup.string().required('Required field'),
    weight: numberValidation(true),
    height: numberValidation(true),
    bmi: yup.string(),
    bp: numberValidation(),
    hemoglobin: numberValidation(),
    cbc: numberValidation(),
    rbs: numberValidation(),
    lft: numberValidation(),
    kft: numberValidation(),
    ecg: numberValidation(),
    lipid_profile: numberValidation(),
});

export const inputFields = (user) => [
    {
        groupName: 'मेडिकल इतिहास - Medical History',
        helpText: 'तुम्हाला असलेल्या बिमारी बद्दल इथे टिक करायचं आहे.',
        inputFields: [
            {
                keyName: 'blood_pressure',
                label: 'Blood Pressure',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'diabetes',
                label: 'Diabetes',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'depression',
                label: 'Depression',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'tuberculosis',
                label: 'Tuberculosis',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'asthama',
                label: 'Asthama',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'mental_disorder',
                label: 'Mental Disorder',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'std_hiv',
                label: 'S.T.D / H.I.V.',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'jaundice',
                label: 'Jaundice',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'epilepsy',
                label: 'Epilepsy',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'heart_disease',
                label: 'Heart Disease',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'sickle_cell',
                label: 'Sickle Cell',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'cancer',
                label: 'Cancer',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'vision_disorders',
                label: 'Vision Disorders',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'medical_history_other',
                label: 'Any Other',
                type: 'text',
                className: 'w-full',
                onChangeOverride: (val) => titleCase(val),
            },
        ],
    },
    {
        groupName: 'शस्त्रक्रिया  इतिहास - Surgical Histor',
        helpText: 'तुम्हाला असलेल्या शस्त्रक्रिया बद्दल इथे टिक करायचं आहे.',
        inputFields: [
            {
                keyName: 'head_injury',
                label: 'Head Injury',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'leg_fracture',
                label: 'Leg Fracture',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'hand_fracture',
                label: 'Hand Fracture',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'other_fracture',
                label: 'Other Fracture',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'covid19',
                label: 'COVID 19',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'hernia',
                label: 'Hernia',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'hydrocele',
                label: 'Hydrocele.',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'appendix',
                label: 'Appendix',
                type: 'checkbox',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'surgical_history_any_other',
                label: 'Any Other',
                type: 'text',
                className: 'w-full lg:w-6/12',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'allergy_history',
                label: 'Allergy History',
                type: 'text',
                className: 'w-full lg:w-6/12',
            },
        ],
    },
    {
        groupName: 'वैद्यकीय माहिती - Medical Details',
        inputFields: [
            {
                keyName: 'age',
                label: 'Age',
                type: 'number',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'blood_group',
                label: 'Blood Group',
                type: 'select',
                className: 'w-full lg:w-3/12',
                options: bloodGroupOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'weight',
                label: 'Weight (kg)',
                type: 'number',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'height',
                label: 'Height (cm)',
                type: 'number',
                className: 'w-full lg:w-3/12',
            },
            {
                keyName: 'bmi',
                label: 'BMI',
                type: 'text',
                className: 'w-full',
                disabled: true,
            },
        ],
    },
    user?.gender === 'Female' && {
        groupName: 'Female Additional Medical Checkup Details',
        inputFields: [
            {
                keyName: 'thyroid_profile',
                label: 'Thyroid Profile (mlU/mL)',
                type: 'text',
            },
            {
                keyName: 'serum_electrolyte',
                label: 'Serum Electrolyte (mmol/L)',
                type: 'text',
            },
            {
                keyName: 'sr_calcium',
                label: 'SR. Calcium (mmol/L)',
                type: 'text',
            },
        ],
    },
];
