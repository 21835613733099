import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchUserRoles = () => {
    const axios = useAxios();
    return useQuery('Fetch/userRoles', async () => {
        const { data } = await axios
            .get('Fetch/userRoles')
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data.roles;
    });
};

export default useFetchUserRoles;
