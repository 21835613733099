import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider.jsx';
import FormWithTable from '../Form/FormWithTable.jsx';
import Spinner from '../Spinner.jsx';
import {
    columns,
    inputFields,
    criminalCrimeDetailsSchema,
} from './inputFields/criminalCrimesFormFields.jsx';

const CriminalCrimeForm = ({
    user,
    criminalId,
    viewOnly,
    updating,
    mutate,
    deleteCriminalCrime,
    list,
    title,
    policeStationOptions,
}) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [updatedCriminalCrimeDetails, setUpdatedCriminalCrimeDetails] =
        useState({
            criminal_id: criminalId,
        });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedCriminalCrimeDetails((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await criminalCrimeDetailsSchema.validate(
                updatedCriminalCrimeDetails,
                {
                    abortEarly: false,
                },
            );
            setErrors({});
            mutate(updatedCriminalCrimeDetails);
            setIsOpen(false);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            isOpen={modalIsOpen}
            toggle={setIsOpen}
            formTitle={title}
            addButtonTxt="Add Crime Details"
            data={updatedCriminalCrimeDetails}
            onChange={handleUpdate}
            inputFields={inputFields(user, policeStationOptions, modalIsOpen)}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedCriminalCrimeDetails({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={modalIsOpen ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() => {
                                    setIsOpen(true);
                                    setUpdatedCriminalCrimeDetails(
                                        props.row.original,
                                    );
                                }}
                                className={
                                    'text-sm border border-black mx-2 px-2 py-1 w-auto font-normal block whitespace-nowrap bg-blue-200 text-gray-700'
                                }
                            >
                                {'Edit'}
                            </button>
                            <button
                                onClick={() =>
                                    deleteCriminalCrime(props.row.original)
                                }
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const CriminalCrimeFormCard = ({
    user,
    viewOnly,
    componentWrapper,
    homeDistrict,
    policeStationOptions,
}) => {
    let { criminalId } = useParams();

    const axios = useAxios();

    const fetchCrimeBaseUri = homeDistrict
        ? 'CriminalHomeDistrictCrime'
        : 'CriminalOtherDistrictCrime';
    const title = homeDistrict ? 'Home District Crime' : 'Other District Crime';
    const criminalCrimeDetails = useQuery(
        [`${fetchCrimeBaseUri}/fetch_criminal_crime`, criminalId],
        async () => {
            const { data } = await axios
                .get(
                    `${fetchCrimeBaseUri}/fetch_criminal_crime?criminal_id=${criminalId}`,
                )
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addCriminalCrimeDetails = useMutation(
        async (criminalCrimeDetailsDetails) => {
            const response = await axios
                .post(
                    `${fetchCrimeBaseUri}/add_criminal_crime`,
                    JSON.stringify(criminalCrimeDetailsDetails),
                )
                .then((res) => {
                    criminalCrimeDetails.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    const deleteCriminalCrimeDetails = useMutation(
        async (criminalCrimeDetailsDetails) => {
            const response = await axios
                .post(
                    `${fetchCrimeBaseUri}/delete_criminal_crime`,
                    JSON.stringify(criminalCrimeDetailsDetails),
                )
                .then((res) => {
                    criminalCrimeDetails.refetch();
                })
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return response;
        },
    );

    if (
        addCriminalCrimeDetails.isLoading ||
        deleteCriminalCrimeDetails.isLoading
    ) {
        return <Spinner />;
    }

    if (addCriminalCrimeDetails.error || deleteCriminalCrimeDetails.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <CriminalCrimeForm
                    user={user}
                    criminalId={criminalId}
                    viewOnly={viewOnly}
                    policeStationOptions={policeStationOptions}
                    title={title}
                    mutate={addCriminalCrimeDetails.mutate}
                    deleteCriminalCrime={deleteCriminalCrimeDetails.mutate}
                    list={criminalCrimeDetails.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(criminalCrimeDetails.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default CriminalCrimeFormCard;
