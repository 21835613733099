import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostUpdateCriminal = (options = {}) => {
    const axios = useAxios();
    return useMutation(async (userDetails) => {
        const { data } = await axios
            .post('criminals/update_criminal', JSON.stringify(userDetails))
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return data;
    }, options);
};

export default usePostUpdateCriminal;
