import { createContext, useContext, useState } from 'react';

const AxiosContext = createContext({
    pageTitle: '',
});

export function useAppData() {
    return useContext(AxiosContext);
}

const AppProvider = ({ children }) => {
    const [appData, setAppData] = useState({ pageTitle: '' });

    return (
        <AxiosContext.Provider value={{ appData, setAppData }}>
            {children}
        </AxiosContext.Provider>
    );
};

export default AppProvider;
