import { includes, pick, toLower } from 'lodash';
import moment from 'moment';
import { exportToExcel } from 'react-json-to-excel';
import { Link } from 'react-router-dom';

import { DateCell } from '../FormCard/inputFields/userPostingFormFields';
import Image from '../Image';

export const transferListColumns = [
    {
        Header: 'Buckle No',
        accessor: 'buckle_no',
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: (props) => {
            const { userRoleId } = props;
            const { photo_url, name, user_id } = props.row.original;
            const renderNameCell = (
                <div className="flex items-center text-sm">
                    <div className="relative min-w-12 w-12 h-12 mr-3 rounded-full md:block">
                        {photo_url && (
                            <Image
                                src={photo_url}
                                className="object-cover w-full h-full rounded-full"
                                alt="..."
                                loading="lazy"
                            />
                        )}
                        <div
                            className="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                        ></div>
                    </div>
                    <div>
                        <p className="font-semibold text-black">{name}</p>
                    </div>
                </div>
            );
            if (userRoleId === '3' || userRoleId === '4') {
                return renderNameCell;
            }
            return <Link to={`/view/${user_id}`}>{renderNameCell}</Link>;
        },
    },
    {
        Header: 'Gender',
        accessor: 'gender',
    },
    {
        Header: 'Officer Post',
        accessor: 'officer_post',
    },
    {
        Header: 'Work Type',
        accessor: 'work_type',
        className: 'max-w-200-px',
        headerClassName: 'whitespace-nowrap',
    },
    {
        Header: 'Mobile No',
        accessor: 'mobile_no',
        headerClassName: 'whitespace-nowrap',
        Cell: (props) => {
            const { value } = props;
            return (
                <a className="underline text-blue-400" href={`tel:${value}`}>
                    {value}
                </a>
            );
        },
    },
    {
        Header: 'Office Name',
        accessor: 'office_name',
    },
    {
        Header: 'Retirement Date',
        accessor: 'date_of_retirement',
        Cell: DateCell,
    },
];

export const validatorsForTransferList = {
    search_text: (list, value) =>
        includes(toLower(list.name), toLower(value)) ||
        includes(toLower(list.buckle_no), toLower(value)),
    search_retirement_month: (list, value) =>
        moment(new Date(list.date_of_retirement)).month() + 1 === value,
};

export const monthOptions = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 },
];

export const searchFilterForTransferList = [
    {
        type: 'text',
        keyName: 'search_text',
        label: 'Name',
        defaultValue: '',
        className: 'w-full md:w-1/4 md:pr-2',
    },
    {
        type: 'select',
        keyName: 'search_retirement_month',
        label: 'Month',
        defaultValue: null,
        className: 'w-full md:pl-2 md:w-1/4',
        options: monthOptions,
    },
    {
        className: 'w-full md:w-2/12 md:pl-2 mt-6',
        render: (props) => {
            const downloadingData = props?.map((x) =>
                pick(x, [
                    'office_name',
                    'name_suffix',
                    'name',
                    'gender',
                    'buckle_no',
                    'officer_post',
                    'work_type',
                    'mobile_no',
                    'alt_mobile_no',
                    'wa_mobile_no',
                    'age',
                    'blood_group',
                    'bmi',
                    'family_no',
                    'email_address',
                    'education',
                    'caste_category',
                    'caste',
                    'caste_validity',
                    'date_of_birth',
                    'date_of_joining',
                    'date_of_retirement',
                    'aadhar_card_no',
                    'pan_no',
                    'sevarth_id',
                    'home_address',
                    'home_pincode',
                    'permanent_address',
                    'permanent_pincode',
                    'mode_of_selection',
                    'confirmation_date',
                    'mscit_computer_course',
                    'present_posting_start_date',
                    'present_district_posting_date',
                ]),
            );
            return (
                <button
                    className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    onClick={() =>
                        downloadingData?.length &&
                        exportToExcel(
                            downloadingData,
                            `police_list_${new Date()}`,
                            false,
                        )
                    }
                    style={{ marginTop: 2 }}
                >
                    {'Download'}
                </button>
            );
        },
        hide: (user) =>
            user?.user_role_id === '4' || user?.user_role_id === '3',
    },
];
