import { map } from 'lodash';
import React from 'react';
import { Navigate, useLocation } from 'react-router';

import CardStats from '../../components/CardStats';
import useFetchDashboardDetails from '../../hooks/useFetchDashboardDetails';

const HeaderStats = () => {
    const location = useLocation();
    const {
        data: stat,
        isLoading,
        isFetching,
        error,
    } = useFetchDashboardDetails();

    if (
        location.pathname.indexOf('/dashboard') === -1 ||
        isLoading ||
        isFetching ||
        !stat
    ) {
        return null;
    }

    if (error) {
        return <Navigate to="/auth/Login" />;
    }

    return (
        <>
            <div className={`bg-blue-600 py-4`}>
                <div className="px-4 md:px-10 mx-auto w-full">
                    <div>
                        <div className="bg-orange-500 bg-red-500 bg-yellow-500 bg-lightBlue-500 bg-blue-500"></div>
                        <div className="flex flex-wrap">
                            {map(stat, (s, i) => (
                                <div key={i} className="w-full md:flex-1 px-4">
                                    <CardStats
                                        statSubtitle={s.fieldName}
                                        statTitle={s.count}
                                        statUserId={s.id}
                                        statIconColor={`bg-${s.color}-500`}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default HeaderStats;
