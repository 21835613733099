import { filter, head } from 'lodash';
import * as yup from 'yup';

import useFetchList from '../../../hooks/useFetchList';
import { DateCell } from './userPostingFormFields';

export const userDeputationSchema = yup.object({
    user_id: yup.string().required(),
    deputation_current_office: yup.string().required(),
    deputation_deputed_to: yup.string().required(),
    deputation_from_date: yup.string().required(),
    deputation_till_date: yup.string(),
});

export const StationNameCell = ({ value }) => {
    const list = useFetchList('Users/fetchPoliceStations', {});
    return head(filter(list?.data, (x) => +x.id === +value))?.name || value;
};

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Deputation Current Office',
            accessor: 'deputation_current_office',
            Cell: StationNameCell,
        },
        {
            Header: 'Deputed To',
            accessor: 'deputation_deputed_to',
            Cell: StationNameCell,
        },
        {
            Header: 'From Date',
            accessor: 'deputation_from_date',
            Cell: DateCell,
        },
        {
            Header: 'Till Date',
            accessor: 'deputation_till_date',
            Cell: DateCell,
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (data, isUpdating) => [
    {
        groupName: '',
        inputFields: [
            {
                keyName: 'id',
                label: 'ID',
                type: 'text',
                className: 'hidden',
            },
            {
                keyName: 'deputation_current_office',
                label: 'Current Office',
                type: 'asyncSelect',
                className: 'w-full lg:w-1/2',
                fetchOptionsKey: 'Users/fetchPoliceStations',
                optionMapper: (ps) => ({
                    label: ps.name,
                    value: ps.id,
                }),
            },
            {
                keyName: 'deputation_deputed_to',
                label: 'Deputed to',
                type: 'asyncSelect',
                className: 'w-full lg:w-1/2',
                fetchOptionsKey: 'Users/fetchPoliceStations',
                optionMapper: (ps) => ({
                    label: ps.name,
                    value: ps.id,
                }),
            },
            {
                keyName: 'deputation_from_date',
                label: 'From Date',
                type: 'date',
                className: 'w-full lg:w-1/2',
            },
            isUpdating && {
                keyName: 'deputation_till_date',
                label: 'Till Date',
                type: 'date',
                className: 'w-full lg:w-1/2',
            },
        ],
    },
];
