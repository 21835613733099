const authUserStorage = {
    getUser: () => {
        try {
            return JSON.parse(localStorage.getItem('auth_user_token')) || {};
        } catch (error) {
            return {};
        }
    },
    setUser: (user) => {
        try {
            return localStorage.setItem(
                'auth_user_token',
                JSON.stringify(user),
            );
        } catch (error) {
            return null;
        }
    },
    removeUser: () => localStorage.removeItem('auth_user_token'),
};

export default authUserStorage;
