import L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css';
import 'leaflet/dist/leaflet.css';
import { useMemo, useRef, useState } from 'react';
import {
    MapContainer,
    Marker,
    TileLayer,
    useMap,
    useMapEvents,
} from 'react-leaflet';

import InputText from './InputText';

const iconMarker = new L.Icon({
    iconUrl: '/images/map-marker.svg',
    iconRetinaUrl: '/images/map-marker.svg',
    iconAnchor: null,
    popupAnchor: null,
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    iconSize: new L.Point(60, 75),
    className: '',
});

const ChangeView = ({ center, zoom, setZoom }) => {
    const map = useMap();
    const mapEvents = useMapEvents({
        zoomend: () => {
            setZoom(mapEvents.getZoom());
        },
    });

    map.setView(center, zoom);
    return null;
};

const InputMap = ({ disabled, latitude, longitude, onChange, label }) => {
    const [zoom, setZoom] = useState(12);
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current;
                if (marker != null) {
                    const { lat, lng } = marker.getLatLng();
                    onChange({
                        latitude: lat,
                        longitude: lng,
                    });
                }
            },
        }),
        [onChange],
    );

    const markerProps = disabled
        ? {}
        : {
              ref: markerRef,
              draggable: true,
              eventHandlers,
              animate: true,
          };

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 p-0 md:px-4">
                    <InputText
                        label={label[0]}
                        disabled={disabled}
                        value={latitude}
                        onChange={(e) =>
                            onChange({
                                latitude: e.target.value,
                                longitude,
                            })
                        }
                    />
                </div>
                <div className="w-full lg:w-6/12 p-0 md:px-4">
                    <InputText
                        label={label[0]}
                        disabled={disabled}
                        value={longitude}
                        onChange={(e) =>
                            onChange({
                                latitude,
                                longitude: e.target.value,
                            })
                        }
                    />
                </div>
            </div>

            {latitude && longitude && (
                <div className="relative w-full mb-3 p-0 md:px-4">
                    <MapContainer
                        center={[latitude, longitude]}
                        zoom={zoom}
                        zoomControl={!disabled}
                        attributionControl={!disabled}
                        closePopupOnClick={!disabled}
                        zoomSnap={!disabled}
                        zoomDelta={!disabled}
                        trackResize={!disabled}
                        boxZoom={!disabled}
                        doubleClickZoom={!disabled}
                        dragging={!disabled}
                        style={{ height: '50vh', width: '100%' }}
                    >
                        <ChangeView
                            center={[latitude, longitude]}
                            zoom={zoom}
                            setZoom={setZoom}
                        />
                        <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" />
                        <Marker
                            {...markerProps}
                            position={[latitude, longitude]}
                            icon={iconMarker}
                        ></Marker>
                    </MapContainer>
                </div>
            )}
        </>
    );
};

export default InputMap;
