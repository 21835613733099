import { filter, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { Navigate, useNavigate, useLocation, useParams } from 'react-router';
import ImageUploading from 'react-images-uploading';

import Form from '../Form/Form';
import Spinner from '../Spinner';
import CriminalFamiliesFormCard from './CriminalFamiliesFormCard';
import { inputFields, criminalSchema } from './inputFields/criminalFormFields';
import usePostCreateCriminal from '../../hooks/usePostCreateCriminal';
import usePostUpdateCriminal from '../../hooks/usePostUpdateCriminal';
import useFetchCriminalById from '../../hooks/useFetchCriminalById';
import CriminalCrimeFormCard from './CriminalCrimeFormCard';
import useFetchList from '../../hooks/useFetchList';
import usePostCriminalUploadImage from '../../hooks/usePostCriminalUploadImage';

const CriminalForm = ({
    refresh,
    viewOnly,
    viewProfile,
    updating,
    criminalById,
    policeAdminOptions,
    policeStationOptions,
    mutate,
}) => {
    const [updatedCriminal, setUpdatedCriminal] = useState(criminalById);
    const location = useLocation();

    const [images, setImages] = React.useState(
        map(updatedCriminal.photos || [], (x) => ({
            id: x.id,
            data_url: x.photo_url,
        })),
    );
    const maxNumber = 5;

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setImages(imageList);
    };
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const uploadCriminalImage = usePostCriminalUploadImage({
        onSuccess: (data) => {
            if (location.pathname.indexOf('update_criminal')) {
                refresh();
            } else {
                navigate(
                    data.criminal_id
                        ? `/update_criminal/${data.criminal_id}`
                        : '/',
                );
            }
        },
    });

    const createCriminal = usePostCreateCriminal({
        onSuccess: (data) => {
            uploadCriminalImage.mutate(
                filter(
                    map(images, (img) => ({
                        id: img.id,
                        criminal_id: updatedCriminal.id,
                        photo_url: img.data_url,
                    })),
                    (x) => !!x.criminal_id || !!x.photo_url,
                ),
            );
        },
    });
    const updateCriminal = usePostUpdateCriminal({
        onSuccess: () => {
            uploadCriminalImage.mutate(
                filter(
                    map(images, (img) => ({
                        criminal_id: updatedCriminal.id,
                        photo_url: img.data_url,
                    })),
                    (x) => !!x.criminal_id || !!x.photo_url,
                ),
            );
        },
    });

    const handleUpdate = (value) => {
        setUpdatedCriminal((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async (event) => {
        const handleMutate = mutate
            ? mutate
            : updating
              ? updateCriminal.mutate
              : createCriminal.mutate;

        try {
            await criminalSchema(
                policeAdminOptions || policeStationOptions,
                updatedCriminal,
            ).validate(updatedCriminal, { abortEarly: false });
            setErrors({});
            handleMutate(updatedCriminal);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    if (
        createCriminal.error ||
        updateCriminal.error ||
        uploadCriminalImage.error
    ) {
        return <Navigate to="/auth/Login" />;
    }

    const uploadPhoto = () => (
        <ImageUploading
            multiple
            value={images}
            onChange={onChange}
            maxNumber={maxNumber}
            dataURLKey="data_url"
        >
            {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
            }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    {!viewOnly && (
                        <button
                            className="image-item"
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            {...dragProps}
                        >
                            <div className="img-wrapper">
                                <div className="img">Click or Drop here</div>
                            </div>
                            <p className="img-label">{'Upload Photo'}</p>
                        </button>
                    )}
                    {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                            <div className="img-wrapper">
                                <img
                                    className="img"
                                    src={image['data_url']}
                                    alt=""
                                    width="100"
                                />
                            </div>
                            <p className="img-label">{'Photo'}</p>
                            {!viewOnly && (
                                <div className="image-item__btn-wrapper">
                                    <button
                                        onClick={() => onImageRemove(index)}
                                    >
                                        X
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </ImageUploading>
    );

    return (
        <>
            {(createCriminal.isLoading ||
                updateCriminal.isLoading ||
                uploadCriminalImage.isLoading) && <Spinner />}
            <Form
                renderUploadImage={uploadPhoto}
                formTitle="Criminal Information"
                data={updatedCriminal}
                onChange={handleUpdate}
                inputFields={inputFields(
                    criminalById,
                    viewOnly,
                    viewProfile,
                    policeAdminOptions,
                    policeStationOptions,
                )}
                onSubmit={submitData}
                errors={errors}
                showReset={!!mutate}
                onReset={() => {
                    setUpdatedCriminal(criminalById || {});
                    setErrors({});
                }}
                resetLabel={'Reset'}
                submitLabel={
                    mutate ? 'Save & Continue' : updating ? 'Update' : 'Add'
                }
                viewOnly={viewOnly}
            />
        </>
    );
};

const CriminalFormCard = ({
    user,
    viewOnly,
    viewProfile,
    updating,
    mutate,
}) => {
    let { criminalId } = useParams();

    const criminalById = useFetchCriminalById(!mutate && criminalId);

    const policeStationOptions = useFetchList(
        'Users/fetchPoliceStationAdminUsers',
    );

    if (
        policeStationOptions.isLoading ||
        policeStationOptions.isFetching ||
        !policeStationOptions.data ||
        criminalById.isLoading ||
        criminalById.isFetching ||
        (updating && !criminalById.data)
    ) {
        return <Spinner />;
    }

    if (criminalById.error) {
        return <Navigate to="/auth/Login" />;
    }

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                    <CriminalForm
                        refresh={() => criminalById.refetch()}
                        user={user}
                        viewOnly={viewOnly}
                        updating={updating}
                        viewProfile={viewProfile}
                        policeStationOptions={policeStationOptions.data}
                        mutate={mutate}
                        criminalById={
                            viewProfile
                                ? {
                                      ...user,
                                      password: null,
                                  }
                                : updating || viewOnly
                                  ? {
                                        ...criminalById.data,
                                    }
                                  : {
                                        is_active: true,
                                    }
                        }
                    />
                </div>
                {updating && criminalId && (
                    <>
                        <CriminalFamiliesFormCard viewOnly={viewOnly} />
                        <CriminalCrimeFormCard
                            viewOnly={viewOnly}
                            user={user}
                            homeDistrict={true}
                            policeStationOptions={policeStationOptions.data}
                        />
                        <CriminalCrimeFormCard
                            viewOnly={viewOnly}
                            user={user}
                            homeDistrict={false}
                            policeStationOptions={policeStationOptions.data}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default CriminalFormCard;
