import { isEmpty, map } from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { BsXLg } from 'react-icons/bs';
import Modal from 'react-modal';

import CardTable from '../CardTable';
import renderInput from './renderInput';

const customStyles = {
    overlay: {
        zIndex: 99999,
    },
};

const FormWithTable = ({
    isOpen,
    toggle,
    addButtonTxt,
    columns,
    data,
    errors,
    formTitle,
    renderHeader,
    inputFields,
    list,
    onChange,
    onReset,
    onSubmit,
    resetLabel,
    showReset,
    submitLabel,
    viewOnly,
}) => {
    const [modalIsOpen, setIsOpen] = React.useState(isOpen);

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        toggle?.(false);
        setIsOpen(false);
    }

    useEffect(() => {
        setIsOpen(isOpen);
    }, [isOpen]);

    return (
        <>
            <div
                className={`relative flex flex-col min-w-0 break-words bg-white w-full rounded-lg ${
                    viewOnly ? '' : 'shadow-xl mb-6'
                }`}
                style={{ zIndex: 9 }}
            >
                <div className={viewOnly ? 'w-full' : 'md:px-6 w-full'}>
                    <div className="flex flex-col w-full">
                        {!viewOnly && (
                            <div className="flex my-4">
                                <h2 className="text-3xl font-normal leading-normal text-center flex-1">
                                    {formTitle}
                                </h2>
                                {!renderHeader && (
                                    <button
                                        onClick={openModal}
                                        className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        {addButtonTxt}
                                    </button>
                                )}
                            </div>
                        )}
                        {!viewOnly && renderHeader && (
                            <div className="flex flex-col my-4">
                                {renderHeader?.()}
                                <div className="flex justify-end">
                                    <button
                                        onClick={openModal}
                                        className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        {addButtonTxt}
                                    </button>
                                </div>
                            </div>
                        )}

                        <CardTable columns={columns} data={list || []} />
                        <Modal
                            isOpen={modalIsOpen}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div className="flex justify-between">
                                <h2 className="text-3xl font-normal leading-normal text-center flex-1">
                                    {formTitle}
                                </h2>
                                <button onClick={closeModal}>
                                    <BsXLg />
                                </button>
                            </div>
                            <form className="w-full px-4 pb-4 mt-16 md:m-auto">
                                {map(
                                    inputFields,
                                    (inputField, inputFieldIndex) =>
                                        inputField && (
                                            <div
                                                key={inputFieldIndex}
                                                className={inputField.className}
                                            >
                                                <h6 className="text-gray-400 text-sm mt-3 mb-6 font-bold">
                                                    {inputField.groupName}
                                                    <p className="block text-blue-400 text-xs font-bold mb-2">
                                                        {inputField.helpText}
                                                    </p>
                                                </h6>
                                                <div className="flex flex-wrap items-end">
                                                    {map(
                                                        inputField.inputFields,
                                                        (inputF, index) => (
                                                            <React.Fragment
                                                                key={index}
                                                            >
                                                                {renderInput({
                                                                    inputF,
                                                                    viewOnly,
                                                                    onChange,
                                                                    data,
                                                                    errors,
                                                                })}
                                                            </React.Fragment>
                                                        ),
                                                    )}
                                                </div>
                                                {!inputField.hideHr && (
                                                    <hr className="w-full mt-6 mb-6 border-b-1 border-gray-300" />
                                                )}
                                            </div>
                                        ),
                                )}
                                {!viewOnly && (
                                    <div className="w-full px-4 lg:order-3 lg:text-right lg:self-center">
                                        <div className="py-6 px-3 text-center">
                                            {!isEmpty(errors) && (
                                                <p
                                                    className="text-red-500 mb-3"
                                                    style={{ minHeight: 24 }}
                                                >
                                                    {
                                                        'Please resolve all the errors and try again!'
                                                    }
                                                </p>
                                            )}
                                            {showReset && (
                                                <button
                                                    onClick={onReset}
                                                    className="bg-red-500 active:bg-red-600 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                >
                                                    {resetLabel}
                                                </button>
                                            )}
                                            <button
                                                onClick={onSubmit}
                                                className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                                type="button"
                                            >
                                                {submitLabel}
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FormWithTable;
