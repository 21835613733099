import { useQuery } from 'react-query';

import { useAxios } from '../AxiosProvider';

const useFetchUserDetailsById = (userId) => {
    const axios = useAxios();
    return useQuery(
        ['users/fetchLoggedInUser', userId],
        async () => {
            const { data } = await axios
                .get(`users/fetchLoggedInUser?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
        {
            enabled: !!userId,
        },
    );
};

export default useFetchUserDetailsById;
