import classNames from 'classnames';
import { map, pick } from 'lodash';
import React from 'react';
import { BsFillSignpost2Fill, BsXLg } from 'react-icons/bs';
import { exportToExcel } from 'react-json-to-excel';
import ReactModal from 'react-modal';
import { Link, useNavigate } from 'react-router-dom';

import usePostTransferOfficer from '../../hooks/usePostTransferOfficer';
import {
    genderOptions,
    officerRankOptions,
    sortedOfficerRanks,
} from '../FormCard/inputFields/userFormFields';
import Image from '../Image';
import { AsyncSelect } from './renderSearchInput';

const customStyles = {
    overlay: {
        zIndex: 99999,
    },
};

export const ModalForTransfer = ({ row: { original } }) => {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [selectedOffice, setSelectedOffice] = React.useState(null);
    const transfer = usePostTransferOfficer();
    const navigate = useNavigate();

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }

    const handleTransfer = async () => {
        if (
            !selectedOffice ||
            Number(selectedOffice) === Number(original.police_station_id)
        ) {
            return;
        }
        await transfer.mutateAsync({
            user_id: original.user_id,
            police_station_id: selectedOffice,
        });
        navigate(0);
    };

    return (
        <>
            <button
                className="text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-orange-500 text-gray-700"
                type="button"
                onClick={openModal}
            >
                <BsFillSignpost2Fill className="fill-white" />
            </button>
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="flex justify-between">
                    <h2 className="text-3xl font-normal leading-normal text-center flex-1">
                        {'Transfer Officer'}
                    </h2>
                    <button onClick={closeModal}>
                        <BsXLg />
                    </button>
                </div>
                <hr />
                <div className="">
                    <div className="flex w-full my-4">
                        <p className="px-4">
                            <span className="font-bold px-2">Name:</span>
                            <span>{original.name}</span>
                        </p>
                    </div>
                    <div className="flex w-full my-4">
                        <p className="flex-1 px-4">
                            <span className="font-bold px-2">Gender:</span>
                            <span>{original.gender}</span>
                        </p>
                        <p className="flex-1 px-4">
                            <span className="font-bold px-2">Rank:</span>
                            <span>{original.officer_post}</span>
                        </p>
                    </div>
                    <hr className="my-4" />
                    <div className="flex w-full my-4">
                        <p className="flex-1 px-4">
                            <AsyncSelect
                                disabled
                                label="Current Office"
                                keyName="search_police_station"
                                value={original.police_station_id}
                                className={classNames(
                                    'w-full md:w-1/4 md:px-2',
                                )}
                                fetchOptionsKey="Users/fetchPoliceStations"
                                optionMapper={(ps) => ({
                                    label: ps.name,
                                    value: ps.id,
                                })}
                            />
                        </p>
                        <p className="flex-1 px-4">
                            <AsyncSelect
                                label="Transfer To"
                                value={selectedOffice}
                                onChange={(option) => {
                                    if (
                                        option.value !==
                                        original.police_station_id
                                    ) {
                                        setSelectedOffice(option.value);
                                    }
                                }}
                                fetchOptionsKey="Users/fetchPoliceStations"
                                optionMapper={(ps) => ({
                                    label: ps.name,
                                    value: ps.id,
                                })}
                            />
                        </p>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            onClick={handleTransfer}
                            className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                            type="button"
                        >
                            {'Transfer Officer'}
                        </button>
                    </div>
                </div>
            </ReactModal>
        </>
    );
};

export const transferPoliceColumns = [
    {
        Header: 'Buckle No',
        accessor: 'buckle_no',
    },
    {
        Header: 'Name',
        accessor: 'name',
        Cell: (props) => {
            const { userRoleId } = props;
            const { photo_url, name, user_id } = props.row.original;
            const renderNameCell = (
                <div className="flex items-center text-sm">
                    <div className="relative min-w-12 w-12 h-12 mr-3 rounded-full md:block">
                        {photo_url && (
                            <Image
                                src={photo_url}
                                className="object-cover w-full h-full rounded-full"
                                alt="..."
                                loading="lazy"
                            />
                        )}
                        <div
                            className="absolute inset-0 rounded-full shadow-inner"
                            aria-hidden="true"
                        ></div>
                    </div>
                    <div>
                        <p className="font-semibold text-black">{name}</p>
                    </div>
                </div>
            );
            if (userRoleId === '3' || userRoleId === '4') {
                return renderNameCell;
            }
            return <Link to={`/view/${user_id}`}>{renderNameCell}</Link>;
        },
    },
    {
        Header: 'Gender',
        accessor: 'gender',
    },
    {
        Header: 'Officer Post',
        accessor: 'officer_post',
        sortType: (rowA, rowB, id) => {
            const rowAIndex = sortedOfficerRanks.indexOf(rowA.values[id]);
            const rowBIndex = sortedOfficerRanks.indexOf(rowB.values[id]);
            if (rowAIndex > rowBIndex) return 1;
            if (rowBIndex > rowAIndex) return -1;
            return 0;
        },
    },
    {
        Header: 'Work Type',
        accessor: 'work_type',
        className: 'max-w-200-px',
        headerClassName: 'whitespace-nowrap',
    },
    {
        Header: 'Mobile No',
        accessor: 'mobile_no',
        headerClassName: 'whitespace-nowrap',
        Cell: (props) => {
            const { value } = props;
            return (
                <a className="underline text-blue-400" href={`tel:${value}`}>
                    {value}
                </a>
            );
        },
    },
    {
        Header: 'Office Name',
        accessor: 'office_name',
    },
    {
        Header: 'Transfer',
        accessor: 'police_station_id',
        Cell: (props) => (
            <div className="inline-flex justify-center items-center w-full">
                <ModalForTransfer {...props} />
            </div>
        ),
        hide: (props) => {
            return props.userRoleId === '3' || props.userRoleId === '4';
        },
    },
];

export const searchFilterForTransferPolice = (hidePoliceStation = false) => [
    {
        type: 'text',
        keyName: 'search_text',
        label: 'Name',
        defaultValue: '',
        className: classNames('w-full md:px-2', {
            'md:w-1/4': !hidePoliceStation,
            'md:w-1/3': hidePoliceStation,
        }),
    },
    {
        type: 'asyncSelect',
        keyName: 'search_police_station',
        label: 'Police Station',
        defaultValue: ({ police_station_id }) => {
            return police_station_id || null;
        },
        className: classNames('w-full md:w-1/4 md:px-2', {
            hidden: hidePoliceStation,
        }),
        fetchOptionsKey: 'Users/fetchPoliceStations',
        optionMapper: (ps) => ({
            label: ps.name,
            value: ps.id,
        }),
    },
    {
        type: 'select',
        keyName: 'search_rank',
        label: 'Officer Post / Rank',
        defaultValue: null,
        className: classNames('w-full md:px-2', {
            'md:w-1/4': !hidePoliceStation,
            'md:w-1/3': hidePoliceStation,
        }),
        options: map(officerRankOptions, (opr) => ({
            label: opr,
            value: opr,
        })),
    },
    {
        type: 'select',
        keyName: 'search_gender',
        label: 'Gender',
        defaultValue: null,
        className: classNames('w-full md:px-2', {
            'md:w-1/4': !hidePoliceStation,
            'md:w-1/3': hidePoliceStation,
        }),
        options: genderOptions,
    },
    {
        className: 'w-full md:w-2/12 md:px-2',
        render: (props) => {
            const downloadingData = props?.map((x) =>
                pick(x, [
                    'office_name',
                    'name_suffix',
                    'name',
                    'gender',
                    'buckle_no',
                    'officer_post',
                    'work_type',
                    'mobile_no',
                    'alt_mobile_no',
                    'wa_mobile_no',
                    'age',
                    'blood_group',
                    'bmi',
                    'family_no',
                    'email_address',
                    'education',
                    'caste_category',
                    'caste',
                    'caste_validity',
                    'date_of_birth',
                    'date_of_joining',
                    'date_of_retirement',
                    'aadhar_card_no',
                    'pan_no',
                    'sevarth_id',
                    'home_address',
                    'home_pincode',
                    'permanent_address',
                    'permanent_pincode',
                    'mode_of_selection',
                    'confirmation_date',
                    'mscit_computer_course',
                    'present_posting_start_date',
                    'present_district_posting_date',
                ]),
            );
            return (
                <button
                    className="w-auto bg-police-blue active:bg-yellow-400 text-white text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    onClick={() =>
                        downloadingData?.length &&
                        exportToExcel(
                            downloadingData,
                            `police_list_${new Date()}`,
                            false,
                        )
                    }
                    style={{ marginTop: 2 }}
                >
                    {'Download'}
                </button>
            );
        },
        hide: (user) =>
            user?.user_role_id === '4' || user?.user_role_id === '3',
    },
];
