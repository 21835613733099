import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import React from 'react';
import { BsFillEyeFill, BsFillPenFill, BsHeartFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

export const TableDropdown = ({ user, criminal }) => {
    if (criminal) {
        return (
            <div className="inline-flex justify-center items-center w-full">
                <Link
                    to={`/view_criminal/${criminal.id}`}
                    className={
                        'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-green-500 text-gray-700'
                    }
                >
                    <BsFillEyeFill className="fill-white" />
                </Link>
                <Link
                    to={`/update_criminal/${criminal.id}`}
                    className={
                        'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-blue-500 text-gray-700'
                    }
                >
                    <BsFillPenFill className="fill-white" />
                </Link>
            </div>
        );
    }

    return (
        <div className="inline-flex justify-center items-center w-full">
            <Link
                to={`/view/${user.user_id}`}
                className={
                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-green-500 text-gray-700'
                }
            >
                <BsFillEyeFill className="fill-white" />
            </Link>
            <Link
                to={`/update/${user.user_id}`}
                className={
                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-blue-500 text-gray-700'
                }
            >
                <BsFillPenFill className="fill-white" />
            </Link>
            {user.user_role_id === '4' && (
                <Link
                    to={`/update_health/${user.user_id}`}
                    className={
                        'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                    }
                >
                    <BsHeartFill className="fill-white" />
                </Link>
            )}
        </div>
    );
};

export default TableDropdown;
