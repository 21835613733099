import * as yup from 'yup';
import { MobileRegx, renderCustomName, titleCase } from './userFormFields';

const relationOptions = [
    'Father',
    'Mother',
    'Brother',
    'Sister',
    'Life Partner',
    'Relative',
    'Friends',
];

export const criminalFamilyDetailsSchema = yup.object({
    criminal_id: yup.string().required(),
    name: yup.string().required(),
    age: yup.string().required(),
    mobile_number: yup
        .string()
        .trim()
        .matches(MobileRegx, '10 digit mandatory')
        .required('Required field'),
    address: yup.string().required(),
    alive_or_dead: yup.string().required(),
    relation_with_criminal: yup.string().required(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Relationship',
            accessor: 'relation_with_criminal',
        },
        {
            Header: 'Name',
            accessor: 'name',
        },
        {
            Header: 'Age',
            accessor: 'age',
        },
        {
            Header: 'Mobile No.',
            accessor: 'mobile_number',
        },
        {
            Header: 'Address',
            accessor: 'address',
        },
        {
            Header: 'Alive or Dead',
            accessor: 'alive_or_dead',
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user) => [
    {
        groupName: 'Add Criminal Family Details',
        inputFields: [
            {
                keyName: 'name',
                label: 'Name of Person ( in English)',
                render: renderCustomName,
                errorKeyName: 'name',
                onChangeOverride: (val) => titleCase(val),
            },
            { keyName: 'age', label: 'Age', type: 'text' },
            { keyName: 'mobile_number', label: 'Mobile Number', type: 'text' },
            { keyName: 'address', label: 'Address', type: 'text' },
            {
                keyName: 'alive_or_dead',
                label: 'Alive Or Dead',
                type: 'select',
                options: ['Alive', 'Dead'],
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'relation_with_criminal',
                label: 'Relation With Criminal',
                type: 'select',
                options: relationOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
        ],
    },
];
