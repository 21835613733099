import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

import authStorage from './auth';
import authUserStorage from './authUser';
import AuthPage from './pages/AuthPage';
import DashboardPage from './pages/DashboardPage';
import PublicPage from './pages/PublicPage';

const ProtectedRoute = (props) => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = useCallback(() => {
        const userToken = authStorage.getToken();
        if (!userToken || userToken === 'undefined') {
            setIsLoggedIn(false);
            authStorage.removeToken();
            authUserStorage.removeUser();
            return navigate('/auth/login', { replace: true });
        }
        axios.defaults.headers['Authorization'] =
            `Bearer ${authStorage.getToken()}`;
        setIsLoggedIn(true);
    }, [navigate]);

    useEffect(() => {
        checkUserToken();
    }, [checkUserToken, isLoggedIn]);

    return (
        <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>
    );
};

export const Router = () => {
    const applicationRoutes = useRoutes([
        { path: '/auth/*', element: <AuthPage /> },
        { path: '/form/*', element: <PublicPage /> },
        {
            path: '/*',
            element: (
                <ProtectedRoute>
                    <DashboardPage />
                </ProtectedRoute>
            ),
        },
    ]);

    return applicationRoutes;
};
