import * as yup from 'yup';

import { DateCell } from './userPostingFormFields';

const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

export const userRewardSchema = yup.object({
    user_id: yup.string().required(),
    rewards_details: yup.string().required(),
    rewards_date: yup.string().required(),
});

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Reward Details',
            accessor: 'rewards_details',
        },
        {
            Header: 'Reward Date',
            accessor: 'rewards_date',
            Cell: DateCell,
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user) => [
    {
        groupName: 'Add Reward Details',
        inputFields: [
            {
                keyName: 'rewards_details',
                label: 'Reward Details',
                type: 'text',
                className: 'w-full lg:w-1/3',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'rewards_date',
                label: 'Reward Date',
                type: 'date',
                className: 'w-full lg:w-1/2',
            },
        ],
    },
];
