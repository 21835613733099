import { filter, isEmpty, map } from 'lodash';
import React from 'react';

import renderInput from './renderInput';

const Form = ({
    data,
    onChange,
    renderHeader,
    renderUploadImage,
    inputFields,
    onSubmit,
    onReset,
    showReset,
    submitLabel,
    resetLabel,
    viewOnly,
    errors,
    formTitle,
    hideTitle,
}) => {
    return (
        <>
            <div
                className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-8"
                style={{ zIndex: 9 }}
            >
                <div className="px-4 md:px-0">
                    {renderHeader?.()}
                    {!hideTitle && formTitle && (
                        <h2 className="font-bold text-3xl leading-normal mt-0 py-4 text-center">
                            {formTitle}
                        </h2>
                    )}
                    {renderUploadImage?.()}
                    <form className="w-full md:m-auto">
                        {map(inputFields, (inputField, inputFieldIndex) => {
                            if (
                                !filter(inputField.inputFields, (x) => !!x)
                                    .length
                            ) {
                                return;
                            }
                            return (
                                <div
                                    key={inputFieldIndex}
                                    className={inputField.className}
                                >
                                    {inputField.groupName ? (
                                        <h6 className="bg-gray-200 p-4 mb-4 text-black text-center text-xl font-bold">
                                            {inputField.groupName}
                                            {!!inputField.helpText && (
                                                <p className="block text-blue-400 text-xs font-bold mt-2">
                                                    {inputField.helpText}
                                                </p>
                                            )}
                                        </h6>
                                    ) : (
                                        <h6 className="bg-gray-200 p-1 mb-4 text-black text-center text-xl font-bold">
                                            {' '}
                                        </h6>
                                    )}
                                    <div className="flex flex-wrap items-end">
                                        {map(
                                            inputField.inputFields,
                                            (inputF, index) => (
                                                <React.Fragment key={index}>
                                                    {renderInput({
                                                        inputF,
                                                        viewOnly,
                                                        onChange,
                                                        data,
                                                        errors,
                                                    })}
                                                </React.Fragment>
                                            ),
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                        {!viewOnly && (
                            <div className="w-full px-4 lg:order-3 lg:text-right lg:self-center">
                                <div className="py-6 px-3 text-center">
                                    {!isEmpty(errors) && (
                                        <p
                                            className="text-red-500 mb-3"
                                            style={{ minHeight: 24 }}
                                        >
                                            {
                                                'Please resolve all the errors and try again!'
                                            }
                                        </p>
                                    )}
                                    {showReset && (
                                        <button
                                            onClick={onReset}
                                            className="bg-red-500 active:bg-red-600 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                        >
                                            {resetLabel}
                                        </button>
                                    )}
                                    <button
                                        onClick={onSubmit}
                                        className="bg-police-blue active:bg-yellow-400 text-white font-bold hover:shadow-md shadow text-xls px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                    >
                                        {submitLabel}
                                    </button>
                                </div>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </>
    );
};

export default Form;
