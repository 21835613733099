const InputText = ({
    keyName,
    value,
    onChange,
    disabled,
    label,
    helpText,
    error,
    type = 'text',
    hideErrors,
}) => (
    <div className="relative w-full mb-3">
        <label
            className="block text-gray-600 text-xs font-bold mb-2"
            htmlFor={`text_${keyName}`}
        >
            {label}
        </label>
        <p className="block text-blue-400 text-xs font-bold mb-2">{helpText}</p>
        <input
            id={`text_${keyName}`}
            type={type}
            className="h-input border p-2 placeholder-gray-300 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            value={value || ''}
            onChange={onChange}
            disabled={disabled}
        />
        {!hideErrors && (
            <p className="text-red-500" style={{ minHeight: 24 }}>
                {error}
            </p>
        )}
    </div>
);

export default InputText;
