import { useMutation } from 'react-query';

import { useAxios } from '../AxiosProvider';

const usePostLogin = () => {
    const axios = useAxios();
    return useMutation(
        async (loginDetails) =>
            (await axios.post('Auths/login', JSON.stringify(loginDetails)))
                ?.data,
    );
};

export default usePostLogin;
