import classNames from 'classnames';
import moment from 'moment';
import * as yup from 'yup';

import { officerRankOptions } from './userFormFields';

const titleCase = (str) => {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
            splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
};

export const userPostingSchema = yup.object({
    user_id: yup.string().required(),
    police_station_id: yup.string().required(),
    office_name: yup.string(),
    district_name: yup.string(),
    officer_post: yup.string().required(),
    from_Date: yup.string().required(),
    to_Date: yup.string().required(),
});

export const dateFormatter = (value) =>
    moment(new Date(value)).format('DD-MM-YYYY');

export const DateCell = ({ value }) => {
    return value ? dateFormatter(value) : value;
};

export const columns = (actions) => {
    const columnsWithoutAction = [
        {
            Header: 'Sr. No.',
            accessor: 'index',
        },
        {
            Header: 'Office Name',
            accessor: 'office_name',
        },
        {
            Header: 'District Name',
            accessor: 'district_name',
        },
        {
            Header: 'Officer Post',
            accessor: 'officer_post',
        },
        {
            Header: 'From Date',
            accessor: 'from_Date',
            Cell: DateCell,
        },
        {
            Header: 'Till Date',
            accessor: 'to_Date',
            Cell: DateCell,
        },
    ];

    if (actions) {
        columnsWithoutAction.push({
            Header: 'Actions',
            accessor: 'id',
            Cell: actions,
        });
    }
    return columnsWithoutAction;
};

export const inputFields = (user, updatedUserPosting) => [
    {
        groupName: 'Add Posting Details',
        hideHr: true,
        inputFields: [
            {
                type: 'asyncSelect',
                keyName: 'police_station_id',
                label: 'Police Station',
                defaultValue: ({ police_division_id }) => {
                    return police_division_id || null;
                },
                className: 'w-full lg:w-1/3',
                fetchOptionsKey: 'Users/fetchPoliceStations',
                optionMapper: (ps) => ({
                    label: ps.name,
                    value: ps.id,
                }),
            },
            {
                keyName: 'office_name',
                label: 'Office Name - कार्यालयाचे नाव',
                type: 'text',
                className: (() =>
                    classNames('w-full lg:w-1/3', {
                        hidden:
                            Number(updatedUserPosting.police_station_id) !== -1,
                    }))(),
                defaultValue: 'placeholder',
                onChangeOverride: (val) => titleCase(val),
            },
            {
                keyName: 'district_name',
                label: 'District Name - ठिकाण व जिल्ह्याचे नाव',
                type: 'text',
                className: (() =>
                    classNames('w-full lg:w-1/3', {
                        hidden:
                            Number(updatedUserPosting.police_station_id) !== -1,
                    }))(),
                defaultValue: 'placeholder',
                onChangeOverride: (val) => titleCase(val),
            },
        ],
    },
    {
        groupName: '',
        inputFields: [
            {
                keyName: 'officer_post',
                label: 'Post / Rank - हुद्दा',
                type: 'select',
                className: 'w-full lg:w-1/3',
                options: officerRankOptions,
                optionMapper: (x) => ({ label: x, value: x }),
            },
            {
                keyName: 'from_Date',
                label: 'From Date - या तारखेपासून',
                type: 'date',
                className: 'w-full lg:w-1/3',
            },
            {
                keyName: 'to_Date',
                label: 'Till Date - ते या तारखेपर्यंत',
                type: 'date',
                className: 'w-full lg:w-1/3',
            },
        ],
    },
];
