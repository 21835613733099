import useFetchList from '../../hooks/useFetchList';
import MapWithMarker from './MapWithMarker';

// Maharashtra location for super admin
const defaultMapCenter = [20, 76];

const PoliceStationOnMap = ({ user }) => {
    const list = useFetchList('Users/fetchPoliceStations', {});
    if (!list?.data) {
        return null;
    }
    if (user.user_role_id === '4') {
        // get logged in police user station location
        user = list.data.find((x) => x.id === user.police_station_id);
    }

    return (
        <MapWithMarker
            defaultMapCenter={[
                user.latitude || user.station_latitude || defaultMapCenter[0],
                user.longitude || user.station_longitude || defaultMapCenter[1],
            ]}
            data={list?.data
                .map(
                    (x) =>
                        x.station_latitude &&
                        x.station_longitude && {
                            lat: x.station_latitude,
                            lng: x.station_longitude,
                            popup_text: `StationName: ${x.name}, Address: ${x.station_address || ''} ${x.station_pincode || ''}`,
                        },
                )
                .filter((x) => x)}
        />
    );
};

export default PoliceStationOnMap;
