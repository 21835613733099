import { reduce } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router';

import useFetchUserDetailsById from '../../hooks/useFetchUserDetailsById';
import useFetchUserHealthDetailsById from '../../hooks/useFetchUserHealthDetailsById';
import usePostCreateUserHealth from '../../hooks/usePostCreateUserHealth';
import Form from '../Form/Form';
import Spinner from '../Spinner';
import {
    inputFields,
    userHealthSchema,
} from './inputFields/userHealthFormFields';

const UserHealthForm = ({
    userById,
    userHealthById,
    viewOnly,
    updating,
    mutate,
}) => {
    const [updatedUserHealth, setUpdatedUserHealth] = useState(userHealthById);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const createUserHealth = usePostCreateUserHealth({
        onSuccess: () => {
            navigate(-1);
        },
    });
    useEffect(() => {
        let bmi = 0;
        if (updatedUserHealth.weight && updatedUserHealth.height) {
            bmi = (
                updatedUserHealth.weight /
                Math.pow(updatedUserHealth.height / 100, 2)
            ).toFixed(1);
        }
        setUpdatedUserHealth((x) => ({ ...x, bmi }));
    }, [updatedUserHealth.weight, updatedUserHealth.height]);

    if (createUserHealth.error) {
        return <Navigate to="/auth/Login" />;
    }

    const handleUpdate = (value) => {
        setUpdatedUserHealth((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        const handleMutate = mutate ? mutate : createUserHealth.mutate;

        try {
            await userHealthSchema.validate(updatedUserHealth, {
                abortEarly: false,
            });
            setErrors({});
            handleMutate(updatedUserHealth);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <Form
            formTitle="Health Information"
            data={updatedUserHealth}
            onChange={handleUpdate}
            inputFields={inputFields(userById)}
            onSubmit={submitData}
            showReset={!mutate}
            onReset={() => {
                setUpdatedUserHealth(userById || {});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={mutate ? 'Complete' : updating ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
        />
    );
};

const UserHealthFormCard = ({ user, viewOnly, mutate, defaultUserId }) => {
    let { userId } = useParams();

    const userById = useFetchUserDetailsById(!mutate && userId);
    const userHealthById = useFetchUserHealthDetailsById(!mutate && userId);

    if (
        userById.isLoading ||
        userById.isFetching ||
        userHealthById.isLoading ||
        userHealthById.isFetching
    ) {
        return <Spinner />;
    }

    if (userById.error || userHealthById.error) {
        return <Navigate to="/auth/Login" />;
    }

    return (
        <>
            <div className="flex flex-wrap">
                <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                    <UserHealthForm
                        user={user}
                        viewOnly={viewOnly}
                        mutate={mutate}
                        updating={!!userHealthById.data}
                        userById={userById.data}
                        userHealthById={{
                            ...(userHealthById.data || {}),
                            user_id: userId || defaultUserId,
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default UserHealthFormCard;
