import { isEmpty, map, reduce } from 'lodash';
import React, { useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { useMutation, useQuery } from 'react-query';
import { Navigate, useParams } from 'react-router';

import { useAxios } from '../../AxiosProvider';
import FormWithTable from '../Form/FormWithTable';
import Spinner from '../Spinner';
import {
    columns,
    inputFields,
    userSportSchema,
} from './inputFields/userSportFormFields';

const UserSportForm = ({
    userId,
    viewOnly,
    updating,
    mutate,
    deleteSport,
    list,
}) => {
    const [updatedUserSport, setUpdatedUserSport] = useState({
        user_id: userId,
    });
    const [errors, setErrors] = useState({});

    const handleUpdate = (value) => {
        setUpdatedUserSport((uu) => ({
            ...uu,
            ...value,
        }));
    };

    const submitData = async () => {
        try {
            await userSportSchema.validate(updatedUserSport, {
                abortEarly: false,
            });
            setErrors({});
            mutate(updatedUserSport);
        } catch (err) {
            console.log(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
            setErrors(
                reduce(
                    err.inner,
                    (acc, val) => ({
                        ...acc,
                        [val.path]: val.message,
                    }),
                    {},
                ),
            );
        }
    };

    return (
        <FormWithTable
            formTitle="Sport Details"
            addButtonTxt="Add Sport"
            data={updatedUserSport}
            onChange={handleUpdate}
            inputFields={inputFields({})}
            onSubmit={submitData}
            showReset={!!mutate}
            onReset={() => {
                setUpdatedUserSport({});
                setErrors({});
            }}
            resetLabel={'Reset'}
            submitLabel={mutate ? 'Add' : updating ? 'Update' : 'Add'}
            viewOnly={viewOnly}
            errors={errors}
            list={map(list, (x, index) => ({ ...x, index: index + 1 }))}
            columns={columns(
                !viewOnly &&
                    ((props) => (
                        <div className="inline-flex justify-center items-center w-full">
                            <button
                                onClick={() => deleteSport(props.row.original)}
                                className={
                                    'text-sm border border-black mx-2 p-2 w-auto font-normal block whitespace-nowrap bg-red-500 text-gray-700'
                                }
                            >
                                <BsFillTrashFill className="fill-white" />
                            </button>
                        </div>
                    )),
            )}
        />
    );
};

const UserSportFormCard = ({ viewOnly, componentWrapper }) => {
    let { userId } = useParams();

    const axios = useAxios();

    const policeUserSport = useQuery(
        ['UsersSport/fetch_user_sports', userId],
        async () => {
            const { data } = await axios
                .get(`UsersSport/fetch_user_sports?user_id=${userId}`)
                .catch(function (error) {
                    console.log('API calling error', error);
                });
            return data.data;
        },
    );

    const addPoliceSport = useMutation(async (userSportDetails) => {
        const response = await axios
            .post(
                'UsersSport/add_user_sports',
                JSON.stringify(userSportDetails),
            )
            .then((res) => {
                policeUserSport.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    const deletePoliceSport = useMutation(async (userSportDetails) => {
        const response = await axios
            .post(
                'UsersSport/delete_user_sports',
                JSON.stringify(userSportDetails),
            )
            .then((res) => {
                policeUserSport.refetch();
            })
            .catch(function (error) {
                console.log('API calling error', error);
            });
        return response;
    });

    if (addPoliceSport.isLoading || deletePoliceSport.isLoading) {
        return <Spinner />;
    }

    if (addPoliceSport.error || deletePoliceSport.error) {
        return <Navigate to="/auth/Login" />;
    }

    const component = (
        <div className="flex flex-wrap w-full">
            <div className="w-full p-0 md:px-4" style={{ margin: 'auto' }}>
                <UserSportForm
                    userId={userId}
                    viewOnly={viewOnly}
                    mutate={addPoliceSport.mutate}
                    deleteSport={deletePoliceSport.mutate}
                    list={policeUserSport.data}
                />
            </div>
        </div>
    );

    if (componentWrapper) {
        return isEmpty(policeUserSport.data)
            ? null
            : componentWrapper(component);
    }

    return component;
};

export default UserSportFormCard;
